import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import { Button, Grid } from "@material-ui/core";

import parameters from "../../constants/parameters.js";
import dimensions from "../../constants/dimensions";
import { GameBGColor, GMColor } from "../../constants/colors";

const Container = styled.div`
  width: 100%;
  //   background-color: blue;
  //   position: fixed;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1rem;
`;

const SubTitle = styled.span`
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  // font-weight: bold;
`;

const Emoji = styled.span`
  font-size: 4rem;
`;

const Content = styled.div`
  margin: 2rem;
  //   background-color: red;
  line-height: 1.3;
  text-align: left;
`;

const AnswerButtonContainer = styled.div`
  position: absolute;
  width: 70%;
  margin-left: 15%;
  //   background-color: yellow;
  margin-top: calc(${dimensions.lineHeight} * -2.5);
`;

const AnswerButton = styled(Button)`
  //   background-color: yellow;
  // position: absolute;
`;

const Text = styled.span``;

class SaidQuoteGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      quote: props.data,
      gamecode: props.gamecode,
      isGM: props.isGM,
      showAnswer: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.censorAnswer = this.censorAnswer.bind(this);
    this.handleShowAnswer = this.handleShowAnswer.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  censorAnswer(author) {
    let cen = parameters.censorTextWord;
    for (let i = 0; i < author.length; i++) {
      if (author[i] === " ") cen += " " + parameters.censorTextWord;
    }
    return cen;
  }

  handleShowAnswer() {
    this.setState({ showAnswer: true });
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/game/gameData/showAnswer")
      .set(true);
  }

  render() {
    const Game = styled.div`
      width: ${this.state.width > 650 ? `75%` : `90%`};
      height: calc(6.5 * ${dimensions.lineHeight});
      overflow: scroll;
      margin-left: ${this.state.width > 650 ? `8rem` : `5%`};
      margin-top: 1rem;
      background-color: ${GameBGColor};
      border-radius: 1rem;
      box-shadow: 2px 2px 5px #535353;
      ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      cursor: default;
    `;

    return (
      <>
        {this.state.isGM && this.state.quote && !this.state.quote.showAnswer && (
          <AnswerButtonContainer>
            <AnswerButton
              style={{ backgroundColor: GMColor }}
              variant="outlined"
              onClick={this.handleShowAnswer}
            >
              Show Answer 🔑
            </AnswerButton>
          </AnswerButtonContainer>
        )}
        <Container>
          <Game>
            <Content>
              <Title>
                <Emoji>📜</Emoji>
              </Title>
              <Text>
                {this.state.quote && this.state.quote.quote && (
                  <SubTitle>
                    <Grid container>
                      <Grid item xs={2}>
                        🖋
                      </Grid>{" "}
                      <Grid item xs={10}>
                        ❝{this.state.quote.quote}❞
                      </Grid>
                    </Grid>
                  </SubTitle>
                )}
              </Text>
              {this.state.quote && this.state.quote.work && (
                <>
                  <br />
                  <br />
                  <Text>
                    <SubTitle>
                      <Grid container>
                        <Grid item xs={2}>
                          📕
                        </Grid>{" "}
                        <Grid item xs={10}>
                          {this.state.quote && this.state.quote.work}
                        </Grid>
                      </Grid>
                    </SubTitle>
                  </Text>
                </>
              )}
              <br />
              <br />
              <Text>
                {this.state.quote && this.state.quote.author && (
                  <SubTitle>
                    <Grid container>
                      <Grid item xs={2}>
                        🗣
                      </Grid>{" "}
                      <Grid item xs={10}>
                        {this.state.quote.showAnswer && this.state.quote.author}
                        {!this.state.quote.showAnswer && (
                          <span>
                            {this.censorAnswer(this.state.quote.author)}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </SubTitle>
                )}
              </Text>
            </Content>
          </Game>
        </Container>
      </>
    );
  }
}

export default SaidQuoteGame;
