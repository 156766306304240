import React, { useState } from "react";
import styled from "styled-components";
import firebase from "../firebase";

import Menu from "@material-ui/core/Menu";

import PlayerListPopup from "./PlayerListPopup";

import { PMColor, PMHoverColor } from "../constants/colors";
import { lineHeight, redLineMargin } from "../constants/dimensions";

const Container = styled.div`
  height: calc(${lineHeight * 1.5});
  font-size: 3vh;
  text-align: bottom;
  //   background-color: green;
  //   &:hover {
  //     background-color: grey;
  //   }
  display: flex;
  cursor: default;
`;

const PenaltyZoneContainer = styled.div`
  height: ${lineHeight};
  text-align: left;
  margin-left: ${redLineMargin};
  // background-color: red;
  width: 35vw;
  padding: calc(${lineHeight} / 4);
`;

const PenaltyZone = styled.div`
  width: 100%;
  height: 100%;
  border: 2px dashed #333333;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: center;
  font-size: 3vh;
`;

const PZLabel = styled.div`
  display: flex;
  position: absolute;
  background-color: white;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: left;
  font-size: 1.5vh;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
`;

const PenaltyButton = styled.div`
  margin: 1vh;
  margin-top: 2vh;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: scale(1.1);
    transform-origin: bottom;
    // background-color: grey;
  }
`;

const DisabledPenaltyButton = styled.div`
  margin: 1vh;
  margin-top: 2vh;
  text-align: center;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

const DMContainer = styled.div`
  height: ${lineHeight};
  text-align: left;
  //   background-color: blue;
  width: 56vw;
  padding-left: 0.25vh;
  padding-right: 3.75vh;
  padding-top: 3.75vh;
  padding-bottom: 0.25vh;
`;

const DMField = styled.div`
  width: 95%;
  border-radius: 100px;
  border: 1.5px solid black;
  padding: 1vh;
  white-space: nowrap;
  text-align: center;
  font-family: Comic Neue;
  font-weight: bold;
  font-size: 1.5vh;
  color: black;
  background-color: ${PMColor};
  cursor: pointer;
  &:hover {
    // color: black;
    background-color: ${PMHoverColor};
    // background-color: #fbf8b1;
  }
`;

const FooterBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState("");
  const [hasStarted] = useState(props.hasStarted);
  const [pzEnabled] = useState(props.advanced[5]);
  const [penalties] = useState(props.penalties);
  const [messagesEnabled] = useState(props.advanced[7]);
  const playerListOpen = Boolean(anchorEl);

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setType(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const usePenalty = () => {
    if (penalties > 0) {
      firebase
        .database()
        .ref("/games/" + props.gamecode + "/players/" + props.myUid)
        .child("/penalties")
        .set(penalties - 1);
    }
  };

  return (
    <Container>
      <Menu
        style={{ marginTop: "-4rem" }}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={playerListOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "50rem",
            width: "20ch",
          },
        }}
      >
        <PlayerListPopup
          gamecode={props.gamecode}
          myUid={props.myUid}
          type={type}
          selectRecipient={props.selectRecipient}
          closeMenu={handleClose}
          handleSendDmOpen={props.handleSendDmOpen}
          usePenalty={usePenalty}
        />
      </Menu>
      {hasStarted && pzEnabled && (
        <PenaltyZoneContainer>
          <PZLabel>
            <b>
              Penalty Zone{" "}
              {penalties === 0
                ? "0️⃣"
                : penalties === 1
                ? "1️⃣"
                : penalties === 2
                ? "2️⃣"
                : penalties === 3
                ? "3️⃣"
                : penalties === 4
                ? "4️⃣"
                : penalties === 5
                ? "5️⃣"
                : penalties === 6
                ? "6️⃣"
                : penalties === 7
                ? "7️⃣"
                : penalties === 8
                ? "8️⃣"
                : penalties === 9
                ? "9️⃣"
                : penalties === 10
                ? "🔟"
                : "0"}
            </b>
          </PZLabel>
          <PenaltyZone>
            {penalties > 0 && (
              <>
                <PenaltyButton onClick={(e) => handleClick(e, "drink")}>
                  🍺
                </PenaltyButton>
                <PenaltyButton onClick={(e) => handleClick(e, "truth")}>
                  👄
                </PenaltyButton>
                <PenaltyButton onClick={(e) => handleClick(e, "dare")}>
                  ⚠️
                </PenaltyButton>
              </>
            )}
            {penalties <= 0 && (
              <>
                <DisabledPenaltyButton>🍺</DisabledPenaltyButton>
                <DisabledPenaltyButton>👄</DisabledPenaltyButton>
                <DisabledPenaltyButton>⚠️</DisabledPenaltyButton>
              </>
            )}
          </PenaltyZone>
        </PenaltyZoneContainer>
      )}
      {hasStarted && !pzEnabled && (
        <PenaltyZoneContainer></PenaltyZoneContainer>
      )}
      <DMContainer>
        {hasStarted && messagesEnabled && (
          <DMField onClick={(e) => handleClick(e, "message")}>
            ✉️ Private Message...
          </DMField>
        )}
      </DMContainer>
    </Container>
  );
};

export default FooterBar;
