import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import { Wheel } from "react-custom-roulette";

import Button from "@material-ui/core/Button";

import { broadcastWheelResult } from "../../utilities/gameUtilities";

import dimensions from "../../constants/dimensions";
import {
  GMColor,
  rouletteWheelDividerColor,
  rouletteWheelInnerBorderColor,
} from "../../constants/colors";
import parameters from "../../constants/parameters";

const Container = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  margin-left: ${dimensions.redLineMargin};
`;

const WheelContainer = styled.div`
  // overflow: hidden;
`;

const SpinButtonContainer = styled.div`
  position: absolute;
  width: 70%;
  margin-left: 15%;
  //   background-color: yellow;
  margin-top: calc(${dimensions.lineHeight} * -2.5);
`;

const SpinButton = styled(Button)`
  //   background-color: yellow;
  // position: absolute;
`;

class DrinkRouletteGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      isGM: props.isGM,
      wheel: props.wheel,
      data: props.data,
      spin: false,
      done: props.done,
    };
    this.handleSpin = this.handleSpin.bind(this);
    this.revealAnswer = this.revealAnswer.bind(this);
  }

  componentDidMount() {
    if (this.state.wheel && this.state.data) {
      this.setState({
        spin: this.state.wheel.showAnswer,
      });
    }
  }

  //   TODO - glitches out when leave the game DURING a spin
  revealAnswer() {
    let oldOptionsData = this.state.data;
    var newOptionsData = [];
    let answer = this.state.wheel.answer;
    let length = oldOptionsData.length;
    var count = 0;
    for (let i = (answer + 1) % length; count < length; i = (i + 1) % length) {
      newOptionsData.push(oldOptionsData[i]);
      count++;
    }
    if (
      this.state.wheel &&
      this.state.isGM &&
      !this.state.done &&
      this.state.spin
    ) {
      let emoji = this.state.data[this.state.wheel.answer].option;
      let text = parameters.wheelOfDrunkOptions[emoji]
        ? parameters.wheelOfDrunkOptions[emoji]
        : this.state.wheel.chosenPlayerName + " Drinks";
      let ref = firebase
        .database()
        .ref("games/" + this.state.gamecode + "/game/gameData");

      ref.child("/finishedSpinning").once("value", (snapshot) => {
        if (snapshot.exists()) {
          let val = snapshot.val();
          if (val === false) {
            broadcastWheelResult(this.state.gamecode, emoji, text);
            ref.child("/data").set(newOptionsData);
            ref.child("/finishedSpinning").set(true);
          }
        }
      });
    }
    this.setState({ done: true, spin: false, data: newOptionsData });
  }

  handleSpin() {
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/game/gameData/showAnswer")
      .set(true);
  }

  render() {
    return (
      <>
        {this.state.isGM && this.state.wheel && !this.state.wheel.showAnswer && (
          <SpinButtonContainer>
            <SpinButton
              style={{ backgroundColor: GMColor }}
              variant="outlined"
              onClick={this.handleSpin}
            >
              Spin Wheel 🔑
            </SpinButton>
          </SpinButtonContainer>
        )}
        <Container>
          <WheelContainer>
            {this.state.wheel && (
              <Wheel
                fontSize={30}
                mustStartSpinning={
                  this.state.spin && !this.state.wheel.finishedSpinning
                }
                onStopSpinning={this.revealAnswer}
                prizeNumber={this.state.wheel.answer}
                data={this.state.data}
                perpendicularText={true}
                textDistance={80}
                innerRadius={30}
                innerBorderWidth={50}
                radiusLineColor={rouletteWheelDividerColor}
                innerBorderColor={rouletteWheelInnerBorderColor}
                outerBorderColor={rouletteWheelInnerBorderColor}
                outerBorderWidth={20}
                radiusLineWidth={1}
              />
            )}
          </WheelContainer>
        </Container>
      </>
    );
  }
}

export default DrinkRouletteGame;
