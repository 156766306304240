import React from "react";
import styled from "styled-components";

import ReactCardFlip from "react-card-flip";

import { lineHeight } from "../../constants/dimensions";

const Container = styled.div`
  width: 100%;
  //   background-color: blue;
  //   position: fixed;
  margin-top: 3.5rem;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  // font-weight: bold;
`;

const Emoji = styled.span`
  font-size: 8rem;
`;

const Content = styled.div`
  // margin: 2rem;
  // margin-top: 2rem;
  // background-color: red;
  line-height: 1.3;
  text-align: center;
  // background-color: green;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 2.2vh;
  margin: 0.5rem;
  // font-weight: bold;
`;

class SimpleGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      isGM: props.isGM,
      emoji: props.emoji,
      message: props.message,
      description: props.description,
      currPlayerName: props.currPlayerName,
      isFlipped: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleFlip = this.handleFlip.bind(this);
    this.fillInDescription = this.fillInDescription.bind(this);
  }

  componentDidMount() {
    if (this.state.description) this.fillInDescription();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleFlip() {
    this.setState({ isFlipped: !this.state.isFlipped });
  }

  fillInDescription() {
    let description = this.state.description;
    let newDescription = description.replaceAll(
      "{:player:}",
      this.state.currPlayerName
    );
    this.setState({ description: newDescription });
  }

  render() {
    const Game = styled.div`
      width: 50%;
      height: calc(5 * ${lineHeight});
      margin-left: 25%;
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.95);
      // background-color: green;
      border-radius: 1rem;
      box-shadow: 2px 10px 30px #777777;
      ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      cursor: ${this.state.description ? `pointer` : `default`};
      overflow: hidden;
      align-items: center;
      justify-content: center;
      display: flex;
    `;

    return (
      <>
        <Container>
          {!this.state.description && (
            <Game onClick={this.handleFlip}>
              <Content>
                <Emoji>{this.state.emoji}</Emoji>

                {this.state.message && (
                  <SubTitle>{this.state.message}</SubTitle>
                )}
              </Content>
            </Game>
          )}
          {this.state.description && (
            <ReactCardFlip
              isFlipped={this.state.isFlipped}
              flipDirection="horizontal"
            >
              <Game onClick={this.handleFlip}>
                <Content>
                  <Emoji>{this.state.emoji}</Emoji>

                  {this.state.message && (
                    <SubTitle>{this.state.message}</SubTitle>
                  )}
                </Content>
              </Game>

              <Game onClick={this.handleFlip}>
                <Content>
                  <Description>{this.state.description}</Description>
                </Content>
              </Game>
            </ReactCardFlip>
          )}
        </Container>
      </>
    );
  }
}

export default SimpleGame;
