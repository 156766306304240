import React from "react";
import styled from "styled-components";

import { Checkbox, Button } from "@material-ui/core";

const Container = styled.div`
  margin: 2rem;
`;

const Text = styled.div`
  font-size: 1rem;
`;

class Consent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeFunc: props.closeFunc,
      checked: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleSubmit = () => {
    this.state.closeFunc();
  };

  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };

  render() {
    return (
      <Container>
        {/* <Title>
          <b>I agree</b> <Emoji> 📣</Emoji>
        </Title> */}
        {/* <br />
        <Text>
          Alcohol abuse is dangerous so please enjoy this app responsibly. By
          choosing to continue you accept responsibility for your actions.
          Should you decide to play this game somewhere further than crawling
          distance to your bed, have a plan in mind about where to stay or fork
          out the cash for an Uber. Never EVER drink and drive.
        </Text> */}
        {/* <br />
        <Text>
          sexual consent text block:
          https://www.rainn.org/articles/what-is-consent finish this later
        </Text> */}
        {/* <br />
        <Text>
          KNOW YOUR LIMIT: The Drinking Games is designed as an adult “drinking
          game” but may be played without consumption of alcohol. According to
          the Surgeon General of the United States (1) women should not drink
          alcoholic beverages during pregnancy because of the risk of birth
          defects and (2) consumption of alcoholic beverages impairs your
          ability to operate machinery and may cause health problems. We do not
          recommend misuse of alcohol including excessive consumption,
          binge-drinking, drinking and driving/boating, and/or underage
          drinking. It is the players’ responsibility to monitor and moderate
          their alcohol consumption. We recommend that all players make adequate
          arrangements for their personal safety and transportation BEFORE
          playing. DISCLAIMER: The Drinking Games, it’s heirs, assigns,
          representatives, employees and agents bear no responsibility and are
          not liable for special or consequential damages resulting from the
          use, misuse, inability to use or interpretation of the contents of
          this product regardless of whether The Drinking Games has been advised
          of the possibility of such damages.
        </Text> */}
        {/* <br />
        <Text>
          The Drinking Games is intended for use by adults of legal drinking
          age. It may contain mature language and sexual content. Playing this
          game may involve the removal of articles of clothing and/or physical
          contact with other players. Know your limits; The Drinking Games shall
          not be responsible for any harm that occurs during or related to play.
          Playing The Drinking Games may be hazardous to your health. This game
          is supposed to be fun. Do not do anything during or related to this
          game that is illegal, makes you uncomfortable, or puts your health,
          safety, or livelihood at risk. Any instructions or directions are
          merely suggestions. Modify them as needed for safety and the comfort
          level of your group. Play at your own risk. By playing this game, you
          accept full responsibility for your actions. If you play this game in
          conjunction with drinking alcohol, DRINK RESPONSIBLY. DO NOT DRINK AND
          DRIVE.
        </Text> */}
        {/* <br />
        <Text>
          We want you to enjoy these games in a responsible way and for your
          experience with our site to be a positive one. While we know that
          alcohol may be involved in your game, it is not required to have fun
          with the game. These games are intended for entertainment purposes of
          responsible adults of legal drinking age and there should not be any
          underage drinking when playing these games, or at events where these
          games may be played. These are publicly available games and we do not
          own these games or any game rules. The games and accompanying rules
          are only suggestions. There may be differences in how you and your
          friends play them and you should not argue or fight about the games or
          their rules. We support and promote responsible drinking. That means
          knowing your limits and drinking with caution and in moderation –
          especially when playing a drinking game. It is the players’
          responsibility to monitor and moderate their own alcohol consumption.
          This includes hydrating between drinks with water, and planning ahead
          for your safety at the event and for a safe ride home or to wherever
          you may be traveling – all before you begin drinking. A simple
          internet search can help you learn some facts about to have fun and be
          safe with games that may involve alcohol. We do not support, or
          recommend - and we discourage –excess drinking or driving or operating
          any machinery or equipment, all of which can be damaging to your
          health and safety and the health and safety of others. It is not the
          intent of anyone associated with The Driinking Games to promote misuse
          of alcohol, alcoholism, hazing, binge drinking or any other form of
          alcohol abuse. We cannot take any responsibility for any effects or
          harm caused by the consumption of alcohol. According to the Surgeon
          General of the United States (1) women should not drink alcoholic
          beverages during pregnancy because of the risk of birth defects and
          (2) consumption of alcoholic beverages impairs your ability to operate
          machinery and may cause health problems. The Drinking Games, its
          owners, principals, heirs, assigns, representatives, employees, and
          agents (The Drinking Games Persons”) disclaim and have no
          responsibility, collectively or individually and are not liable for
          special or consequential damages resulting from the use, or misuse, of
          this site, any alcohol consumption, any game rules, or playing any
          game, or regardless of whether The Drinking Games or any The Drinking
          Games Person has been advised of the possibility of such damages.
        </Text> */}
        {/* <br />
        <Text>
          THE DRINKING GAMES is a game designed for responsible adults 21 and
          over. It is designed and made for entertainment purposes only and does
          not require the use of alcohol. The developers/publishers of this game
          are not encouraging the abuse of alcoholic beverages and hope that if
          you play the THE DRINKING GAMES game, you do so with balance and
          carefulness. We do not wish to promote misuse of alcohol, alcoholism,
          binge drinking or any other form of alcohol abuse. THE DRINKING GAMES
          cannot take any responsibility for the effect alcoholic drinks may
          have on people. All alcoholic drinks may be bad for your health. The
          developers of this game cannot take any responsibility for the
          outcomes that may be caused by playing the game. Information on this
          game is provided "as is" without warranty of any kind, either
          expressed or implied. The information and the terms and conditions of
          the game can be changed or updated at any time. By visiting the
          website and playing the game, you are agreeing to these terms and
          conditions. THE DRINKING GAMES advices you to be aware and responsible
          if you using alcoholic beverages.
        </Text>
        <br/> */}
        <Text>
          By choosing to continue, you accept and acknowledge the{" "}
          <a href="/terms" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>{" "}
          and accept full responsibility for your actions while playing. <br />
          <br />
          Alcohol abuse is dangerous so please enjoy this app responsibly.
          Should you decide to play this game somewhere further than crawling
          distance to your bed, have a plan in mind about where you'll stay or
          how you'll get home responsibily. <b>NEVER</b> drink and drive.
          <br />
          <br />
          <b>NONE</b> of the rules or directions in this game must be followed;
          they are simply suggestions. By continuing, you acknowledge that you
          are in a healthy state of mind and will not put yourself or anyone
          else in danger or make anyone feel uncomfortable. Have fun, and don't
          go outside your comfort zone if you don't want to.
        </Text>
        <br />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Checkbox
            checked={this.state.checked}
            onChange={this.handleCheck}
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <Button
            disabled={!this.state.checked}
            size="small"
            onClick={this.handleSubmit}
            style={{ paddingTop: "0.5rem" }}
            variant={"outlined"}
          >
            Understood
          </Button>
        </div>
      </Container>
    );
  }
}

export default Consent;
