import React from "react";
import styled from "styled-components";
import firebase from "../firebase";

import MuiDialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  Slider,
  TextField,
  ButtonGroup,
  Grid,
  Switch,
  Tooltip,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";

import { maxNameLength } from "../constants/parameters";

const minDate = new Date("01/01/1900");
const maxDate = new Date();

const Container = styled.div`
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

class Inflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,

      birthday: null,
      page: 1,
      name: "",
      is18: false,
      closeModalFunction: props.closeFunc,

      heatVisibleSwitch: false,
      heatVal: 0,
      genderVisibleSwitch: true,
      gender: 0,
      sexualityVisibleSwitch: false,
      sexuality: 0,
      statusVisibleSwitch: true,
      status: 0,

      setStateCallback: props.setStateCallback,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleHeatVisibleChange = this.handleHeatVisibleChange.bind(this);
    this.handleGenderVisibleChange = this.handleGenderVisibleChange.bind(this);
    this.handleSexualityVisibleChange =
      this.handleSexualityVisibleChange.bind(this);
    this.handleStatusVisibleChange = this.handleStatusVisibleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClearSelection = this.handleClearSelection.bind(this);
  }

  componentDidMount() {}

  handleDateChange(date) {
    this.setState({ birthday: date });
    let now = new Date();
    if (date && String(date) !== "Invalid Date") {
      let timeDiff = now.getTime() - date.getTime();
      let age = timeDiff / (1000 * 3600 * 24 * 365);
      if (age >= 18) {
        this.setState({ is18: true });
      } else {
        this.setState({ is18: false });
      }
    }
  }

  handleClickNext() {
    this.setState({ page: this.state.page + 1 });
  }
  handleClickBack() {
    if (this.state.page !== 1) this.setState({ page: this.state.page - 1 });
  }

  handleChangeName(data) {
    let newName = data.target.value;
    if (newName.length <= maxNameLength) this.setState({ name: newName });
  }

  valiDate() {
    if (String(this.state.birthday) === "Invalid Date") return false;
    if (this.state.birthday.getTime() < minDate.getTime()) return false;
    if (this.state.birthday.getTime() > maxDate.getTime()) return false;
    return true;
  }

  handleHeatVisibleChange() {
    this.setState({ heatVisibleSwitch: !this.state.heatVisibleSwitch });
  }

  handleHeatChange(val) {
    this.setState({ heatVal: val });
  }

  handleGenderVisibleChange() {
    this.setState({ genderVisibleSwitch: !this.state.genderVisibleSwitch });
  }

  handleGenderChange(val) {
    this.setState({ gender: val });
  }

  handleSexualityVisibleChange() {
    this.setState({
      sexualityVisibleSwitch: !this.state.sexualityVisibleSwitch,
    });
  }

  handleSexualityChange(val) {
    this.setState({ sexuality: val });
  }

  handleStatusVisibleChange() {
    this.setState({ statusVisibleSwitch: !this.state.statusVisibleSwitch });
  }

  handleStatusChange(val) {
    this.setState({ status: val });
  }

  handleClearSelection(e, val) {
    if (val === "gender") this.setState({ gender: 0 });
    if (val === "sexuality") this.setState({ sexuality: 0 });
    if (val === "status") this.setState({ status: 0 });
    if (val === "heat") this.setState({ heatVal: 0 });
  }

  handleSubmit() {
    this.state.setStateCallback(
      this.state.name,
      this.state.is18,
      this.state.status,
      this.state.showStatus
    );
    let info = {
      name: this.state.name,
      birthday: this.state.birthday,
      is18: this.state.is18,
      heat: this.state.heatVal,
      showHeat: this.state.heatVisibleSwitch,
      gender: this.state.gender,
      showGender: this.state.genderVisibleSwitch,
      sexuality: this.state.sexuality,
      showSexuality: this.state.sexualityVisibleSwitch,
      status: this.state.status,
      showStatus: this.state.statusVisibleSwitch,
      isReady: true,
    };
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/players/" + this.state.myUid)
      .update(info);
    this.state.closeModalFunction();
  }

  render() {
    return (
      <Container>
        <MuiDialogContent style={{ overflow: "hidden" }}>
          {this.state.page === 1 && (
            //   name
            <>
              <br />
              <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
                What's your name?
              </div>
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                <TextField
                  style={{ width: "65%", marginBottom: "1rem" }}
                  onChange={(e) => this.handleChangeName(e)}
                  label="Your Name"
                  value={this.state.name}
                />
                <br />
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  style={{ marginBottom: "1rem", marginTop: "1rem" }}
                  variant="outlined"
                  color="primary"
                  onClick={this.handleClickNext}
                  disabled={this.state.name === null || this.state.name === ""}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {this.state.page === 2 && (
            //   birthday
            <>
              <br />
              <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
                When's your birthday?
              </div>
              <br />
              <br />
              <div style={{ textAlign: "center" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ width: "65%" }}
                    disableFuture
                    autoOk
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={this.state.birthday ? "" : "Your Birthday"}
                    value={this.state.birthday}
                    onChange={this.handleDateChange}
                    minDateMessage={"Invalid Date"}
                    maxDateMessage={"Invalid Date"}
                    minDate={minDate}
                    maxDate={maxDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <br />
                <br />
              </div>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <Button
                      style={{ marginBottom: "1rem", marginTop: "1rem" }}
                      variant="outlined"
                      color="primary"
                      onClick={this.handleClickBack}
                    >
                      Back
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ textAlign: "right" }}>
                    {this.state.is18 ? (
                      <Button
                        style={{
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={this.handleClickNext}
                        disabled={
                          this.state.birthday === null ||
                          this.state.birthday === "" ||
                          this.valiDate() === false
                        }
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        style={{
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={this.handleSubmit}
                        disabled={
                          this.state.birthday === null ||
                          this.state.birthday === "" ||
                          this.valiDate() === false
                        }
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          {this.state.is18 &&
            this.state.page === 3 && ( // adult settings
              <>
                <div>
                  <br />
                  <div style={{ textAlign: "center", fontSize: "1.5rem" }}>
                    Adult Settings 👅
                  </div>
                  <br />
                  <Grid container spacing={1}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "right" }}>Visible</div>
                    </Grid>

                    <Grid item xs={3}>
                      Gender
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ButtonGroup variant="text" color="primary">
                          <Tooltip
                            title="Male"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button onClick={() => this.handleGenderChange(1)}>
                              🚹
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Female"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button onClick={() => this.handleGenderChange(2)}>
                              🚺
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Nonbinary"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button onClick={() => this.handleGenderChange(3)}>
                              🚻
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div
                        onClick={(e) => this.handleClearSelection(e, "gender")}
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          fontSize: "1.5rem",
                        }}
                      >
                        {this.state.gender === 1
                          ? "🚹"
                          : this.state.gender === 2
                          ? "🚺"
                          : this.state.gender === 3
                          ? "🚻"
                          : ""}
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "right" }}>
                        <Switch
                          checked={this.state.genderVisibleSwitch}
                          onChange={this.handleGenderVisibleChange}
                          color="primary"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      Sexuality
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ButtonGroup variant="text" color="primary">
                          <Tooltip
                            title="Straight"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button
                              onClick={() => this.handleSexualityChange(1)}
                            >
                              📏
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Gay"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button
                              onClick={() => this.handleSexualityChange(2)}
                            >
                              🌈
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Bi"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button
                              onClick={() => this.handleSexualityChange(3)}
                            >
                              💘
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div
                        onClick={(e) =>
                          this.handleClearSelection(e, "sexuality")
                        }
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          fontSize: "1.5rem",
                        }}
                      >
                        {this.state.sexuality === 1
                          ? "📏"
                          : this.state.sexuality === 2
                          ? "🌈"
                          : this.state.sexuality === 3
                          ? "💘"
                          : ""}
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "right" }}>
                        <Switch
                          checked={this.state.sexualityVisibleSwitch}
                          onChange={this.handleSexualityVisibleChange}
                          color="primary"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      Status
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ButtonGroup variant="text" color="primary">
                          <Tooltip
                            title="Single"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button onClick={() => this.handleStatusChange(1)}>
                              📫
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Taken"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button onClick={() => this.handleStatusChange(2)}>
                              📪
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Open"
                            placement="top"
                            enterDelay={1}
                            enterTouchDelay={1}
                          >
                            <Button onClick={() => this.handleStatusChange(3)}>
                              📭
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div
                        onClick={(e) => this.handleClearSelection(e, "status")}
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          fontSize: "1.5rem",
                        }}
                      >
                        {this.state.status === 1
                          ? "📫"
                          : this.state.status === 2
                          ? "📪"
                          : this.state.status === 3
                          ? "📭"
                          : ""}
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "right" }}>
                        <Switch
                          checked={this.state.statusVisibleSwitch}
                          onChange={this.handleStatusVisibleChange}
                          color="primary"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={3}>
                      Heat Level
                    </Grid>
                    <Grid item xs={6}>
                      <Slider
                        onChange={(e, val) => this.handleHeatChange(val)}
                        defaultValue={1}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(x) =>
                          x === 1
                            ? "❄️"
                            : x === 2
                            ? "🌨"
                            : x === 3
                            ? "🌧"
                            : x === 4
                            ? "☁️"
                            : x === 5
                            ? "🌥"
                            : x === 6
                            ? "🌤"
                            : x === 7
                            ? "☀️"
                            : x === 8
                            ? "🔥"
                            : x === 9
                            ? "👅"
                            : x === 10
                            ? "🍆"
                            : x
                        }
                        step={1}
                        marks
                        min={1}
                        max={10}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <div
                        onClick={(e) => this.handleClearSelection(e, "heat")}
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          fontSize: "1.5rem",
                        }}
                      >
                        {this.state.heatVal === 1
                          ? "❄️"
                          : this.state.heatVal === 2
                          ? "🌨"
                          : this.state.heatVal === 3
                          ? "🌧"
                          : this.state.heatVal === 4
                          ? "☁️"
                          : this.state.heatVal === 5
                          ? "🌥"
                          : this.state.heatVal === 6
                          ? "🌤"
                          : this.state.heatVal === 7
                          ? "☀️"
                          : this.state.heatVal === 8
                          ? "🔥"
                          : this.state.heatVal === 9
                          ? "👅"
                          : this.state.heatVal === 10
                          ? "🍆"
                          : ""}
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: "right" }}>
                        <Switch
                          checked={this.state.heatVisibleSwitch}
                          onChange={this.handleHeatVisibleChange}
                          color="primary"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <div style={{ width: "40%", textAlign: "left" }}>
                      <Button
                        style={{ marginBottom: "1rem", marginTop: "1rem" }}
                        variant="outlined"
                        color="primary"
                        onClick={this.handleClickBack}
                      >
                        Back
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        style={{
                          marginBottom: "1rem",
                          marginTop: "1rem",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Done
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
        </MuiDialogContent>
      </Container>
    );
  }
}

export default Inflow;
