import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

const Result = styled.span`
  font-size: 8rem;
`;

class DiceUtility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: Math.floor(Math.random() * 6) + 1,
    };
  }

  render() {
    return (
      <Container>
        <Title>
          <b>You Rolled</b> <Emoji> 🎲</Emoji>
        </Title>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Result>
            {this.state.result === 1
              ? "⚀"
              : this.state.result === 2
              ? "⚁"
              : this.state.result === 3
              ? "⚂"
              : this.state.result === 4
              ? "⚃"
              : this.state.result === 5
              ? "⚄"
              : "⚅"}
          </Result>
        </div>
      </Container>
    );
  }
}

export default DiceUtility;
