import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

const Result = styled.span`
  font-size: 4rem;
`;

class CoinUtility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: Math.random() < 0.5,
    };
  }

  render() {
    return (
      <Container>
        <Title>
          <b>Coin Flip</b> <Emoji> ❓</Emoji>
        </Title>
        <div
          style={{
            border: "0.2rem solid black",
            borderRadius: "100%",
            height: "6rem",
            width: "6rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {this.state.result && <Result>T</Result>}
          {!this.state.result && <Result>H</Result>}
        </div>
      </Container>
    );
  }
}

export default CoinUtility;
