import React, { useEffect, useState } from "react";
import styled from "styled-components";
import firebase from "../firebase";

import { MenuItem, Divider } from "@material-ui/core";

import {
  giveDrink,
  giveDare,
  giveTruth,
  kick,
  ban,
  unban,
  pause,
  endTimeout,
  changeGM,
  incrementPenalties,
} from "../utilities/utils.js";

const Container = styled.div`
  max-height: 25rem;
  cursor: default;
`;

const Title = styled.div`
  margin: 0.2rem;
  font-size: 1.2rem;
  text-align: center;
  overflow: scroll;
`;

const PlayerText = styled.div`
  overflow: scroll;
  margin-left: 0.3rem;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const PlayerListPopup = (props) => {
  const [players, setPlayers] = useState([]);
  const [playersRef] = useState(
    firebase.database().ref("/games/" + props.gamecode + "/players")
  );

  useEffect(() => {
    playersRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        setPlayers(snapshot.val());
      }
    });
  }, [props.gamecode, playersRef]);

  useEffect(
    () => () => {
      playersRef.off("value");
    },
    [props.gamecode, playersRef]
  );

  const handleClickPlayer = (e, playerUid, name, emoji) => {
    if (props.type === "drink") {
      giveDrink(
        props.gamecode,
        props.myUid,
        playerUid,
        players[props.myUid].name,
        players[props.myUid].emoji
      );
      props.usePenalty();
      props.closeMenu();
    }
    if (props.type === "truth") {
      giveTruth(props.gamecode, props.myUid, playerUid);
      props.usePenalty();
      props.closeMenu();
    }
    if (props.type === "dare") {
      giveDare(props.gamecode, props.myUid, playerUid);
      props.usePenalty();
      props.closeMenu();
    }
    if (props.type === "message") {
      props.selectRecipient(playerUid, name, emoji);
      props.closeMenu();
      props.handleSendDmOpen();
    }
    if (props.type === "gmkick") {
      kick(props.gamecode, playerUid);
      props.closeMenu();
    }
    if (props.type === "gmawardpenalty") {
      incrementPenalties(props.gamecode, playerUid);
      props.closeMenu();
    }
    if (props.type === "changegm") {
      changeGM(props.gamecode, props.myUid, playerUid);
      props.closeMenu();
      props.closeGMControls();
    }
    if (props.type === "gmban") {
      ban(props.gamecode, playerUid);
      props.closeMenu();
    }
    if (props.type === "gmunban") {
      unban(props.gamecode, playerUid);
      props.closeMenu();
    }
    if (props.type === "gmpause") {
      pause(props.gamecode, playerUid);
      props.closeMenu();
    }
    if (props.type === "endtimeout") {
      endTimeout(props.gamecode, playerUid);
      props.closeMenu();
    }
  };

  const getPlayers = () => {
    let allPlayersList = [];
    let allPlayersButMeList = [];
    let activePlayersList = [];
    let bannedPlayersList = [];
    let allNotBanned = [];
    let timedoutPlayersList = [];
    Object.entries(players).forEach(([key, value]) => {
      allPlayersList.push(
        <MenuItem
          onClick={(e) => handleClickPlayer(e, key, value.name, value.emoji)}
          key={key}
        >
          {value.emoji} <PlayerText> {value.name}</PlayerText>
        </MenuItem>
      );
      if (key !== props.myUid) {
        allPlayersButMeList.push(
          <MenuItem
            onClick={(e) => handleClickPlayer(e, key, value.name, value.emoji)}
            key={key}
          >
            {value.emoji} <PlayerText> {value.name}</PlayerText>
          </MenuItem>
        );
      }
      if (key !== props.myUid && value.isActive) {
        activePlayersList.push(
          <MenuItem
            onClick={(e) => handleClickPlayer(e, key, value.name, value.emoji)}
            key={key}
          >
            {value.emoji} <PlayerText> {value.name}</PlayerText>
          </MenuItem>
        );
      }
      if (key !== props.myUid && value.isBanned) {
        bannedPlayersList.push(
          <MenuItem
            onClick={(e) => handleClickPlayer(e, key, value.name, value.emoji)}
            key={key}
          >
            {value.emoji} <PlayerText> {value.name}</PlayerText>
          </MenuItem>
        );
      }
      if (key !== props.myUid && value.isBanned === false) {
        allNotBanned.push(
          <MenuItem
            onClick={(e) => handleClickPlayer(e, key, value.name, value.emoji)}
            key={key}
          >
            {value.emoji} <PlayerText> {value.name}</PlayerText>
          </MenuItem>
        );
      }
      if (value.inTimeout) {
        timedoutPlayersList.push(
          <MenuItem
            onClick={(e) => handleClickPlayer(e, key, value.name, value.emoji)}
            key={key}
          >
            {value.emoji} <PlayerText> {value.name}</PlayerText>
          </MenuItem>
        );
      }
    });

    var playersListFinal = [];
    if (
      props.type === "drink" ||
      props.type === "truth" ||
      props.type === "dare" ||
      props.type === "message" ||
      props.type === "gmkick" ||
      props.type === "changegm"
    ) {
      playersListFinal = activePlayersList.filter((x) => {
        return x !== undefined;
      });
    } else if (props.type === "gmunban") {
      playersListFinal = bannedPlayersList.filter((x) => {
        return x !== undefined;
      });
    } else if (props.type === "gmban") {
      playersListFinal = allNotBanned.filter((x) => {
        return x !== undefined;
      });
    } else if (props.type === "gmawardpenalty") {
      playersListFinal = allPlayersList.filter((x) => {
        return x !== undefined;
      });
    } else if (props.type === "endtimeout") {
      playersListFinal = timedoutPlayersList.filter((x) => {
        return x !== undefined;
      });
    } else {
      playersListFinal = allPlayersButMeList.filter((x) => {
        return x !== undefined;
      });
    }

    if (playersListFinal.length > 0) {
      return playersListFinal;
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <span style={{ margin: "1rem" }}>No players found</span>
        </div>
      );
    }
  };

  return (
    <Container>
      <Title>
        <b>
          {props.type === "drink"
            ? "🍺 Give Drink"
            : props.type === "truth"
            ? "👄 Give Truth"
            : props.type === "dare"
            ? "⚠️ Give Dare"
            : props.type === "message"
            ? "✉️ Send Message"
            : ""}
        </b>
      </Title>
      {(props.type === "drink" ||
        props.type === "truth" ||
        props.type === "dare" ||
        props.type === "message") && (
        <Divider style={{ marginBottom: "0.5rem" }} />
      )}
      {getPlayers()}
    </Container>
  );
};

export default PlayerListPopup;
