import React from "react";
import styled from "styled-components";

import { shuffleString } from "../../utilities/utils";

import { redCards, blackCards } from "../../constants/references";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

const Result = styled.span`
  font-size: 10rem;
`;

class CardUtility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "",
      useBlack: false,
    };
  }

  componentDidMount() {
    let useBlack = Math.random() < 0.5 ? true : false;
    let shuffledCards = shuffleString(useBlack ? blackCards : redCards);
    let card = shuffledCards.substring(0, 2);
    this.setState({ result: card, useBlack: useBlack });
  }

  render() {
    return (
      <Container>
        <Title>
          <b>Your Card</b> <Emoji> 🃏</Emoji>
        </Title>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Result style={{ color: this.state.useBlack ? "black" : "red" }}>
            {this.state.result}
          </Result>
        </div>
      </Container>
    );
  }
}

export default CardUtility;
