import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Button, Dialog } from "@material-ui/core";

const Container = styled.div`
  margin: 2rem;
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 3rem;
`;

const Banned = (props) => {
  const history = useHistory();

  const returnHome = () => {
    history.push("/");
  };

  return (
    <Dialog open={true}>
      <Container>
        <Emoji>🔨</Emoji>
        <br />
        <br />
        <Title>You are Banned from this game.</Title>
        <br />

        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            size="small"
            onClick={returnHome}
            style={{ paddingTop: "0.5rem" }}
            variant={"outlined"}
          >
            Home
          </Button>
        </div>
      </Container>
    </Dialog>
  );
};

export default Banned;
