import React from "react";
import styled from "styled-components";

import { HorLinesColor, VerLineColor } from "../constants/colors";
import {
  redLineMargin,
  redLineWidth,
  lineHeight,
  blueLineHeight,
} from "../constants/dimensions";

import "../css/settingsAnimation.css";

const Container = styled.div`
  position: absolute;
  height: 100vh;
  pointer-events: none;
`;

const RedLine = styled.div`
  background-color: ${VerLineColor};
  position: absolute;
  top: 0rem;
  margin-left: ${redLineMargin};
  width: ${redLineWidth};
  height: 100vh;
`;

const BlueLine = styled.div`
  background-color: ${HorLinesColor};
  position: relative;
  margin-bottom: calc(${lineHeight} - ${blueLineHeight});
  height: ${blueLineHeight};
  width: 100vw;
  &:nth-child(1) {
    margin-top: calc(2 * ${lineHeight});
  }
`;

const BackgroundLines = () => {
  return (
    <div>
      <RedLine />
      <Container>
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
        <BlueLine />
      </Container>
    </div>
  );
};

export default BackgroundLines;
