import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import { getEmojiList } from "../../utilities/utils.js";

const Container = styled.div`
  margin: 1rem;
  justify-content: center;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;

const Emoji = styled.div`
  font-size: 2.3rem;
  display: flex;
  justify-content: center;
`;

const Emojis = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: center;
`;

class ProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      emoji: "",
      name: "",
      emojis: "",
    };
  }

  componentDidMount() {
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/players/" + this.state.myUid)
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let player = snapshot.val();
          let emojis = getEmojiList(player, true, true);
          this.setState({
            emoji: player.emoji,
            name: player.name,
            emojis: emojis,
          });
        }
      });
  }

  render() {
    return (
      <Container>
        <Emoji>{this.state.emoji}</Emoji>
        <Title>
          <b>{this.state.name}</b>
        </Title>
        <Emojis>{this.state.emojis}</Emojis>
        <br />
      </Container>
    );
  }
}

export default ProfileInfo;
