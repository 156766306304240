import React from "react";
import styled from "styled-components";

import { Typography, Switch, Grid } from "@material-ui/core";

const Container = styled.div`
  cursor: default;
  width: 100%;
  display: flex;
  margin-left: -1rem;
`;

export default class AsyncSettingItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: props.idx,
      name: props.name,
      emoji: props.emoji,
      switch: props.switch,

      updateFunc: props.updateFunc,
    };
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  handleSwitch() {
    this.state.updateFunc(this.state.idx, !this.state.switch);
    this.setState({ switch: !this.state.switch });
  }

  render() {
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Typography>{this.state.emoji}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{this.state.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ whiteSpace: "nowrap", textAlign: "center" }}></div>
          </Grid>
          <Grid item xs={2}>
            <div
              style={{
                textAlign: "right",
                marginTop: "-0.5rem",
                marginLeft: "1rem",
              }}
            >
              <Switch
                checked={this.state.switch}
                onChange={this.handleSwitch}
                color="primary"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
