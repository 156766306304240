import React from "react";
import styled from "styled-components";
import firebase from "../firebase";

import { TextField, Button, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { broadcast } from "../utilities/utils.js";

const Container = styled.div`
  // width: clamp(50%, 40rem, 90%);
  margin: 2rem;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Recipient = styled.div`
  margin: 1rem;
  text-align: left;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModalFunction: props.closeFunc,
      gamecode: props.gamecode,
      myUid: props.myUid,
      myName: props.myName,
      myEmoji: props.myEmoji,
      msg: "",
      recipientUid: props.recipientUid,
      recipientEmoji: props.recipientEmoji,
      recipientName: props.recipientName,
      broadcastPercentage: props.broadcast,
    };

    this.handleSend = this.handleSend.bind(this);
    this.handleChangeMsg = this.handleChangeMsg.bind(this);
  }

  componentDidMount() {}

  handleChangeMsg = (e) => {
    this.setState({ msg: e.target.value });
  };

  handleSend() {
    firebase
      .database()
      .ref(
        "games/" +
          this.state.gamecode +
          "/players/" +
          this.state.recipientUid +
          "/"
      )
      .child("message/")
      .set({
        sender: this.state.myEmoji + " " + this.state.myName,
        msg: this.state.msg,
      });
    let doBroadcast =
      Math.floor(Math.random() * 100) < this.state.broadcastPercentage;
    if (doBroadcast) {
      let castMsg =
        "" +
        this.state.myEmoji +
        " " +
        this.state.myName +
        " just messaged " +
        this.state.recipientEmoji +
        " " +
        this.state.recipientName +
        ': "' +
        this.state.msg +
        '"';
      broadcast(this.state.gamecode, castMsg);
    }
    this.state.closeModalFunction();
  }

  render() {
    return (
      <Container>
        <Title>
          <b>Private Message</b> <Emoji> ✉️</Emoji>
        </Title>
        <TextField
          onChange={(e) => this.handleChangeMsg(e)}
          label="Message"
          value={this.state.msg}
        />
        {this.state.broadcastPercentage && (
          <Tooltip
            enterDelay={1}
            enterTouchDelay={1}
            title={
              "There is a " +
              this.state.broadcastPercentage +
              "% change this message will be broadcasted to everyone"
            }
          >
            <InfoIcon
              style={{ marginLeft: "1rem", marginTop: "1rem", color: "grey" }}
            />
          </Tooltip>
        )}
        <br />
        <br />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            disabled={this.state.msg === ""}
            size="small"
            onClick={this.handleSend}
          >
            <Recipient>
              Send to {this.state.recipientName} {this.state.recipientEmoji}
            </Recipient>
          </Button>
        </div>
      </Container>
    );
  }
}

export default Message;
