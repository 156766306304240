import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import TextField from "@material-ui/core/TextField";

const Container = styled.div`
  margin: 2rem;
  width: 15rem;
  text-align: center;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Description = styled.div`
  text-align: center;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 0.4rem;
`;

class EmojiUtility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      emoji: "",
      emojis: {},
    };
    this.handleChangeEmoji = this.handleChangeEmoji.bind(this);
    this.getDescriptions = this.getDescriptions.bind(this);
  }

  componentDidMount = () => {
    firebase
      .database()
      .ref("gameassets/emojiLegend")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let dict = snapshot.val();
          this.setState({ emojis: dict });
        }
      });
  };

  getDescriptions = () => {
    if (this.state.emojis["" + this.state.emoji]) {
      let descs = this.state.emojis["" + this.state.emoji].split("{:OR:}");
      let result = descs.map((str) => {
        return <Description>{str}</Description>;
      });
      return result;
    } else {
      return <Description>Not Found</Description>;
    }
  };

  handleChangeEmoji = (e) => {
    if (e.target.value.length <= 2) this.setState({ emoji: e.target.value });
  };

  render() {
    return (
      <Container>
        <Title>
          <b>Emoji Lookup 🗝</b>
        </Title>
        <TextField
          size={"small"}
          onChange={(e) => this.handleChangeEmoji(e)}
          label="Emoji"
          value={this.state.emoji}
        />
        <br />
        <br />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div style={{ width: "100%" }}>
            {this.state.emoji.length > 0 && this.getDescriptions()}
          </div>
        </div>
      </Container>
    );
  }
}

export default EmojiUtility;
