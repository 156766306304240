import React from "react";
import styled from "styled-components";

import { Slider, Switch, Grid } from "@material-ui/core";

import { minPenalties, maxPenalties } from "../../constants/parameters";

const StyledCrementButton = styled.button`
  margin-top: -0.3rem;
  border: none;
  width: 1.5rem;
  background-color: white;
  color: black;
  border: 1px solid #eeeeee;
  border-radius: 2rem;
  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
  font-family: Comic Neue;
  // font-weight: bold;
  font-size: 0.9rem;
`;

const Container = styled.div`
  cursor: default;
  width: 100%;
  display: flex;
  margin-left: -0.5rem;
`;

export default class AdvancedSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numRounds: props.advancedSettings[0],
      ageSwitch: props.advancedSettings[1],
      timeoutSwitch: props.advancedSettings[2],
      maxTimeoutLength: props.advancedSettings[3],
      timeoutFrequency: props.advancedSettings[4],
      penaltyZone: props.advancedSettings[5],
      startingPenalties: props.advancedSettings[6],
      privateMessages: props.advancedSettings[7],
      broadcastPrivateMessagePercentage: props.advancedSettings[8],
      presentationsEnabled: props.advancedSettings[9],
      updateFunc: props.updateFunc,
    };
    this.handleIncrementNumRounds = this.handleIncrementNumRounds.bind(this);
    this.handleDecrementNumRounds = this.handleDecrementNumRounds.bind(this);
    this.handleAgeSwitch = this.handleAgeSwitch.bind(this);
    this.handleTimeoutSwitch = this.handleTimeoutSwitch.bind(this);
    this.handlePenaltyZone = this.handlePenaltyZone.bind(this);
    this.handlePrivateMessages = this.handlePrivateMessages.bind(this);
    this.handlePresentationsEnabled =
      this.handlePresentationsEnabled.bind(this);
    this.handleIncrementMaxTimeoutLength =
      this.handleIncrementMaxTimeoutLength.bind(this);
    this.handleDecrementMaxTimeoutLength =
      this.handleDecrementMaxTimeoutLength.bind(this);
    this.handleIncrementTimeoutFrequency =
      this.handleIncrementTimeoutFrequency.bind(this);
    this.handleDecrementTimeoutFrequency =
      this.handleDecrementTimeoutFrequency.bind(this);
    this.handleDecrementStartingPenalties =
      this.handleDecrementStartingPenalties.bind(this);
    this.handleIncrementStartingPenalties =
      this.handleIncrementStartingPenalties.bind(this);
    this.handleChangebroadcastPrivateMessagePercentage =
      this.handleChangebroadcastPrivateMessagePercentage.bind(this);
  }

  handleIncrementNumRounds() {
    if (this.state.numRounds < 50) {
      this.state.updateFunc("numRounds", this.state.numRounds + 1);
      this.setState({ numRounds: this.state.numRounds + 1 });
    }
  }

  handleDecrementNumRounds() {
    if (this.state.numRounds > 5) {
      this.state.updateFunc("numRounds", this.state.numRounds - 1);
      this.setState({ numRounds: this.state.numRounds - 1 });
    }
  }

  handleAgeSwitch() {
    this.state.updateFunc("ageSwitch", !this.state.ageSwitch);
    this.setState({ ageSwitch: !this.state.ageSwitch });
  }

  handleTimeoutSwitch() {
    this.state.updateFunc("timeoutSwitch", !this.state.timeoutSwitch);
    this.setState({ timeoutSwitch: !this.state.timeoutSwitch });
  }
  handlePenaltyZone() {
    this.state.updateFunc("penaltyZone", !this.state.penaltyZone);
    this.setState({ penaltyZone: !this.state.penaltyZone });
  }
  handlePrivateMessages() {
    this.state.updateFunc("privateMessages", !this.state.privateMessages);
    this.setState({ privateMessages: !this.state.privateMessages });
  }
  handlePresentationsEnabled() {
    this.state.updateFunc(
      "presentationsEnabled",
      !this.state.presentationsEnabled
    );
    this.setState({ presentationsEnabled: !this.state.presentationsEnabled });
  }

  handleIncrementMaxTimeoutLength() {
    if (this.state.maxTimeoutLength < 10) {
      this.state.updateFunc(
        "maxTimeoutLength",
        this.state.maxTimeoutLength + 1
      );
      this.setState({ maxTimeoutLength: this.state.maxTimeoutLength + 1 });
    }
  }

  handleDecrementMaxTimeoutLength() {
    if (this.state.maxTimeoutLength > 2) {
      this.state.updateFunc(
        "maxTimeoutLength",
        this.state.maxTimeoutLength - 1
      );
      this.setState({ maxTimeoutLength: this.state.maxTimeoutLength - 1 });
    }
  }

  handleIncrementTimeoutFrequency() {
    if (this.state.timeoutFrequency < 10) {
      this.state.updateFunc(
        "timeoutFrequency",
        this.state.timeoutFrequency + 1
      );
      this.setState({ timeoutFrequency: this.state.timeoutFrequency + 1 });
    }
  }

  handleDecrementTimeoutFrequency() {
    if (this.state.timeoutFrequency > 2) {
      this.state.updateFunc(
        "timeoutFrequency",
        this.state.timeoutFrequency - 1
      );
      this.setState({ timeoutFrequency: this.state.timeoutFrequency - 1 });
    }
  }

  handleDecrementStartingPenalties() {
    if (this.state.startingPenalties > minPenalties) {
      this.state.updateFunc(
        "startingPenalties",
        this.state.startingPenalties - 1
      );
      this.setState({ startingPenalties: this.state.startingPenalties - 1 });
    }
  }

  handleIncrementStartingPenalties() {
    if (this.state.startingPenalties < maxPenalties) {
      this.state.updateFunc(
        "startingPenalties",
        this.state.startingPenalties + 1
      );
      this.setState({ startingPenalties: this.state.startingPenalties + 1 });
    }
  }

  handleChangebroadcastPrivateMessagePercentage(newProbability) {
    this.state.updateFunc("broadcastPrivateMessagePercentage", newProbability);
    this.setState({ broadcastPrivateMessagePercentage: newProbability });
  }

  render() {
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <>Number of Rounds</>
          </Grid>
          <Grid item xs={4}>
            <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
              <StyledCrementButton onClick={this.handleDecrementNumRounds}>
                {"-"}
              </StyledCrementButton>{" "}
              {this.state.numRounds}{" "}
              <StyledCrementButton onClick={this.handleIncrementNumRounds}>
                {"+"}
              </StyledCrementButton>
            </div>
          </Grid>
          {/* <Grid item xs={8}>
              <>Display age next to player names?</>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "center" }}>
                <Switch
                  checked={this.state.ageSwitch}
                  onChange={this.handleAgeSwitch}
                  color="primary"
                />
              </div>
            </Grid> */}
          {/* <Grid item xs={8}>
            <>Enable Timeouts</>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <Switch
                checked={this.state.timeoutSwitch}
                onChange={this.handleTimeoutSwitch}
                color="primary"
              />
            </div>
          </Grid>
          {this.state.timeoutSwitch && (
            <>
              <Grid item xs={1}></Grid>
              <Grid item xs={7}>
                <>Maximum Timeout Duration</>
              </Grid>
              <Grid item xs={4}>
                <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <StyledCrementButton
                    onClick={this.handleDecrementMaxTimeoutLength}
                  >
                    {"-"}
                  </StyledCrementButton>{" "}
                  {this.state.maxTimeoutLength}{" "}
                  <StyledCrementButton
                    onClick={this.handleIncrementMaxTimeoutLength}
                  >
                    {"+"}
                  </StyledCrementButton>
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={7}>
                <>Frequency of Timeouts</>
              </Grid>
              <Grid item xs={4}>
                <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <StyledCrementButton
                    onClick={this.handleDecrementTimeoutFrequency}
                  >
                    {"-"}
                  </StyledCrementButton>{" "}
                  {this.state.timeoutFrequency}{" "}
                  <StyledCrementButton
                    onClick={this.handleIncrementTimeoutFrequency}
                  >
                    {"+"}
                  </StyledCrementButton>
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={7}>
                <>Enable Presentations</>
              </Grid>
              <Grid item xs={4}>
                <div style={{ textAlign: "center" }}>
                  <Switch
                    checked={this.state.presentationsEnabled}
                    onChange={this.handlePresentationsEnabled}
                    color="primary"
                  />
                </div>
              </Grid>
            </>
          )} */}
          <Grid item xs={8}>
            <>Enable Penalty Zone</>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <Switch
                checked={this.state.penaltyZone}
                onChange={this.handlePenaltyZone}
                color="primary"
              />
            </div>
          </Grid>
          {this.state.penaltyZone && (
            <>
              <Grid item xs={1}></Grid>
              <Grid item xs={7}>
                <>Starting Penalties</>
              </Grid>
              <Grid item xs={4}>
                <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <StyledCrementButton
                    onClick={this.handleDecrementStartingPenalties}
                  >
                    {"-"}
                  </StyledCrementButton>{" "}
                  {this.state.startingPenalties}{" "}
                  <StyledCrementButton
                    onClick={this.handleIncrementStartingPenalties}
                  >
                    {"+"}
                  </StyledCrementButton>
                </div>
              </Grid>
            </>
          )}
          <Grid item xs={8}>
            <>Enable Private Messages</>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "center" }}>
              <Switch
                checked={this.state.privateMessages}
                onChange={this.handlePrivateMessages}
                color="primary"
              />
            </div>
          </Grid>
          {this.state.privateMessages && (
            <>
              <Grid item xs={1}></Grid>
              <Grid item xs={6}>
                <>% chance DMs are broadcast</>
              </Grid>
              <Grid item xs={5}>
                <Slider
                  defaultValue={2}
                  step={1}
                  onChange={(e, val) =>
                    this.handleChangebroadcastPrivateMessagePercentage(val)
                  }
                  valueLabelFormat={(x) => "" + x + "%"}
                  min={0}
                  max={100}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    );
  }
}
