import firebase from "../firebase";
import Cookies from "universal-cookie";
import parameters, { maxPenalties } from "../constants/parameters";

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

//bad
export function assignGM(gamecode) {
  const ref = firebase.database().ref("/games/" + gamecode + "/players");
  ref.on("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      if (typeof players !== "undefined" && Object.keys(players).length > 0) {
        for (var i = 0; i < Object.keys(players).length; i++) {
          if (
            players[Object.keys(players)[i]].isActive &&
            players[Object.keys(players)[i]].isPresent
          ) {
            ref.child("/" + Object.keys(players)[i] + "/isGM").set(true);
            break;
          }
        }
      }
    }
  });
}

export function getPlayer(gamecode, uid) {
  return new Promise((resolve, reject) => {
    const ref = firebase
      .database()
      .ref("/games/" + gamecode + "/players/" + uid);
    ref.once("value", (snapshot) => {
      if (snapshot.exists()) {
        let player = snapshot.val();
        resolve(player);
      }
    });
  });
}

export function changeGM(gamecode, oldGMUid, newGMUid) {
  const playersRef = firebase.database().ref("/games/" + gamecode + "/players");
  if (oldGMUid !== null) playersRef.child("/" + oldGMUid + "/isGM").set(false);
  playersRef.child("/" + newGMUid + "/isGM").set(true);
}

export function numActive(gamecode) {
  var count = 0;
  const ref = firebase.database().ref("/games/" + gamecode + "/players");
  ref.on("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      for (var i = 0; i < Object.keys(players).length; i++) {
        if (players[Object.keys(players)[i]].isActive) count++;
      }
    }
  });
  return count;
}

export function getUidFromEmoji(gamecode, playerEmoji) {
  return new Promise((resolve, reject) => {
    let pUid = null;
    const ref = firebase.database().ref("/games/" + gamecode + "/players");
    ref.on("value", (snapshot) => {
      if (snapshot.exists()) {
        let players = snapshot.val();
        for (var i = 0; i < Object.keys(players).length; i++) {
          if (players[Object.keys(players)[i]].emoji === playerEmoji)
            pUid = Object.keys(players)[i];
        }
      }
    });
    resolve(pUid);
  });
}

// // called on initUser, makes isActive false of all prev sessions from this player's browser (by IP)
// export function removeOldSession(gamecode, myUid, ip) {
//   const ref = firebase.database().ref("/games/" + gamecode + "/players");
//   ref.on("value", (snapshot) => {
//     if (snapshot.exists()) {
//       let players = snapshot.val();
//       for (var i = 0; i < Object.keys(players).length; i++) {
//         if (players[Object.keys(players)[i]].isActive) {
//           var player = players[Object.keys(players)[i]];
//           if (
//             Object.keys(players)[i] !== myUid &&
//             player.isActive &&
//             player.IP === ip
//           ) {
//             // ref.child("/" + Object.keys(players)[i] + "/isActive").set(false);
//             // ref.child("/" + Object.keys(players)[i] + "/kickself").set(true);
//             // TODO
//           }
//         }
//       }
//     }
//   });
// }

export function IPInGame(gamecode, ip) {
  const ref = firebase.database().ref("/games/" + gamecode + "/players");
  let exists = false;
  let currUid = null;
  ref.on("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      for (var i = 0; i < Object.keys(players).length; i++) {
        if (players[Object.keys(players)[i]].IP === ip) {
          exists = true;
          currUid = Object.keys(players)[i];
        }
      }
    }
  });
  return [exists, currUid];
}

export function setCookie(cookieName, val, options = { path: "/" }) {
  const cookies = new Cookies();
  cookies.set(cookieName, val, options);
}

export function cookieExists(cookieName) {
  const cookies = new Cookies();
  var cookie = cookies.get(cookieName);
  if (typeof cookie !== "undefined" && cookie !== null && cookie !== "") {
    return true;
  }
  return false;
}

export function getCookie(cookieName) {
  const cookies = new Cookies();
  var cookie = cookies.get(cookieName);
  if (typeof cookie !== "undefined" && cookie !== null && cookie !== "") {
    return cookie;
  }
  return null;
}

export function removeCookie(cookieName) {
  const cookies = new Cookies();
  cookies.remove(cookieName);
}

export function lookupName(gamecode, playerUid) {
  firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid)
    .child("/name")
    .once("value", (snapshot) => {
      if (snapshot.exists()) return snapshot.val();
    });
}

export function getNewPlayerData(
  ip = "",
  host = false,
  gm = false,
  playerEmoji = "",
  penalties = parameters.defaultStartingPenalties
) {
  var playerData = {
    name: "player" + Math.floor(100 * Math.random()),
    birthday: "",
    emoji: playerEmoji,
    isReady: false,
    isActive: true,
    isPaused: false,
    isPresent: true,
    isHost: host,
    isGM: gm,
    is18: false,
    inTimeout: false,
    penalties: penalties,
    lastSeen: "",
    isBanned: false,
    IP: ip,
    votekicks: {},
    kickself: false,
    status: 0,
    showStatus: false,
    popups: [{ typecode: 0 }],
    // message: { msg: "", sender: "" },
    partner: { partnerUid: "", mutual: false, emoji: "" },
  };
  return playerData;
}

export function getPlayerEmoji(gamecode, emojis) {
  //TODO handle case when numPlayers exceeds num of player emojis in string
  let gameRef = firebase.database().ref("/games/" + gamecode);
  let emoji = emojis.substring(0, 2);
  let newEmojis = emojis.substring(2, emojis.length);
  gameRef.child("/playerEmojis").set(newEmojis);
  return emoji;
}

export function isBannedFromGame(gamecode, myIP) {
  firebase
    .database()
    .ref("/games/" + gamecode)
    .child("/bannedIPs")
    .once("value", (snapshot) => {
      if (snapshot.exists()) {
        let ips = snapshot.val();
        if (ips.length > 0) {
          for (var i = 0; i < ips.length; i++) {
            let ip = ips[i];
            if (ip === myIP) return true;
          }
        }
      }
    });
  return false;
}

export function getActivePlayers(gamecode) {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref("games/" + gamecode + "/players")
      .once("value", (snapshot) => {
        let activePlayers = {};
        if (snapshot.exists()) {
          let players = snapshot.val();
          Object.entries(players).forEach(([uid, playerData]) => {
            if (playerData.isActive) {
              activePlayers[uid] = playerData;
            }
          });
        }
        resolve(activePlayers);
      });
  });
}

export function getRoomOccupants(gamecode) {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref("games/" + gamecode + "/players")
      .once("value", (snapshot) => {
        let activePlayers = {};
        if (snapshot.exists()) {
          let players = snapshot.val();
          Object.entries(players).forEach(([uid, playerData]) => {
            if (playerData.isActive) {
              activePlayers[uid] = playerData;
            }
          });
        }
        resolve(activePlayers);
      });
  });
}

export function shuffleString(str) {
  return [...str].sort((_) => Math.random() - 0.5).join("");
}

function shuffleArr(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

export function broadcast(gamecode, message) {
  let broadcastData = {
    typecode: 1,
    title: "BROADCAST",
    emoji: "📣",
    description: message,
  };
  getActivePlayers(gamecode).then((activePlayers) => {
    for (let i = 0; i < Object.keys(activePlayers).length; i++) {
      let playerRef = firebase
        .database()
        .ref("games/" + gamecode + "/players/" + Object.keys(activePlayers)[i]);
      let player = activePlayers[Object.keys(activePlayers)[i]];
      let popups = player.popups;
      popups.push(broadcastData);
      playerRef.child("/popups").set(popups);
    }
  });
}

export function giveDrink(gamecode, drinkerUid, drinkeeUid, myName, myEmoji) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  let drinkeeRef = gameRef.child("/players/" + drinkeeUid);
  drinkeeRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      let player = snapshot.val();
      let drinkData = {
        typecode: 1,
        title: "From " + myName + " " + myEmoji,
        emoji: "🥃",
        description: "" + player.emoji + " " + player.name + ", drink",
      };
      let popups = player.popups;
      popups.push(drinkData);
      drinkeeRef.child("/popups").set(popups);
    }
  });
}

export function getDareMessage(gamecode, daree, darer, darerUid, dareeUid) {
  return new Promise((resolve, reject) => {
    getActivePlayers(gamecode).then((activePlayers) => {
      let numOthersInvolved =
        Object.keys(activePlayers).length < 3
          ? 0
          : Object.keys(activePlayers).length === 3
          ? Math.floor(Math.random() * 2)
          : Math.floor(Math.random() * 3);
      var other1Uid,
        other1,
        other2 = null;
      if (numOthersInvolved > 0 && Object.keys(activePlayers).length > 2) {
        other1Uid = getRandomActivePlayer(activePlayers, darerUid, dareeUid);
        other1 = other1Uid === null ? null : activePlayers[other1Uid];
      }
      if (numOthersInvolved > 1 && Object.keys(activePlayers).length > 3) {
        other2 =
          activePlayers[
            getRandomActivePlayer(activePlayers, darerUid, dareeUid, other1Uid)
          ];
      }
      let other1Compatible = other1 ? isCompatible(daree, other1) : null;
      let other2Compatible = other2 ? isCompatible(daree, other2) : null;
      var max = 9;
      if (numOthersInvolved === 1) {
        if (!other1Compatible) {
          max = parameters.highestDareLevelWithoutCompatibility;
        }
      } else if (numOthersInvolved === 2) {
        if (!other1Compatible || !other2Compatible)
          max = parameters.highestDareLevelWithoutCompatibility;
      }
      var severity = chooseSeverity(
        gamecode,
        activePlayers,
        daree,
        darer,
        max,
        other1,
        other2
      );
      let daresRef = firebase.database().ref("gameassets/dares");
      daresRef.once("value", (snapshot) => {
        if (snapshot.exists()) {
          let allDares = snapshot.val();
          let dares = allDares[numOthersInvolved][severity];
          var dare = "";
          var satisfied = false;
          var idx = Math.floor(Math.random() * dares.length);
          do {
            dare = dares[idx].dare;
            let compatCode = dares[idx].compatCode;
            // let requiresConsent = dares[idx].requiresConsent; // TODO consent
            let hasSeconds = dares[idx].hasSeconds;
            let hasRounds = dares[idx].hasRounds;
            let seconds = chooseSeconds(hasSeconds);
            let rounds = chooseRounds(hasRounds);

            if (hasRounds || hasSeconds || numOthersInvolved > 0) {
              dare = processMessage(
                dare,
                daree.name,
                darer.name,
                other1,
                other2,
                seconds,
                rounds
              );
            }
            satisfied = checkSatisfied(
              compatCode,
              darer,
              daree,
              numOthersInvolved,
              other1Compatible,
              other2Compatible,
              other1,
              other2
            );
            idx = (idx + 1) % dares.length;
          } while (!satisfied);
          resolve(dare);
        }
      });
    });
  });
}

function checkSatisfied(
  compatCode,
  giver,
  givee,
  numOthersInvolved,
  other1Compatible,
  other2Compatible,
  other1,
  other2
) {
  let satisfied = false;
  switch (compatCode) {
    case 0:
      satisfied = true;
      break;
    case 1:
      satisfied = other1Compatible;
      break;
    case 2:
      satisfied = other1Compatible
        ? other1.gender && other1.gender === 2
          ? true
          : false
        : false;
      break;
    case 3:
      satisfied = other1Compatible
        ? other1.gender && other1.gender === 1
          ? true
          : false
        : false;
      break;
    case 4:
      satisfied = other1Compatible
        ? other1.gender &&
          other1.gender === 1 &&
          givee.gender &&
          givee.gender === 1
          ? true
          : false
        : false;
      break;
    case 5:
      satisfied = other1Compatible
        ? other1.gender &&
          other1.gender === 2 &&
          givee.gender &&
          givee.gender === 2
          ? true
          : false
        : false;
      break;
    case 6:
      satisfied = other1Compatible && other2Compatible;
      break;
    case 7:
      satisfied =
        other1Compatible && other2Compatible
          ? other1.gender &&
            other1.gender === 2 &&
            other2.gender &&
            other2.gender === 2
          : false;
      break;
    case 8:
      satisfied =
        other1Compatible && other2Compatible
          ? other1.gender &&
            other1.gender === 1 &&
            other2.gender &&
            other2.gender === 1
          : false;
      break;
    case 9:
      satisfied =
        other1Compatible && other2Compatible
          ? givee.gender &&
            givee.gender === 2 &&
            other1.gender &&
            other1.gender === 2 &&
            other2.gender &&
            other2.gender === 2
          : false;
      break;
    case 10:
    default:
      satisfied =
        other1Compatible && other2Compatible
          ? givee.gender &&
            givee.gender === 1 &&
            other1.gender &&
            other1.gender === 1 &&
            other2.gender &&
            other2.gender === 1
          : false;
      break;
  }
  return satisfied;
}

function chooseSeconds(hasSeconds) {
  if (!hasSeconds) return 0;
  else {
    return (
      (Math.floor(
        Math.random() *
          (parameters.randomDareSecondsMax / parameters.randomDareSecondsMin)
      ) +
        1) *
      parameters.randomDareSecondsMin
    );
  }
}

function chooseRounds(hasRounds) {
  if (!hasRounds) return 0;
  else {
    let choices = [];
    for (
      let i = parameters.randomDareRoundsMin;
      i <= parameters.randomDareRoundsMax;
      i++
    ) {
      choices.push(i);
    }
    return choices[Math.floor(Math.random() * choices.length)];
  }
}

export function getRandomActivePlayer(
  activePlayers,
  player1,
  player2,
  player3 = null
) {
  let playersUids = Object.keys(activePlayers);
  shuffleArr(playersUids);
  let idx = Math.floor(Math.random() * playersUids.length);
  let p = playersUids[idx];
  for (let i = 0; i < playersUids.length; i++) {
    if (
      p !== player1 &&
      p !== player2 &&
      (player3 === null || (player3 && p !== player3))
    ) {
      return p;
    } else {
      idx = (idx + 1) % playersUids.length;
      p = playersUids[idx];
    }
  }
  return null;
}

export function giveDare(gamecode, darerUid, dareeUid) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  let dareeRef = gameRef.child("/players/" + dareeUid);
  let darerRef = gameRef.child("/players/" + darerUid);
  dareeRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      var daree = snapshot.val();
      darerRef.once("value", (snapshot) => {
        if (snapshot.exists()) {
          let darer = snapshot.val();
          var dareMessage = getDareMessage(
            gamecode,
            daree,
            darer,
            darerUid,
            dareeUid
          );
          dareMessage.then((dareVal) => {
            let darerData = {
              typecode: 1,
              title: "You sent " + daree.name + " " + daree.emoji,
              emoji: "⚠️",
              description:
                dareVal.substring(0, 1).toUpperCase() + dareVal.substring(1),
            };
            let dareeData = {
              typecode: 1,
              title: "DARE",
              emoji: "⚠️",
              description:
                darer.emoji +
                " " +
                darer.name +
                " dares you to " +
                dareVal +
                " (or drink)",
            };
            let darerPopups = darer.popups;
            darerPopups.push(darerData);
            darerRef.child("/popups").set(darerPopups);
            let dareePopups = daree.popups;
            dareePopups.push(dareeData);
            dareeRef.child("/popups").set(dareePopups);
          });
        }
      });
    }
  });
}

export function isCompatible(player1, player2) {
  let p1Gender = player1.gender;
  let p1Sexuality = player1.sexuality;
  let p1Status = player1.status;
  let p2Gender = player2.gender;
  let p2Sexuality = player2.sexuality;
  let p2Status = player2.status;

  if (
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 1) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 1) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 1) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 1) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 1 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 1 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 1 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 1 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 1 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 2) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 2) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 2) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 2) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 2) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 2) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 2 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 2 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 3 &&
      p2Status === 1 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 3 &&
      p2Status === 3 &&
      p2Gender === 1 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 3 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 1 &&
      p1Sexuality === 3 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 3 &&
      p2Status === 1 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 1 &&
      p1Sexuality === 3 &&
      p2Status === 3 &&
      p2Gender === 2 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 3 &&
      p1Sexuality === 3 &&
      p2Status === 1 &&
      p2Gender === 3 &&
      p2Sexuality === 3) ||
    (p1Status === 1 &&
      p1Gender === 3 &&
      p1Sexuality === 3 &&
      p2Status === 3 &&
      p2Gender === 3 &&
      p2Sexuality === 3) ||
    (p1Status === 3 &&
      p1Gender === 3 &&
      p1Sexuality === 3 &&
      p2Status === 3 &&
      p2Gender === 3 &&
      p2Sexuality === 3) ||
    (player1.partner.emoji === player2.emoji &&
      player1.partner.mutual &&
      player2.partner.emoji === player1.emoji &&
      player2.partner.mutual)
  ) {
    return true;
  } else {
    return false;
  }
}

export function getRandomCompatiblePlayers(
  gamecode,
  myUid,
  player,
  compatCode,
  heatVal
) {
  var players = [];
  let activePlayersPromise = getActivePlayers(gamecode);
  activePlayersPromise.then((activePlayers) => {
    let activePlayersKeys = Object.keys(activePlayers);
    shuffleArr(activePlayersKeys);
    for (let i = 0; i < activePlayersKeys.length; i++) {
      let pUid = activePlayersKeys[i];
      if (pUid !== myUid) {
        let p = activePlayers[pUid];
        if (p.heat && p.heat >= heatVal) {
          if (isCompatible(player, p)) {
            if (compatCode === 1) return p;
            else if (compatCode === 2 && p.gender && p.gender === 2) {
              return p;
            } else if (compatCode === 3 && p.gender && p.gender === 1) {
              return p;
            } else if (
              compatCode === 4 &&
              player.gender &&
              player.gender === 1 &&
              p.gender &&
              p.gender === 1
            ) {
              return p;
            } else if (
              compatCode === 5 &&
              player.gender &&
              player.gender === 2 &&
              p.gender &&
              p.gender === 2
            ) {
              return p;
            } else if (compatCode === 6 && players.length === 0) {
              players.push(p);
            } else if (compatCode === 6 && players.length === 1) {
              players.push(p);
              return players;
            } else if (
              compatCode === 7 &&
              players.length === 0 &&
              p.gender &&
              p.gender === 2
            ) {
              players.push(p);
            } else if (
              compatCode === 7 &&
              players.length === 1 &&
              p.gender &&
              p.gender === 2
            ) {
              players.push(p);
              return players;
            } else if (
              compatCode === 8 &&
              players.length === 0 &&
              p.gender &&
              p.gender === 1
            ) {
              players.push(p);
            } else if (
              compatCode === 8 &&
              players.length === 1 &&
              p.gender &&
              p.gender === 1
            ) {
              players.push(p);
              return players;
            } else if (
              compatCode === 9 &&
              players.length === 0 &&
              player.gender &&
              player.gender === 2 &&
              p.gender &&
              p.gender === 2
            ) {
              players.push(p);
            } else if (
              compatCode === 9 &&
              players.length === 1 &&
              player.gender &&
              player.gender === 2 &&
              p.gender &&
              p.gender === 2
            ) {
              players.push(p);
              return players;
            } else if (
              compatCode === 10 &&
              players.length === 0 &&
              player.gender &&
              player.gender === 1 &&
              p.gender &&
              p.gender === 1
            ) {
              players.push(p);
            } else if (
              compatCode === 10 &&
              players.length === 1 &&
              player.gender &&
              player.gender === 1 &&
              p.gender &&
              p.gender === 1
            ) {
              players.push(p);
              return players;
            }
          }
        }
      }
    }
    if (compatCode >= 6 && compatCode <= 10) {
      for (let i = players.length; i < 2; i++) players.push(null);
    }
    return players;
  });
}

export function chooseSeverity(
  gamecode,
  activePlayers,
  giver,
  givee,
  max = 6,
  other1 = null,
  other2 = null
) {
  for (let i = 0; i < Object.keys(activePlayers).length; i++) {
    if (activePlayers[Object.keys(activePlayers)[i]].is18 === false) {
      return 0;
    }
  }
  var maxSeverity = max;
  if (giver.heat !== 0) {
    maxSeverity = Math.min(giver.heat, maxSeverity);
  }
  if (givee.heat !== 0) {
    maxSeverity = Math.min(givee.heat, maxSeverity);
  }
  if (other1 && other1.heat !== 0) {
    maxSeverity = Math.min(other1.heat, maxSeverity);
  }
  if (other2 && other2.heat !== 0) {
    maxSeverity = Math.min(other2.heat, maxSeverity);
  }
  if (
    giver.heat === 0 ||
    givee.heat === 0 ||
    (other1 && other1.heat === 0) ||
    (other2 && other2.heat === 0)
  ) {
    maxSeverity = parameters.defaultHeatLevel;
  }
  if (maxSeverity === 100) {
    maxSeverity = parameters.defaultHeatLevel;
  }

  let severityOptions = [];
  for (let i = 0; i <= maxSeverity; i++) {
    for (let j = i; j <= maxSeverity; j++) {
      severityOptions.push(i);
    }
  }
  let idx = Math.floor(Math.random() * severityOptions.length);

  return severityOptions[idx];
}

export function getTruthMessage(gamecode, truthee, truther) {
  return new Promise((resolve, reject) => {
    getActivePlayers(gamecode).then((activePlayers) => {
      let severity = chooseSeverity(gamecode, activePlayers, truthee, truther);
      let truthsRef = firebase.database().ref("gameassets/truths/");
      truthsRef.child("/" + severity).once("value", (snapshot) => {
        if (snapshot.exists()) {
          let truths = snapshot.val();
          let idx = Math.floor(Math.random() * truths.length);
          let truth = truths[idx];
          let truthMsg = processMessage(truth, truthee.name, truther.name);
          resolve(truthMsg);
        }
      });
    });
  });
}

export function giveTruth(gamecode, trutherUid, trutheeUid) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  let trutheeRef = gameRef.child("/players/" + trutheeUid);
  let trutherRef = gameRef.child("/players/" + trutherUid);
  trutheeRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      var truthee = snapshot.val();
      trutherRef.once("value", (snapshot) => {
        if (snapshot.exists()) {
          let truther = snapshot.val();
          var truthMessage = getTruthMessage(gamecode, truthee, truther);
          truthMessage.then((truthVal) => {
            let trutherData = {
              typecode: 1,
              title: "You sent " + truthee.name + " " + truthee.emoji,
              emoji: "👄",
              description: truthVal,
            };
            let trutheeData = {
              typecode: 1,
              title: "TRUTH",
              emoji: "👄",
              description:
                truther.emoji +
                " " +
                truther.name +
                " wants to know... " +
                truthVal +
                " (or drink)",
            };
            let trutherPopups = truther.popups;
            trutherPopups.push(trutherData);
            trutherRef.child("/popups").set(trutherPopups);
            let trutheePopups = truthee.popups;
            trutheePopups.push(trutheeData);
            trutheeRef.child("/popups").set(trutheePopups);
          });
        }
      });
    }
  });
}

export function ban(gamecode, playerUid) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  let playerRef = firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid);
  playerRef.child("/IP").once("value", (snapshot) => {
    if (snapshot.exists()) {
      let banIP = snapshot.val();
      let newBannedIPs = [];
      gameRef.child("/bannedIPs").once("value", (snapshot) => {
        if (snapshot.exists()) {
          let ips = snapshot.val();
          if (ips.length > 0) {
            for (var i = 0; i < ips.length; i++) {
              let ip = ips[i];
              newBannedIPs.push(ip);
            }
          }
        }
      });
      newBannedIPs.push(banIP);
      gameRef.child("/bannedIPs").set(newBannedIPs);
    }
  });
  playerRef.child("/isBanned").set(true);
  playerRef.child("/kickself").set(true);
  playerRef.child("/isActive").set(false);
}

export function unban(gamecode, playerUid) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  let playerRef = firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid);
  playerRef.child("/IP").once("value", (snapshot) => {
    if (snapshot.exists()) {
      let unbanIP = snapshot.val();
      let newBannedIPs = [];
      gameRef.child("/bannedIPs").once("value", (snapshot) => {
        if (snapshot.exists()) {
          let ips = snapshot.val();
          if (ips.length > 0) {
            for (var i = 0; i < ips.length; i++) {
              let ip = ips[i];
              if (ip !== unbanIP) newBannedIPs.push(ip);
            }
          }
        }
      });
      gameRef.child("/bannedIPs").set(newBannedIPs);
    }
  });
  playerRef.child("/isBanned").set(false);
}

export function kick(gamecode, playerUid) {
  let playerRef = firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid);
  playerRef.child("/kickself").set(true);
  playerRef.child("/isActive").set(false);
}

export function votekick(gamecode, kickerUid, kickeeUid) {
  let now = new Date();
  let currTimeSecs = now.getTime() / 1000;
  getActivePlayers(gamecode).then((activePlayers) => {
    // Only allow votekicking if 3 or more players
    if (Object.keys(activePlayers).length < 3) return;
    let playerRef = firebase
      .database()
      .ref("games/" + gamecode + "/players/" + kickeeUid);
    playerRef.child("/votekicks").once("value", (snapshot) => {
      playerRef.child("votekicks/" + kickerUid).set(currTimeSecs);
      let votekicks = snapshot.val() ? snapshot.val() : {};
      votekicks[kickerUid] = currTimeSecs;
      // check whether or not every active player has votekicked
      //  'kickee' within the past 3 minutes
      let voted = {};
      Object.entries(activePlayers).forEach(([uid, _]) => {
        voted[uid] = false;
      });
      voted[kickeeUid] = true;
      for (const [uid, time] of Object.entries(votekicks)) {
        if (uid !== kickeeUid) {
          if (!Object.keys(activePlayers).includes(uid)) return;
          if (time < currTimeSecs - 180) return;
          voted[uid] = true;
        }
      }
      // only kick if all active players have recently votekicked
      if (Object.keys(voted).every((k) => voted[k])) {
        playerRef.child("/kickself").set(true);
        playerRef.child("/isActive").set(false);
      }
    });
  });
}

export function pause(gamecode, playerUid) {
  let playerRef = firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid);
  playerRef.child("/isPaused").set(true);
  playerRef.child("/isActive").set(false);
}

export function endTimeout(gamecode, playerUid) {
  let playerRef = firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid);
  playerRef.child("/inTimeout").set(false);
}

export function incrementPenalties(gamecode, playerUid) {
  let playerRef = firebase
    .database()
    .ref("games/" + gamecode + "/players/" + playerUid);
  playerRef.child("/penalties").once("value", (snapshot) => {
    if (snapshot.exists()) {
      let val = snapshot.val();
      if (val + 1 <= maxPenalties) {
        playerRef.child("/penalties").set(val + 1);
      }
    }
  });
}

export function initializePenalties(gamecode, penalties) {
  let playersRef = firebase.database().ref("games/" + gamecode + "/players");
  playersRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      if (typeof players !== "undefined" && Object.keys(players).length > 0) {
        for (var i = 0; i < Object.keys(players).length; i++) {
          playersRef
            .child("/" + Object.keys(players)[i] + "/penalties")
            .set(penalties);
        }
      }
    }
  });
}

export function cheatcodeReroute(gamecode, url) {
  let playersRef = firebase.database().ref("games/" + gamecode + "/players");
  playersRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      if (typeof players !== "undefined" && Object.keys(players).length > 0) {
        for (var i = 0; i < Object.keys(players).length; i++) {
          playersRef.child("/" + Object.keys(players)[i] + "/reroute").set(url);
        }
      }
    }
  });
}

export function playSound(gamecode, playerEmoji, url) {
  let playersRef = firebase.database().ref("games/" + gamecode + "/players");
  playersRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      if (playerEmoji === "all") {
        if (typeof players !== "undefined" && Object.keys(players).length > 0) {
          for (var i = 0; i < Object.keys(players).length; i++) {
            playersRef.child("/" + Object.keys(players)[i] + "/sound").set(url);
          }
        }
      } else {
        getUidFromEmoji(gamecode, playerEmoji).then((pUid) => {
          if (pUid !== null) playersRef.child("/" + pUid + "/sound").set(url);
        });
      }
    }
  });
}

export function processMessage(
  message,
  name1,
  name2,
  other1 = null,
  other2 = null,
  seconds = 0,
  rounds = 0
) {
  let newMessage = message.replaceAll("{:truther:}", name1);
  newMessage = newMessage.replaceAll("{:truthee:}", name2);
  newMessage = newMessage.replaceAll("{:darer:}", name1);
  newMessage = newMessage.replaceAll("{:daree:}", name2);
  if (other1 !== null)
    newMessage = newMessage.replaceAll(
      "{:other1:}",
      other1.emoji + " " + other1.name
    );
  if (other2 !== null)
    newMessage = newMessage.replaceAll(
      "{:other2:}",
      other2.emoji + " " + other2.name
    );
  newMessage = newMessage.replaceAll("{:drinker:}", name1);
  newMessage = newMessage.replaceAll("{:drinkee:}", name2);
  newMessage = newMessage.replaceAll("{:seconds:}", seconds);
  newMessage = newMessage.replaceAll("{:rounds:}", rounds);
  return newMessage;
}

export function getEmoji(gamecode, playerUid) {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref("games/" + gamecode + "/players/" + playerUid + "/emoji")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          let val = snapshot.val();
          if (val) resolve(val);
        }
      });
  });
}

export function getEmojiList(player, im18, override = false) {
  let emojiList = player.isGM ? "🔑" : "";
  if ((im18 && !player.is18) || (override && !player.is18)) {
    emojiList += "🔞";
  } else {
    if (
      (im18 && player.showGender === true && player.gender !== 0) ||
      (override && player.gender !== 0)
    ) {
      emojiList +=
        player.gender === 1
          ? "🚹"
          : player.gender === 2
          ? "🚺"
          : player.gender === 3
          ? "🚻"
          : "";
    }
    if (
      (im18 && player.showSexuality === true && player.sexuality !== 0) ||
      (override && player.sexuality !== 0)
    ) {
      emojiList +=
        player.sexuality === 1
          ? "📏"
          : player.sexuality === 2
          ? "🌈"
          : player.sexuality === 3
          ? "💘"
          : "";
    }
    if (
      (im18 && player.showStatus === true && player.status !== 0) ||
      (override && player.status !== 0)
    ) {
      emojiList +=
        player.status === 1
          ? "📫"
          : player.status === 2
          ? "📪"
          : player.status === 3
          ? "📭"
          : "";
    }
    if (
      (im18 && player.showHeat === true && player.heat !== 0) ||
      (override && player.heat !== 0)
    ) {
      emojiList +=
        player.heat === 1
          ? "❄️"
          : player.heat === 2
          ? "🌨"
          : player.heat === 3
          ? "🌧"
          : player.heat === 4
          ? "☁️"
          : player.heat === 5
          ? "🌥"
          : player.heat === 6
          ? "🌤"
          : player.heat === 7
          ? "☀️"
          : player.heat === 8
          ? "🔥"
          : player.heat === 9
          ? "👅"
          : player.heat === 10
          ? "🍆"
          : "";
    }
  }
  if (player.inTimeout) {
    emojiList += "⏱";
  }
  if (player.isPaused) {
    emojiList += "⏸";
  }
  let playerPartner = player.partner;
  if (
    (player.showStatus === true && playerPartner.mutual === true) ||
    (override && playerPartner.mutual === true)
  ) {
    return emojiList + "❤️" + playerPartner.emoji;
  } else {
    return emojiList;
  }
}

export function setPartner(gamecode, myUid, partnerUid, myEmoji, partnerEmoji) {
  let playersRef = firebase.database().ref("games/" + gamecode + "/players");
  playersRef.child("/" + partnerUid + "/partner").once("value", (snapshot) => {
    if (snapshot.exists()) {
      let proposedPartner = snapshot.val();
      let proposedPartnerMutual = proposedPartner.partnerUid === myUid;
      if (proposedPartnerMutual) {
        playersRef
          .child("/" + myUid + "/partner")
          .set({ partnerUid: partnerUid, mutual: true, emoji: partnerEmoji });
        playersRef.child("/" + partnerUid + "/partner/mutual").set(true);
        playersRef.child("/" + partnerUid + "/partner/emoji").set(myEmoji);
      } else {
        playersRef.child("/" + myUid + "/partner").once("value", (snapshot) => {
          if (snapshot.exists()) {
            let myPartner = snapshot.val();
            if (myPartner.mutual) {
              playersRef
                .child("/" + myPartner.partnerUid + "/partner")
                .set({ partnerUid: "", mutual: false, emoji: "" });
              playersRef
                .child("/" + myUid + "/partner")
                .set({ partnerUid: partnerUid, mutual: false, emoji: "" });
            } else {
              playersRef
                .child("/" + myUid + "/partner")
                .set({ partnerUid: partnerUid, mutual: false, emoji: "" });
            }
          } else {
            playersRef
              .child("/" + myUid + "/partner")
              .set({ partnerUid: partnerUid, mutual: false, emoji: "" });
          }
        });
      }
    }
  });
}

export function removePartner(gamecode, myUid, partnerUid) {
  let playersRef = firebase.database().ref("games/" + gamecode + "/players");
  playersRef
    .child("/" + myUid + "/partner")
    .set({ partnerUid: "", mutual: false, emoji: "" });
  playersRef.child("/" + partnerUid + "/partner/mutual").set(false);
}

export function setTeleprompter(gamecode, msg) {
  let gameRef = firebase.database().ref("/games/" + gamecode);
  gameRef.child("/teleprompter").set(msg);
}

export function setRule(gamecode, msg) {
  let gameRef = firebase.database().ref("/games/" + gamecode);
  gameRef.child("/rule").set(msg);
}
