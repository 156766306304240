import React from "react";
import styled from "styled-components";

import { TextField, Button } from "@material-ui/core";

import { setTeleprompter } from "../utilities/utils.js";

import parameters from "../constants/parameters.js";

const Container = styled.div`
  margin: 2rem;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

class Prompter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      msg: "",
      closeFunc: props.closeFunc,
    };
    this.handleChangeMsg = this.handleChangeMsg.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleChangeMsg = (e) => {
    if (e.target.value.length <= parameters.maxTeleprompterLength)
      this.setState({ msg: e.target.value });
  };

  handleSend = () => {
    setTeleprompter(this.state.gamecode, this.state.msg);
    this.state.closeFunc();
  };

  render() {
    return (
      <Container>
        <Title>
          <b>Change Teleprompter</b> <Emoji> 📺</Emoji>
        </Title>
        <TextField
          style={{ width: "100%" }}
          onChange={(e) => this.handleChangeMsg(e)}
          label="Message"
          value={this.state.msg}
        />
        <br />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            // disabled={this.state.msg === ""}
            size="small"
            onClick={this.handleSend}
            style={{ marginTop: "1rem" }}
          >
            Submit
          </Button>
        </div>
      </Container>
    );
  }
}

export default Prompter;
