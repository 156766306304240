import React from "react";
import styled from "styled-components";

import { Button, Menu, Grid, Dialog } from "@material-ui/core";

import PlayerListPopup from "./PlayerListPopup";
import EndGamesPopup from "./EndGamesPopup";
import Broadcast from "./Broadcast";
import Prompter from "./Prompter";

import { nextRound, triggerAsync } from "../utilities/gameUtilities";

import { GMColor } from "../constants/colors";

const Container = styled(Grid)`
  background-color: ${GMColor};
  cursor: default;
`;

const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  white-space: nowrap;
  margin-top: 2rem;
`;

const ControlButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  margin: 1.5rem;
  padding: 1rem;
`;

const ControlButton = styled(Button)`
  text-align: center;
  white-space: nowrap;
`;

class GMControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      handleGMControlsClose: props.handleGMControlsClose,
      playerListOpen: false,
      prompterOpen: false,
      confirmEnd: false,
      anchorEl: null,
      type: "",
      broadcasterOpen: false,
      round: props.round,
      rounds: props.advanced[0],
      presentationsEnabled: props.advanced[9],
    };

    this.handleAwardPenalty = this.handleAwardPenalty.bind(this);
    this.handleBanPlayer = this.handleBanPlayer.bind(this);
    this.handleUnbanPlayer = this.handleUnbanPlayer.bind(this);
    this.handleKickPlayer = this.handleKickPlayer.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.handlePausePlayer = this.handlePausePlayer.bind(this);
    this.handleNextRound = this.handleNextRound.bind(this);
    this.handlePresentations = this.handlePresentations.bind(this);
    this.handleTransferGM = this.handleTransferGM.bind(this);
    this.handleEndGames = this.handleEndGames.bind(this);
    this.handleTeleprompter = this.handleTeleprompter.bind(this);
    this.handleTeleprompterOpen = this.handleTeleprompterOpen.bind(this);
    this.handleTeleprompterClose = this.handleTeleprompterClose.bind(this);
    this.handleBroadcast = this.handleBroadcast.bind(this);
    this.handleBroadcastOpen = this.handleBroadcastOpen.bind(this);
    this.handleBroadcastClose = this.handleBroadcastClose.bind(this);
    this.handleConfirmEndOpen = this.handleConfirmEndOpen.bind(this);
    this.handleConfirmEndClose = this.handleConfirmEndClose.bind(this);
    this.handleTriggerAsync = this.handleTriggerAsync.bind(this);

    this.handlePlayerListOpen = this.handlePlayerListOpen.bind(this);
    this.handlePlayerListClose = this.handlePlayerListClose.bind(this);
  }

  handleAwardPenalty = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "gmawardpenalty" });
    this.handlePlayerListOpen();
  };

  handleBanPlayer = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "gmban" });
    this.handlePlayerListOpen();
  };

  handleUnbanPlayer = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "gmunban" });
    this.handlePlayerListOpen();
  };

  handleKickPlayer = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "gmkick" });
    this.handlePlayerListOpen();
  };

  handleTimeout = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "endtimeout" });
    this.handlePlayerListOpen();
  };

  handlePausePlayer = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "gmpause" });
    this.handlePlayerListOpen();
  };

  handleNextRound = () => {
    nextRound(this.state.gamecode);
    this.state.handleGMControlsClose();
  };

  handlePresentations = () => {
    this.state.handleGMControlsClose();
  };

  handleTriggerAsync = () => {
    triggerAsync(this.state.gamecode);
  };

  handleTransferGM = (event) => {
    this.setState({ anchorEl: event.currentTarget, type: "changegm" });
    this.handlePlayerListOpen();
  };

  handleEndGames = () => {
    this.handleConfirmEndOpen();
  };

  handleTeleprompter = () => {
    this.handleTeleprompterOpen();
  };

  handleTeleprompterOpen = () => {
    this.setState({ prompterOpen: true });
  };

  handleTeleprompterClose = () => {
    this.setState({ prompterOpen: false });
  };

  handleBroadcast = () => {
    this.handleBroadcastOpen();
  };

  handleBroadcastOpen = () => {
    this.setState({ broadcasterOpen: true });
  };

  handleBroadcastClose = () => {
    this.setState({ broadcasterOpen: false });
  };

  handleConfirmEndOpen = () => {
    this.setState({ confirmEnd: true });
  };

  handleConfirmEndClose = () => {
    this.setState({ confirmEnd: false });
  };

  handlePlayerListOpen = () => {
    this.setState({ playerListOpen: true });
  };

  handlePlayerListClose = () => {
    this.setState({ playerListOpen: false, anchorEl: null });
  };

  render() {
    return (
      <>
        <Dialog
          //other popups
          onClose={this.handleBroadcastClose}
          open={this.state.broadcasterOpen}
        >
          <Broadcast
            gamecode={this.state.gamecode}
            closeFunc={this.handleBroadcastClose}
          />
        </Dialog>
        <Dialog
          //other popups
          onClose={this.handleTeleprompterClose}
          open={this.state.prompterOpen}
        >
          <Prompter
            gamecode={this.state.gamecode}
            closeFunc={this.handleTeleprompterClose}
          />
        </Dialog>
        <Dialog
          onClose={this.handleConfirmEndClose}
          open={this.state.confirmEnd}
        >
          <EndGamesPopup gamecode={this.state.gamecode} />
        </Dialog>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.playerListOpen}
          onClose={this.handlePlayerListClose}
          PaperProps={{
            style: {
              maxHeight: "50rem",
              width: "20ch",
            },
          }}
        >
          <PlayerListPopup
            gamecode={this.state.gamecode}
            myUid={this.state.myUid}
            type={this.state.type}
            closeMenu={this.handlePlayerListClose}
            closeGMControls={this.state.handleGMControlsClose}
          />
        </Menu>
        <Container container>
          <Grid item xs={12}>
            <Title>
              🔑 <b>Gamemaster Controls</b>
            </Title>
          </Grid>
          <br />
          <ControlButtonContainer>
            {this.state.round !== this.state.rounds && (
              <Grid item xs={12} sm={4}>
                <ControlButton onClick={this.handleNextRound}>
                  Next Round ⏩
                </ControlButton>
              </Grid>
            )}
            {this.state.round === this.state.rounds &&
              !this.state.presentationsEnabled && (
                <Grid item xs={12} sm={4}>
                  <ControlButton disabled>Next Round ⏩</ControlButton>
                </Grid>
              )}
            {this.state.round === this.state.rounds &&
              this.state.presentationsEnabled && (
                <Grid item xs={12} sm={4}>
                  <ControlButton onClick={this.handlePresentations}>
                    Presentations 💃
                  </ControlButton>
                </Grid>
              )}
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleBanPlayer}>
                Ban Player 🔨
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleUnbanPlayer}>
                Unban Player ✅
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleKickPlayer}>
                Kick Player 🥾
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleTimeout}>
                End Timeout ⏱
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handlePausePlayer}>
                Pause Player ⏸
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleAwardPenalty}>
                Award Penalty 🍺
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* <ControlButton disabled onClick={this.handleSkipToEnd}>
                Skip to End ⏭
              </ControlButton> */}
              <ControlButton onClick={this.handleTriggerAsync}>
                Trigger Async ⏰
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleTransferGM}>
                Transfer GM 🔑
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              {/* <ControlButton disabled onClick={this.handleModifyRooms}>
                Modify Rooms 🚪
              </ControlButton> */}
              <ControlButton onClick={this.handleTeleprompter}>
                Teleprompter 📺
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleBroadcast}>
                Broadcast 📣
              </ControlButton>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlButton onClick={this.handleEndGames}>
                End Games 🛑
              </ControlButton>
            </Grid>
          </ControlButtonContainer>
        </Container>
      </>
    );
  }
}

export default GMControls;
