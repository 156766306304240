import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 2rem;
  font-family: Arial;
  text-align: left;
  z-index: 1000;
  position: absolute;
  font-size: 1rem;
  overflow: scroll;
`;
const WhiteOut = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
  margin: 0;
`;

const Terms = (props) => {
  return (
    <>
      <WhiteOut />
      <Container>
        <p>
          We want you to enjoy these games in a responsible way and for your
          experience with our site to be a positive one. While we know that
          alcohol may be involved in your game, it is not required to have fun
          with the game. These games are intended for entertainment purposes of
          responsible adults of legal drinking age and there should not be any
          underage drinking when playing these games, or at events where these
          games may be played. The games and accompanying rules are only
          suggestions. We support and promote responsible drinking. That means
          knowing your limits and drinking with caution and in moderation. It is
          the players’ responsibility to monitor and moderate their own alcohol
          consumption. This includes hydrating between drinks with water, and
          planning ahead for your safety at the event and for a safe ride home
          or to wherever you may be traveling – all before you begin drinking.
          We do not support, or recommend - and we discourage - excess drinking
          or driving or operating any machinery or equipment, all of which can
          be damaging to your health and safety and the health and safety of
          others. It is not the intent of anyone associated with The Drinking
          Games to promote misuse of alcohol, alcoholism, hazing, binge drinking
          or any other form of alcohol abuse. We cannot take any responsibility
          for any effects or harm caused by the consumption of alcohol.
          According to the Surgeon General of the United States (1) women should
          not drink alcoholic beverages during pregnancy because of the risk of
          birth defects and (2) consumption of alcoholic beverages impairs your
          ability to operate machinery and may cause health problems. The
          Drinking Games, its owners, principals, heirs, assigns,
          representatives, employees, and agents ("The Drinking Games Persons")
          disclaim and have no responsibility, collectively or individually and
          are not liable for special or consequential damages resulting from the
          use, or misuse, of this site, any alcohol consumption, any game rules,
          or playing any game, or regardless of whether The Drinking Games or
          any The Drinking Games Person has been advised of the possibility of
          such damages.
        </p>
      </Container>
    </>
  );
};

export default Terms;
