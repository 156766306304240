import React from "react";
import styled from "styled-components";

import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import RoomSettingItem from "./RoomSettingItem.js";
import GameSettingItem from "./GameSettingItem.js";
import ActivitySettingItem from "./ActivitySettingItem.js";
import AsyncSettingItem from "./AsyncSettingItem.js";
import AdvancedSettings from "./AdvancedSettings.js";
// import AddItem from "./AddItem.js";

import { ButtonColor } from "../../constants/colors";

const MainContainer = styled.div`
  cursor: default;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

export default class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModalFunction: props.closeFunc,
      gameItemsFunction: props.gameItemsFunc,
      expanded: false,
      roomList: ["Living Room", "Outside"],
      mainRoom: 0,
      activitiesList: props.items.activities,
      finalActivitiesList: props.items.activities,
      gamesList: props.items.games,
      finalGamesList: props.items.games,
      asyncList: props.items.asynchronous,
      finalAsyncList: props.items.asynchronous,
      advancedList: [25, false, true, 5, 3, true, 3, true, 2, true],
      finalAdvancedList: [25, false, true, 5, 3, true, 3, true, 2, true],
      rerender: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickedDone = this.handleClickedDone.bind(this);
    this.handleClickedAdd = this.handleClickedAdd.bind(this);
    this.handleClickedDelete = this.handleClickedDelete.bind(this);
    this.handleRoomNameChange = this.handleRoomNameChange.bind(this);
    this.handleChangeMainRoom = this.handleChangeMainRoom.bind(this);
    this.updateActivitiesFunc = this.updateActivitiesFunc.bind(this);
    this.updateGamesFunc = this.updateGamesFunc.bind(this);
    this.updateAsyncFunc = this.updateAsyncFunc.bind(this);
    this.updateAdvancedFunc = this.updateAdvancedFunc.bind(this);
    this.rerender = this.rerender.bind(this);
  }

  componentDidMount() {}

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleClickedAdd() {
    if (this.state.roomList.length < 10) {
      let newRoomList = this.state.roomList;
      newRoomList.push("");
      this.setState({ roomList: newRoomList });
    }
  }

  handleClickedDelete(idx) {
    let newRoomList = this.state.roomList;
    newRoomList.splice(idx, 1);
    this.setState({
      rerender: !this.state.rerender,
      mainRoom:
        idx < this.state.mainRoom
          ? this.state.mainRoom - 1
          : this.state.mainRoom,
      roomList: newRoomList,
    });
  }

  handleRoomNameChange(newName, idx) {
    let rooms = this.state.roomList;
    rooms[idx] = newName;
    this.setState({ roomList: rooms });
  }

  handleChangeMainRoom(idx) {
    this.setState({ rerender: !this.state.rerender, mainRoom: idx });
  }

  getRoomList() {
    let roomList = this.state.roomList.map((name, idx) => {
      return (
        <ListItem key={idx}>
          <RoomSettingItem
            idx={idx}
            deleteFunc={this.handleClickedDelete}
            changeNameFunc={this.handleRoomNameChange}
            setMainFunc={this.handleChangeMainRoom}
            name={name}
            isMainroom={idx === this.state.mainRoom}
          />
        </ListItem>
      );
    });
    return roomList;
  }

  updateGamesFunc(idx, changeType, val) {
    let newFinalGamesList = this.state.finalGamesList;
    if (changeType === "switch") newFinalGamesList[idx]["inGame"] = val;
    if (changeType === "rounds") newFinalGamesList[idx]["duration"] = val;
    this.setState({ finalGamesList: newFinalGamesList });
  }

  getGames() {
    if (this.state.gamesList) {
      let gamesList = this.state.gamesList.map((game, idx) => {
        let name = game.game;
        let emoji = game.emoji;
        let enabled = game.inGame;
        let duration = game.duration;
        return (
          <ListItem key={idx}>
            <GameSettingItem
              idx={idx}
              emoji={emoji}
              name={name}
              switch={enabled}
              numRounds={duration}
              updateFunc={this.updateGamesFunc}
            />
          </ListItem>
        );
      });
      return gamesList;
    }
  }

  updateActivitiesFunc(idx, changeType, val) {
    let newFinalActivitiesList = this.state.finalActivitiesList;
    if (changeType === "switch") newFinalActivitiesList[idx]["inGame"] = val;
    if (changeType === "rounds") newFinalActivitiesList[idx]["duration"] = val;
    this.setState({ finalActivitiesList: newFinalActivitiesList });
  }

  getActivities() {
    let activitiesList = this.state.activitiesList.map((activity, idx) => {
      let name = activity.activity;
      let emoji = activity.emoji;
      let active = activity.active;
      let enabled = activity.inGame;
      let numRounds = activity.duration;
      if (active) {
        return (
          <ListItem key={idx}>
            <ActivitySettingItem
              idx={idx}
              emoji={emoji}
              name={name}
              switch={enabled}
              numRounds={numRounds}
              updateFunc={this.updateActivitiesFunc}
            />
          </ListItem>
        );
      }
      return null;
    });
    return activitiesList;
  }

  updateAsyncFunc(idx, val) {
    let newFinalAsyncList = this.state.finalAsyncList;
    newFinalAsyncList[idx]["inGame"] = val;
    this.setState({ finalAsyncList: newFinalAsyncList });
  }

  getAsync() {
    let asyncList = this.state.asyncList.map((async, idx) => {
      let name = async.async;
      let emoji = async.emoji;
      let active = async.active;
      let enabled = async.inGame;
      if (active) {
        return (
          <ListItem key={idx}>
            <AsyncSettingItem
              idx={idx}
              emoji={emoji}
              name={name}
              switch={enabled}
              updateFunc={this.updateAsyncFunc}
            />
          </ListItem>
        );
      }
      return null;
    });
    return asyncList;
  }

  updateAdvancedFunc(changeType, val) {
    let newFinalAdvancedList = this.state.finalAdvancedList;
    if (changeType === "numRounds") newFinalAdvancedList[0] = val;
    if (changeType === "ageSwitch") newFinalAdvancedList[1] = val;
    if (changeType === "timeoutSwitch") newFinalAdvancedList[2] = val;
    if (changeType === "maxTimeoutLength") newFinalAdvancedList[3] = val;
    if (changeType === "timeoutFrequency") newFinalAdvancedList[4] = val;
    if (changeType === "penaltyZone") newFinalAdvancedList[5] = val;
    if (changeType === "startingPenalties") newFinalAdvancedList[6] = val;
    if (changeType === "privateMessages") newFinalAdvancedList[7] = val;
    if (changeType === "broadcastPrivateMessagePercentage")
      newFinalAdvancedList[8] = val;
    if (changeType === "presentationsEnabled") newFinalAdvancedList[9] = val;

    this.setState({ finalAdvancedList: newFinalAdvancedList });
  }

  rerender() {
    this.setState({ rerender: !this.state.rerender });
  }

  handleClickedDone() {
    let roomList = this.state.roomList;
    let mainRoom = this.state.roomList[this.state.mainRoom];
    roomList[this.state.mainRoom] = "";
    let finalRoomList = [];
    roomList.forEach((name) => {
      if (name && name !== "" && name !== null) finalRoomList.push(name);
    });

    this.state.gameItemsFunction(
      finalRoomList,
      mainRoom,
      this.state.finalGamesList,
      this.state.finalActivitiesList,
      this.state.finalAsyncList,
      this.state.finalAdvancedList
    );

    if (finalRoomList.length > 0) this.state.closeModalFunction();
  }

  render() {
    return (
      <MainContainer>
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "1rem",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        >
          ⚙️ Game Settings
        </div>
        <MuiDialogContent>
          {/* <Accordion
            expanded={this.state.expanded === "panel1"}
            onChange={this.handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>🚪 Rooms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: "100%" }}>
                {this.state.rerender && this.getRoomList()}
                {!this.state.rerender && this.getRoomList()}
                <ListItem>
                  <AddItem type={"Room"} addFunc={this.handleClickedAdd} />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={this.state.expanded === "panel2"}
            onChange={this.handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography>🕹 Games</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: "100%" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}>
                    <div style={{ textAlign: "center" }}># Rounds</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: "center" }}>Active</div>
                  </Grid>
                </Grid>
                {this.getGames()}
              </List>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={this.state.expanded === "panel3"}
            onChange={this.handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography>🧸 Activities</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: "100%" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}>
                    <div style={{ textAlign: "center" }}># Rounds</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: "center" }}>Active</div>
                  </Grid>
                </Grid>
                {this.getActivities()}
              </List>
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={this.state.expanded === "panel4"}
            onChange={this.handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography>⏰ Asynchronous</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: "100%" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: "center" }}>Active</div>
                  </Grid>
                </Grid>
                {this.getAsync()}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={this.state.expanded === "panel5"}
            onChange={this.handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography>🔧 Advanced Settings</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AdvancedSettings
                advancedSettings={this.state.advancedList}
                updateFunc={this.updateAdvancedFunc}
              />
            </AccordionDetails>
            {/* <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Adult Settings 👅</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Coming Soon...</Typography>
              </AccordionDetails>
            </Accordion> */}
          </Accordion>
        </MuiDialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="medium"
            style={{
              width: "50%",
              marginBottom: "1rem",
              marginTop: "1rem",
              backgroundColor: ButtonColor,
            }}
            variant="outlined"
            color="primary"
            onClick={this.handleClickedDone}
          >
            Done
          </Button>
        </div>
      </MainContainer>
    );
  }
}
