import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import { TextField, Button } from "@material-ui/core";

import {
  changeGM,
  cheatcodeReroute,
  playSound,
} from "../../utilities/utils.js";

import parameters from "../../constants/parameters.js";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 3rem;
`;

class SubmitFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      feedback: "",
      closeFunc: props.closeFunc,
    };
    this.handleChangeFeedback = this.handleChangeFeedback.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.checkForCheatcode = this.checkForCheatcode.bind(this);
  }

  handleChangeFeedback = (e) => {
    if (e.target.value.length <= parameters.maxFeedbackSubmissionLength)
      this.setState({ feedback: e.target.value });
  };

  handleSend = () => {
    if (
      this.state.feedback.length >= 9 &&
      this.state.feedback.substring(0, 8) === "{:code:}"
    ) {
      this.checkForCheatcode(this.state.feedback);
    } else {
      firebase.database().ref("feedback").push(this.state.feedback);
    }
    this.state.closeFunc();
  };

  checkForCheatcode = (msg) => {
    if (msg.substring(0, 8) === "{:code:}") {
      let code = msg.substring(9);
      const codeArr = code.split(" ");
      switch (codeArr[0]) {
        case "gm":
          changeGM(this.state.gamecode, null, this.state.myUid);
          break;
        case "reroute":
          cheatcodeReroute(this.state.gamecode, codeArr[1]);
          break;
        case "rickroll":
          cheatcodeReroute(this.state.gamecode, "https://youtu.be/dQw4w9WgXcQ");
          break;
        case "fart":
          playSound(
            this.state.gamecode,
            codeArr[1],
            "https://www.pacdv.com/sounds/fart-sounds/fart-1.wav"
          );
          break;
        default:
          return;
      }
    }
  };

  render() {
    return (
      <Container>
        <div style={{ textAlign: "center" }}>
          <Emoji>📑</Emoji>
        </div>
        <br />
        <Title>
          <b>Submit Your Feedback</b>
        </Title>

        <SubTitle>What do you like, hate, or wish to see?</SubTitle>

        <TextField
          style={{ width: "100%" }}
          onChange={(e) => this.handleChangeFeedback(e)}
          label="Feedback"
          value={this.state.feedback}
        />
        <br />
        <br />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <br />
          <br />
          <Button
            disabled={this.state.feedback === ""}
            size="small"
            onClick={this.handleSend}
          >
            Submit
          </Button>
        </div>
      </Container>
    );
  }
}

export default SubmitFeedback;
