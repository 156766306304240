import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import firebase from "../firebase";

import { lineHeight } from "../constants/dimensions";

const Container = styled.div`
  height: calc(${lineHeight} / 2);
  font-size: 3vh;
  position: relative;
  //   background-color: blue;
  // &:hover {
  //   background-color: grey;
  // }
  cursor: default;
`;

const TeleprompterText = styled.span`
  width: 100vw;
  position: absolute;
  font-weight: bolder;
  font-size: 2.5vh;
  white-space: nowrap;
`;

var temp = 100;
var temp2 = 25;

const teleprompterAnimation = keyframes`
    0% { transform: translate(100vw); }
    100% { transform: translate(calc(-200vw - ${temp}vw)); }
`;

const ScrollingText = styled.div`
  animation-name: ${teleprompterAnimation};
  animation-duration: ${temp2}s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const Teleprompter = (props) => {
  const [teleprompter, setTeleprompter] = useState("");
  const [gamecode] = useState(props.gamecode);

  useEffect(() => {
    firebase
      .database()
      .ref("/games/" + gamecode)
      .child("/teleprompter")
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          let val = snapshot.val();
          setTeleprompter(val);
        }
      });
  }, [gamecode]);

  return (
    <Container>
      <ScrollingText>
        <TeleprompterText>{teleprompter}</TeleprompterText>
      </ScrollingText>
    </Container>
  );
};

export default Teleprompter;
