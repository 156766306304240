import React, { useEffect, useState } from "react";
import styled from "styled-components";
import firebase from "../firebase";

import { Menu, MenuItem } from "@material-ui/core";

import {
  kick,
  votekick,
  ban,
  changeGM,
  getEmojiList,
  setPartner,
  removePartner,
} from "../utilities/utils.js";

import { lineHeight, redLineMargin } from "../constants/dimensions";

const Container = styled.div`
  height: calc(1.5 * ${lineHeight});
  font-size: 3vh;
  // background-color: yellow;
  // &:hover {
  //   background-color: grey;
  // }
  cursor: default;
`;

const Round = styled.div`
  height: ${lineHeight};
  text-align: left;
  margin-left: ${redLineMargin};
  position: relative;
  padding-left: 0.5rem;
  // background-color: orange;
  white-space: nowrap;
`;

const RoundText = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 2.75vh;
`;

const Players = styled.div`
  text-align: left;
  margin-left: ${redLineMargin};
  padding-left: 0.5rem;
  white-space: nowrap;
  // background-color: orange;
`;

const PlayerText = styled.span`
  margin: 1rem;
  font-size: 2vh;
  color: #aaaaaa;
  &:nth-child(1) {
    margin-left: 0rem;
    color: black;
    font-weight: bolder;
  }
  cursor: pointer;
`;
const database = firebase.database();

const RoundInfo = (props) => {
  const [isGM] = useState(props.isGM);
  const [im18] = useState(props.im18);
  const [gamecode] = useState(props.gamecode);
  const [myUid] = useState(props.myUid);
  const [imTaken] = useState(props.status === 2);
  const [hasStarted] = useState(props.hasStarted);
  const [players, setPlayers] = useState([]);
  const [round] = useState(props.round);
  const [game] = useState(props.game);
  const [gameEmoji] = useState(props.gameEmoji);
  const [playerOrder] = useState(props.playerOrder);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [selectedPlayerUid, setSelectedPlayerUid] = useState("");
  const [selectedPlayerPartner, setSelectedPlayerPartner] = useState({});
  const open = Boolean(anchorEl);

  const handleClick = (event, value, uid) => {
    setAnchorEl(event.currentTarget);
    setSelectedPlayer(value);
    setSelectedPlayerPartner(value.partner);
    setSelectedPlayerUid(uid);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let isMounted = true;
    const gameRef = database.ref("games/" + gamecode).child("/players");
    // possibly .on() instead?
    // TODO possibly hanging here for a while?
    gameRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const players = snapshot.val();
        if (isMounted) setPlayers(players);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [gamecode]);

  const getPlayers = () => {
    const playerComponents = [];
    if (Object.keys(players).length > 0) {
      for (let i = 0; i < playerOrder.length; i++) {
        let pUid = playerOrder[i];
        let p = players[pUid];
        if (p.isActive && !p.inTimeout && p.isReady) {
          if (pUid === myUid) {
            playerComponents.push(
              <PlayerText key={pUid}>
                {p.emoji} {p.name}
              </PlayerText>
            );
          } else {
            playerComponents.push(
              <PlayerText onClick={(e) => handleClick(e, p, pUid)} key={pUid}>
                {p.emoji} {p.name}
              </PlayerText>
            );
          }
        }
      }
    }
    // Object.entries(players).map(([key, value]) => {
    //   if (value.isActive && !value.inTimeout) {
    //     if (key === props.myUid) {
    //       playerComponents.push(
    //         <PlayerText key={key}>
    //           {value.emoji}
    //           {value.name}
    //         </PlayerText>
    //       );
    //     } else {
    //       playerComponents.push(
    //         <PlayerText onClick={(e) => handleClick(e, value, key)} key={key}>
    //           {value.emoji}
    //           {value.name}
    //         </PlayerText>
    //       );
    //     }
    //   }
    // });
    return playerComponents;
  };

  const handleSendMessage = () => {
    props.selectRecipient(
      selectedPlayerUid,
      selectedPlayer.name,
      selectedPlayer.emoji
    );
    handleClose();
    props.handleSendDmOpen();
  };

  const handleChangeGM = () => {
    changeGM(gamecode, myUid, selectedPlayerUid);
    handleClose();
  };

  const handleVotekick = () => {
    votekick(gamecode, myUid, selectedPlayerUid);
    handleClose();
  };

  const handleKick = () => {
    kick(gamecode, selectedPlayerUid);
    handleClose();
  };

  const handleBan = () => {
    ban(gamecode, selectedPlayerUid);
    handleClose();
  };

  const handleSetPartner = () => {
    setPartner(
      gamecode,
      myUid,
      selectedPlayerUid,
      props.myEmoji,
      selectedPlayer.emoji
    );
    handleClose();
  };

  const handleRemovePartner = () => {
    removePartner(gamecode, myUid, selectedPlayerUid);
    handleClose();
  };

  return (
    <>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "50rem",
            width: "20ch",
          },
        }}
      >
        {" "}
        <div style={{ textAlign: "center" }}>
          <span style={{ fontSize: "2.5rem" }}>{selectedPlayer.emoji}</span>{" "}
          <br />
          {selectedPlayer.name}
          <br />
          {Object.keys(selectedPlayer).length !== 0 &&
            getEmojiList(selectedPlayer, im18)}
          <br />
        </div>
        <br />
        {isGM && (
          <div>
            <MenuItem onClick={handleChangeGM}>Make Gamemaster</MenuItem>
            <MenuItem onClick={handleKick}>Kick</MenuItem>
            <MenuItem onClick={handleBan}>Ban</MenuItem>
            {props.advanced[7] && (
              <MenuItem onClick={handleSendMessage}>Message</MenuItem>
            )}
            <MenuItem onClick={handleVotekick}>Votekick</MenuItem>
            {selectedPlayer.is18 &&
              im18 &&
              imTaken &&
              selectedPlayer.showStatus &&
              selectedPlayer.status === 2 &&
              !(
                selectedPlayerPartner.partnerUid === myUid &&
                selectedPlayerPartner.mutual
              ) && (
                <MenuItem onClick={handleSetPartner}>
                  Set as Partner ♥︎
                </MenuItem>
              )}
            {selectedPlayerPartner.partnerUid === myUid &&
              selectedPlayerPartner.mutual && (
                <MenuItem onClick={handleRemovePartner}>
                  Remove as Partner
                </MenuItem>
              )}
          </div>
        )}
        {!isGM && (
          <div>
            {props.advanced[7] && (
              <MenuItem onClick={handleSendMessage}>Message</MenuItem>
            )}
            <MenuItem onClick={handleVotekick}>Votekick</MenuItem>
            {selectedPlayer.is18 &&
              im18 &&
              imTaken &&
              selectedPlayer.showStatus &&
              selectedPlayer.status === 2 &&
              !(
                selectedPlayerPartner.partnerUid === myUid &&
                selectedPlayerPartner.mutual
              ) && (
                <MenuItem onClick={handleSetPartner}>
                  Set as Partner ♥︎
                </MenuItem>
              )}
            {selectedPlayerPartner.partnerUid === myUid &&
              selectedPlayerPartner.mutual && (
                <MenuItem onClick={handleRemovePartner}>
                  Remove as Partner
                </MenuItem>
              )}
          </div>
        )}
      </Menu>
      <Container>
        <Round>
          {/* <RoundText>Round 13: Categories</RoundText> */}
          {!hasStarted && <RoundText>The Drinking Games</RoundText>}
          {hasStarted && (
            <RoundText>
              {round}: {game} {gameEmoji}
              {/* Round {round}: {game} {gameEmoji} */}
            </RoundText>
          )}
        </Round>
        {hasStarted === true && <Players>{getPlayers()}</Players>}
        {hasStarted === false && <Players> </Players>}
      </Container>
    </>
  );
};

export default RoundInfo;
