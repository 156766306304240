import firebase from "../firebase";
import parameters from "../constants/parameters";
import { setTeleprompter, getActivePlayers } from "./utils";
import {
  rouletteWheelEveryColor,
  rouletteWheelOtherColor,
  rouletteWheelNobodyColor,
} from "../constants/colors";

export function startGames(gamecode) {
  initPlayerOrder(gamecode);
  nextRound(gamecode);
  setTeleprompter(gamecode, "");
  firebase
    .database()
    .ref("/games/" + gamecode + "/hasStarted")
    .set(true);
}

function initPlayerOrder(gamecode) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  gameRef.child("/players").once("value", (snapshot) => {
    if (snapshot.exists()) {
      var order = [];
      let players = snapshot.val();

      for (let i = 0; i < Object.keys(players).length; i++) {
        order.push(Object.keys(players)[i]);
      }
      gameRef.child("/playerOrder").set(order);
    }
  });
}

export function nextRound(gamecode) {
  let gameRef = firebase.database().ref("/games/" + gamecode);
  gameRef.once("value", (snapshot) => {
    if (snapshot.exists()) {
      let game = snapshot.val();
      if (game.round < game.rounds) {
        gameRef
          .child("/round")
          .set(
            game.round === 0
              ? 1
              : game.round + game.game.duration <= game.rounds
              ? game.round + game.game.duration
              : game.rounds
          );
        let nextGame = getNextGame(game.games, gamecode);
        let nextGameName = nextGame[0];
        let nextGameEmoji = nextGame[1];
        let nextGameDuration = nextGame[2];
        let nextGameDescription = nextGame[3];
        gameRef.child("/game/game").set(nextGameName);
        gameRef.child("/game/gameEmoji").set(nextGameEmoji);
        gameRef
          .child("/game/gameDescription")
          .set(nextGameDescription ? nextGameDescription : "");
        gameRef.child("/game/duration").set(nextGameDuration);
        let nextGameData = getNextGameData(nextGameName, gamecode);
        nextGameData.then((resp) => gameRef.child("/game/gameData").set(resp));
        let nextOrder = [];
        do {
          nextOrder = getNextPlayerOrder(game.playerOrder);
        } while (!game.players[nextOrder[0]].isActive);
        gameRef.child("/playerOrder").set(nextOrder);
      }
    }
  });
}

function getNextGame(games, gamecode) {
  let numGames = games.length;
  var count = 0;
  var game = games[Math.floor(Math.random() * numGames)];
  for (
    let i = Math.floor(Math.random() * numGames);
    count < numGames;
    i = (i + 1) % numGames
  ) {
    game = games[i];
    if (!game.picked && game.inGame) {
      firebase
        .database()
        .ref("games/" + gamecode)
        .child("/games/" + i + "/picked")
        .set(true);
      return [game.game, game.emoji, game.duration, game.description];
    }
    count++;
  }
  do {
    game = games[Math.floor(Math.random() * numGames)];
  } while (!game.inGame);
  return [game.game, game.emoji, game.duration, game.description];
}

function getNextPlayerOrder(playerOrder) {
  var arr = playerOrder;
  arr.push(arr.shift());
  return arr;
}

function getNextGameData(game, gamecode) {
  return new Promise((resolve, reject) => {
    switch (game.toLowerCase()) {
      case "trivia":
        getTrivia().then((q) => {
          resolve(q);
        });
        break;
      case "who said the quote?":
        getQuote().then((q) => {
          resolve(q);
        });
        break;
      case "wheel of drunk":
        getWheelOfDrunkData(gamecode).then((d) => {
          resolve(d);
        });
        break;
      case "drink roulette":
        getDrinkRouletteData(gamecode).then((d) => {
          resolve(d);
        });
        break;
      case "photo roulette":
        getPhotoRouletteData(gamecode).then((p) => {
          resolve(p);
        });
        break;
      default:
        resolve({});
    }
  });
}

export function addToPlayerOrder(gamecode, playerUid) {
  let gameRef = firebase.database().ref("/games/" + gamecode);
  gameRef.child("playerOrder").once("value", (snapshot) => {
    if (snapshot.exists()) {
      let order = snapshot.val();
      order.push(playerUid);
      gameRef.child("playerOrder").set(order);
    }
  });
}

export function endGame(gamecode) {
  let gameRef = firebase.database().ref("/games/" + gamecode);
  gameRef.child("isFinished").set(true);
}

export function getTrivia() {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(
        "gameassets/trivia/" +
          Math.floor(Math.random() * parameters.lengthOfTriviaDataset)
      )
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve({ ...snapshot.val(), showAnswer: false });
        }
      });
  });
}

export function getQuote() {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(
        "gameassets/quotes/" +
          Math.floor(Math.random() * parameters.lengthOfQuotesDataset)
      )
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          resolve({ ...snapshot.val(), showAnswer: false });
        }
      });
  });
}

function shuffleArr(array) {
  let currentIndex = array.length,
    randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

export function getWheelOfDrunkData(gamecode) {
  return new Promise((resolve, reject) => {
    getActivePlayers(gamecode).then((activePlayers) => {
      let randActivePlayer =
        activePlayers[
          Object.keys(activePlayers)[
            Math.floor(Math.random() * Object.keys(activePlayers).length)
          ]
        ];
      let wodData = [
        {
          option: randActivePlayer["emoji"],
          style: { backgroundColor: getColor(1) },
        },
      ];
      let wheelOptions = { ...parameters.wheelOfDrunkOptions };
      let shuffledOptions = shuffleArr(Object.keys(wheelOptions));
      for (let i = 1; i < parameters.wheelOfDrunkSize; i++) {
        let emoji = shuffledOptions[i];
        wodData.push({
          option: emoji,
          style: { backgroundColor: getColor(1) },
        });
      }
      let wheelData = {
        answer: Math.floor(Math.random() * parameters.wheelOfDrunkSize),
        data: wodData,
        showAnswer: false,
        finishedSpinning: false,
        chosenPlayerName: randActivePlayer["name"],
      };
      resolve(wheelData);
    });
  });
}

export function getDrinkRouletteData(gamecode) {
  return new Promise((resolve, reject) => {
    getActivePlayers(gamecode).then((activePlayers) => {
      let drData = [
        {
          option: "🚫",
          style: { backgroundColor: rouletteWheelNobodyColor },
        },
      ];
      let length = Object.keys(activePlayers).length;
      let randIdx = Math.floor(Math.random() * length);
      for (let i = 0; i < length; i++) {
        let p = activePlayers[Object.keys(activePlayers)[i]];
        let emoji = p.emoji;
        drData.push({
          option: emoji,
          style: {
            backgroundColor:
              i % 2 === 0 ? rouletteWheelEveryColor : rouletteWheelOtherColor,
          },
        });
      }
      let wheelData = {
        answer: randIdx,
        data: drData,
        showAnswer: false,
        finishedSpinning: false,
        chosenPlayerName:
          randIdx === 0
            ? "Nobody"
            : activePlayers[Object.keys(activePlayers)[randIdx]].name,
      };
      resolve(wheelData);
    });
  });
}

function getColor(alpha) {
  let r = Math.floor(Math.random() * 255);
  let g = Math.floor(Math.random() * 255);
  let b = Math.floor(Math.random() * 255);
  return `rgba(${r},${g},${b},${alpha})`;
}

export function broadcastWheelResult(gamecode, emoji, message) {
  let broadcastData = {
    typecode: 1,
    title: message ? message : "error",
    emoji: emoji ? emoji : "🚫",
  };
  getActivePlayers(gamecode).then((activePlayers) => {
    for (let i = 0; i < Object.keys(activePlayers).length; i++) {
      let playerRef = firebase
        .database()
        .ref("games/" + gamecode + "/players/" + Object.keys(activePlayers)[i]);
      let player = activePlayers[Object.keys(activePlayers)[i]];
      let popups = player.popups;
      popups.push(broadcastData);
      playerRef.child("/popups").set(popups);
    }
  });
}

export function getPhotoRouletteData(gamecode) {
  return new Promise((resolve, reject) => {
    getActivePlayers(gamecode).then((activePlayers) => {
      let submitted = {};
      let urls = {};
      let guesses = {};
      let length = Object.keys(activePlayers).length;
      let randIdx = Math.floor(Math.random() * length);
      var playerNameText = "error";
      var chosenPlayerUid = "";
      for (let i = 0; i < length; i++) {
        let pUid = Object.keys(activePlayers)[i];
        let p = activePlayers[pUid];
        submitted[pUid] = false;
        urls[pUid] = "";
        guesses[pUid] = "";
        if (i === randIdx) {
          playerNameText = p.emoji + " " + p.name;
          chosenPlayerUid = pUid;
        }
      }
      let prData = {
        submitted: submitted,
        photos: urls,
        answer: chosenPlayerUid,
        chosenPlayerName: playerNameText,
        doneUploading: false,
        showAnswer: false,
        guesses: guesses,
      };
      resolve(prData);
    });
  });
}

export function photoRouletteUpload(gamecode, uid, filename, file, metadata) {
  var photoRef = firebase.storage().ref("images/" + gamecode);
  photoRef
    .child("/" + uid + "_" + filename)
    .put(file, metadata)
    .then((snapshot) => {
      let ref = firebase.database().ref("games/" + gamecode + "/game/gameData");
      ref.child("/submitted/" + uid).set(true);
      photoRef
        .child("/" + uid + "_" + filename)
        .getDownloadURL()
        .then((url) => {
          ref.child("/photos/" + uid).set(url);
        })
        .catch((error) => {});
    });
}

export function triggerAsync(gamecode) {
  let gameRef = firebase.database().ref("games/" + gamecode);
  gameRef.child("/players").once("value", (snapshot) => {
    if (snapshot.exists()) {
      let players = snapshot.val();
      gameRef.child("/async").once("value", (snapshot) => {
        if (snapshot.exists()) {
          let asyncs = snapshot.val();
          var randPlayer = {};
          var randPlayerUid = "";
          var asyncPicked = {};
          do {
            asyncPicked = asyncs[Math.floor(Math.random() * asyncs.length)];
          } while (!asyncPicked.active || !asyncPicked.inGame);
          do {
            randPlayerUid =
              Object.keys(players)[
                Math.floor(Math.random() * Object.keys(players).length)
              ];
            randPlayer = players[randPlayerUid];
          } while (!randPlayer.isActive || randPlayer.inTimeout);
          let asyncData = {};
          switch (asyncPicked.emoji) {
            case "❓":
            case "🔥":
            case "🤫":
            case "🥚":
            case "😂":
            case "🤳":
            case "🥃":
              asyncData = {
                typecode: 1,
                title: asyncPicked.async ? asyncPicked.async + " ⏰" : "error",
                emoji: asyncPicked.emoji ? asyncPicked.emoji : "🚫",
                description: asyncPicked.description
                  ? asyncPicked.description
                  : "",
              };
              break;
            case "🏷":
              asyncData = {
                typecode: 6,
                title: asyncPicked.async ? asyncPicked.async + " ⏰" : "error",
                emoji: asyncPicked.emoji ? asyncPicked.emoji : "🚫",
                description: asyncPicked.description
                  ? asyncPicked.description
                  : "",
              };
              break;
            case "💘":
              asyncData = {
                typecode: 5,
                title: asyncPicked.async ? asyncPicked.async + " ⏰" : "error",
                emoji: asyncPicked.emoji ? asyncPicked.emoji : "🚫",
                description: asyncPicked.description
                  ? asyncPicked.description
                  : "",
              };
              break;
            case "✏️":
              asyncData = {
                typecode: 1,
                title: asyncPicked.async ? asyncPicked.async + " ⏰" : "error",
                emoji: asyncPicked.emoji ? asyncPicked.emoji : "🚫",
                description: asyncPicked.description
                  ? asyncPicked.description
                  : "",
              };
              break;
            default:
              asyncData = {
                typecode: 1,
                title: "error",
                emoji: "🚫",
              };
              break;
          }
          let playerName = randPlayer.name;
          let playerEmoji = randPlayer.emoji;
          if (asyncData.description) {
            let newDesc = asyncData.description.replaceAll(
              "{:player:}",
              playerEmoji + " " + playerName
            );
            asyncData["description"] = newDesc;
          }
          for (let i = 0; i < Object.keys(players).length; i++) {
            let pUid = Object.keys(players)[i];
            let playerRef = firebase
              .database()
              .ref("games/" + gamecode + "/players/" + pUid);
            let player = players[pUid];
            if (player.isActive) {
              if (asyncPicked.emoji === "✏️") {
                let changeRuleData = { ...asyncData };
                changeRuleData["typecode"] = 2;
                let popups = player.popups;
                popups.push(
                  pUid === randPlayerUid ? changeRuleData : asyncData
                );
                playerRef.child("/popups").set(popups);
              } else {
                let popups = player.popups;
                popups.push(asyncData);
                playerRef.child("/popups").set(popups);
              }
            }
          }
        }
      });
    }
  });
}
