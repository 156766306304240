import React from "react";
import styled from "styled-components";

import {
  Dialog,
  List,
  Divider,
  ListItemText,
  ListItem,
} from "@material-ui/core";

import CoinUtility from "../Utilities/CoinUtility";
import DiceUtility from "../Utilities/DiceUtility";
import CardUtility from "../Utilities/CardUtility";
import PlayerUtility from "../Utilities/PlayerUtility";
import SubmitTord from "../Utilities/SubmitTord";
import SubmitFeedback from "../Utilities/SubmitFeedback";
import RPSUtility from "../Utilities/RPSUtility";
import EmojiUtility from "../Utilities/EmojiUtility";
import ProfileInfo from "./ProfileInfo";

import { donateLink } from "../../constants/references";

const Container = styled.div`
  cursor: default;
`;

const Emoji = styled.div`
  margin-right: 2.5rem;
`;

class SettingsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      closeDrawer: props.closeDrawer,
      pauseFunc: props.pauseFunc,
      settingsPageFunc: props.settingsPageFunc,
      utilityOpen: false,
      utilityType: "",
    };
    this.handleStepOut = this.handleStepOut.bind(this);
    this.handleUtilityOpen = this.handleUtilityOpen.bind(this);
    this.handleUtilityClose = this.handleUtilityClose.bind(this);
  }
  componentDidMount() {}

  handleProfile = () => {
    this.state.settingsPageFunc("profile");
    this.state.closeDrawer();
  };

  handleStepOut = () => {
    this.state.pauseFunc();
    this.state.closeDrawer();
  };

  handleSettings = () => {
    this.state.closeDrawer();
  };

  handlePlayers = () => {
    this.state.settingsPageFunc("players");
    this.state.closeDrawer();
  };

  handleRooms = () => {
    this.state.settingsPageFunc("rooms");
    this.state.closeDrawer();
  };

  handleLog = () => {
    this.state.closeDrawer();
  };

  handleCoinFlip = () => {
    this.setState({ utilityType: "coin" });
    this.handleUtilityOpen();
  };

  handleRandomPlayer = () => {
    this.setState({ utilityType: "player" });
    this.handleUtilityOpen();
  };
  handlePickCard = () => {
    this.setState({ utilityType: "card" });
    this.handleUtilityOpen();
  };
  handleRollDice = () => {
    this.setState({ utilityType: "dice" });
    this.handleUtilityOpen();
  };
  handleRPS = () => {
    this.setState({ utilityType: "rps" });
    this.handleUtilityOpen();
  };
  handleEmoji = () => {
    this.setState({ utilityType: "key" });
    this.handleUtilityOpen();
  };
  handleSubmitTord = () => {
    this.setState({ utilityType: "submittord" });
    this.handleUtilityOpen();
  };
  handleSubmitFeedback = () => {
    this.setState({ utilityType: "submitfeedback" });
    this.handleUtilityOpen();
  };

  handleDonate = () => {
    // <Route
    //   path="/privacy-policy"
    //   component={() => {
    //     window.location.href = donateLink;
    //     return null;
    //   }}
    // />;
  };

  handleUtilityOpen = () => {
    this.setState({ utilityOpen: true });
  };

  handleUtilityClose = () => {
    this.setState({ utilityOpen: false });
  };

  render() {
    return (
      <Container>
        <Dialog
          //other popups
          onClose={this.handleUtilityClose}
          open={this.state.utilityOpen}
        >
          {this.state.utilityType === "coin" && <CoinUtility />}
          {this.state.utilityType === "dice" && <DiceUtility />}
          {this.state.utilityType === "card" && <CardUtility />}
          {this.state.utilityType === "rps" && <RPSUtility />}
          {this.state.utilityType === "key" && <EmojiUtility />}
          {this.state.utilityType === "player" && (
            <PlayerUtility gamecode={this.state.gamecode} />
          )}
          {this.state.utilityType === "submittord" && (
            <SubmitTord
              gamecode={this.state.gamecode}
              closeFunc={this.handleUtilityClose}
            />
          )}
          {this.state.utilityType === "submitfeedback" && (
            <SubmitFeedback
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              closeFunc={this.handleUtilityClose}
            />
          )}
        </Dialog>
        <ProfileInfo
          gamecode={this.state.gamecode}
          myUid={this.state.myUid}
          // closeFunc={this.handleSettingsPageClose}
        />
        <Divider />
        <List>
          {/* <ListItem button key={"profile"} onClick={this.handleProfile}>
            <Emoji>👤</Emoji>
            <ListItemText>Profile</ListItemText>
          </ListItem> */}
          <ListItem button key={"pause"} onClick={this.handleStepOut}>
            {/* <ListItemIcon>⏸</ListItemIcon> */}
            <Emoji>⏸</Emoji>
            <ListItemText primary={"Step Out"} />
          </ListItem>
          {/* <ListItem
            button
            disabled
            key={"gamesettings"}
            onClick={this.handleSettings}
          >
            <Emoji>⚙️</Emoji>
            <ListItemText primary={"Game Settings"} />
          </ListItem> */}
          <ListItem button key={"players"} onClick={this.handlePlayers}>
            <Emoji>👥</Emoji>
            <ListItemText primary={"Players"} />
          </ListItem>
          {/* <ListItem button key={"rooms"} onClick={this.handleRooms}>
            <Emoji>🚪</Emoji>
            <ListItemText primary={"Rooms"} />
          </ListItem> */}
          {/* <ListItem button disabled key={"log"} onClick={this.handleLog}>
            <Emoji>📒</Emoji>
            <ListItemText primary={"Game Log"} />
          </ListItem> */}
        </List>
        <Divider />

        <List>
          <ListItem alignItems={"center"} key={"utilities"}>
            <ListItemText primary={"Utilities"} />
          </ListItem>
          <ListItem button key={"coin"} onClick={this.handleCoinFlip}>
            <Emoji>❓</Emoji>
            <ListItemText primary={"Flip Coin"} />
          </ListItem>
          <ListItem
            button
            key={"randomplayer"}
            onClick={this.handleRandomPlayer}
          >
            <Emoji>🖐</Emoji>
            <ListItemText primary={"Random Player"} />
          </ListItem>
          <ListItem button key={"card"} onClick={this.handlePickCard}>
            <Emoji>🃏</Emoji>
            <ListItemText primary={"Draw Card"} />
          </ListItem>
          <ListItem button key={"dice"} onClick={this.handleRollDice}>
            <Emoji>🎲</Emoji>
            <ListItemText primary={"Roll Dice"} />
          </ListItem>
          <ListItem button key={"rps"} onClick={this.handleRPS}>
            <Emoji>✂️</Emoji>
            <ListItemText primary={"Rock, Paper, Scissors"} />
          </ListItem>
          <ListItem button key={"key"} onClick={this.handleEmoji}>
            <Emoji>🗝</Emoji>
            <ListItemText primary={"Emoji Lookup"} />
          </ListItem>
        </List>
        <Divider />

        <List>
          <ListItem alignItems={"center"} key={"cnontribute"}>
            <ListItemText primary={"Contribute"} />
          </ListItem>
          <ListItem button key={"submittord"} onClick={this.handleSubmitTord}>
            <Emoji>📨</Emoji>
            <ListItemText primary={"Submit Truth or Dare"} />
          </ListItem>
          <ListItem
            button
            key={"submitfeedback"}
            onClick={this.handleSubmitFeedback}
          >
            <Emoji>📑</Emoji>
            <ListItemText primary={"Submit Feedback"} />
          </ListItem>
          <a
            href={donateLink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black", textDecoration: "none" }}
          >
            <ListItem button key={"donate"}>
              <Emoji>🍺</Emoji>
              <ListItemText primary={"Buy Me A Beer"} />
            </ListItem>
          </a>
        </List>
      </Container>
    );
  }
}

export default SettingsList;
