import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import {
  List,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  ListItem,
  Toolbar,
  Typography,
} from "@material-ui/core";

import {
  kick,
  votekick,
  ban,
  changeGM,
  getEmojiList,
  setPartner,
  removePartner,
} from "../../utilities/utils.js";

const StyledEmoji = styled.span`
  font-size: 2rem;
  margin-right: 1rem;
`;

const PlayerName = styled.span`
  font-family: Comic Neue;
`;

class SettingsPagePlayers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      players: {},
      playerOptions: false,
      myEmoji: props.myEmoji,
      closeFunc: props.closeFunc,
      im18: props.im18,
      isGM: props.isGM,
      anchorEl: null,
      selectedPlayerUid: "",
      selectedPlayer: {},
      selectedPlayerPartner: {},
      imTaken: props.status === 2,
      selectRecipient: props.selectRecipient,
      handleSendDmOpen: props.handleSendDmOpen,
      advanced: props.advanced,
    };
    this.handleClose = this.handleClose.bind(this);
    this.getPlayers = this.getPlayers.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePlayerOptionsOpen = this.handlePlayerOptionsOpen.bind(this);
    this.handlePlayerOptionsClose = this.handlePlayerOptionsClose.bind(this);
    this.handleSetPartner = this.handleSetPartner.bind(this);
    this.handleRemovePartner = this.handleRemovePartner.bind(this);
    this.handleVotekick = this.handleVotekick.bind(this);
  }

  componentDidMount() {
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/players")
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          let players = snapshot.val();
          this.setState({ players: players });
        }
      });
  }

  handleClose() {
    this.state.closeFunc();
  }

  handleClick(e, playerUid, player) {
    this.setState({
      anchorEl: e.currentTarget,
      selectedPlayerUid: playerUid,
      selectedPlayer: player,
      selectedPlayerPartner: player.partner,
    });

    this.handlePlayerOptionsOpen();
  }

  getPlayers() {
    let playerData = [];
    Object.entries(this.state.players).forEach(([key, player]) => {
      if (key !== this.state.myUid) {
        let nameText = "" + player.name;
        let emojiText = player ? getEmojiList(player, this.state.im18) : {};
        playerData.push(
          <>
            <ListItem
              button
              disabled={!player.isActive}
              key={key}
              onClick={(e) => this.handleClick(e, key, player)}
            >
              <StyledEmoji>{player.emoji}</StyledEmoji>
              <ListItemText
                primary={
                  <PlayerName>
                    <b>{nameText}</b>
                  </PlayerName>
                }
                secondary={emojiText}
              />
            </ListItem>
            <Divider />
          </>
        );
      }
    });
    if (playerData.length === 0) {
      return (
        <ListItem key="empty">
          <ListItemText primary={"No Players Found"} />
        </ListItem>
      );
    }
    return playerData;
  }

  handlePlayerOptionsOpen = () => {
    this.setState({ playerOptions: true });
  };

  handlePlayerOptionsClose = () => {
    this.setState({ playerOptions: false, anchorEl: null });
  };

  handleSendMessage = () => {
    this.state.selectRecipient(
      this.state.selectedPlayerUid,
      this.state.selectedPlayer.name,
      this.state.selectedPlayer.emoji
    );
    this.handlePlayerOptionsClose();
    this.handleClose();
    this.state.handleSendDmOpen();
  };

  handleChangeGM = () => {
    changeGM(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid
    );
    this.handlePlayerOptionsClose();
    this.handleClose();
  };

  handleVotekick = () => {
    votekick(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid
    );
    this.handleClose();
  };

  handleKick = () => {
    kick(this.state.gamecode, this.state.selectedPlayerUid);
    this.handlePlayerOptionsClose();
    // this.handleClose();
  };

  handleBan = () => {
    ban(this.state.gamecode, this.state.selectedPlayerUid);
    this.handlePlayerOptionsClose();
    // this.handleClose();
  };

  handleSetPartner = () => {
    setPartner(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid,
      this.state.myEmoji,
      this.state.selectedPlayer.emoji
    );
    this.handlePlayerOptionsClose();
  };

  handleRemovePartner = () => {
    removePartner(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid
    );
    this.handlePlayerOptionsClose();
  };

  render() {
    return (
      <div
        style={{
          cursor: "default",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.playerOptions}
          onClose={this.handlePlayerOptionsClose}
          PaperProps={{
            style: {
              maxHeight: "50rem",
              width: "15rem",
              marginLeft: "5%",
            },
          }}
        >
          <div>
            {" "}
            <div style={{ textAlign: "center" }}>
              <span style={{ fontSize: "2.5rem" }}>
                {this.state.selectedPlayer.emoji}
              </span>{" "}
              <br />
              {this.state.selectedPlayer.name}
              <br />
              {Object.keys(this.state.selectedPlayer).length !== 0 &&
                getEmojiList(this.state.selectedPlayer, this.state.im18)}
              <br />
            </div>
            <br />
            {this.state.isGM && (
              <div>
                <MenuItem onClick={this.handleChangeGM}>
                  Make Gamemaster
                </MenuItem>
                <MenuItem onClick={this.handleKick}>Kick</MenuItem>
                <MenuItem onClick={this.handleBan}>Ban</MenuItem>
                {this.state.advanced[7] && (
                  <MenuItem onClick={this.handleSendMessage}>Message</MenuItem>
                )}
                <MenuItem onClick={this.handleVotekick}>Votekick</MenuItem>
                {this.state.selectedPlayer.is18 &&
                  this.state.im18 &&
                  this.state.imTaken &&
                  this.state.selectedPlayer.showStatus &&
                  this.state.selectedPlayer.status === 2 &&
                  !(
                    this.state.selectedPlayerPartner.partnerUid ===
                      this.state.myUid &&
                    this.state.selectedPlayerPartner.mutual
                  ) && (
                    <MenuItem onClick={this.handleSetPartner}>
                      Set as Partner ♥︎
                    </MenuItem>
                  )}
                {this.state.selectedPlayerPartner.partnerUid ===
                  this.state.myUid &&
                  this.state.selectedPlayerPartner.mutual && (
                    <MenuItem onClick={this.handleRemovePartner}>
                      Remove as Partner
                    </MenuItem>
                  )}
              </div>
            )}
            {!this.state.isGM && (
              <div>
                {this.state.advanced[7] && (
                  <MenuItem onClick={this.handleSendMessage}>Message</MenuItem>
                )}
                <MenuItem onClick={this.handleVotekick}>Votekick</MenuItem>
                {this.state.selectedPlayer.is18 &&
                  this.state.im18 &&
                  this.state.imTaken &&
                  this.state.selectedPlayer.showStatus &&
                  this.state.selectedPlayer.status === 2 &&
                  !(
                    this.state.selectedPlayerPartner.partnerUid ===
                      this.state.myUid &&
                    this.state.selectedPlayerPartner.mutual
                  ) && (
                    <MenuItem onClick={this.handleSetPartner}>
                      Set as Partner ♥︎
                    </MenuItem>
                  )}
                {this.state.selectedPlayerPartner.partnerUid ===
                  this.state.myUid &&
                  this.state.selectedPlayerPartner.mutual && (
                    <MenuItem onClick={this.handleRemovePartner}>
                      Remove as Partner
                    </MenuItem>
                  )}
              </div>
            )}
          </div>
        </Menu>
        <Toolbar>
          {/* <IconButton
            edge="start"
            color="inherit"
            onClick={this.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton> */}
          <div
            style={{
              width: "90%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Typography
              style={{
                position: "absolute",
                fontFamily: "Comic Neue",
                marginTop: "-0.9rem",
              }}
              variant="h5"
            >
              👥 <b>Players In Game</b>
            </Typography>
          </div>
        </Toolbar>
        <Divider />
        <br />
        <List>{this.getPlayers()}</List>
      </div>
    );
  }
}

export default SettingsPagePlayers;
