import React from "react";
import styled from "styled-components";

import {
  List,
  Divider,
  ListItemText,
  ListItem,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Toolbar,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const StyledEmoji = styled.span`
  font-size: 2rem;
  margin-right: 1rem;
`;

const RoomName = styled.span`
  font-family: Comic Neue;
`;

class SettingsPageRooms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      closeFunc: props.closeFunc,
      rooms: props.rooms,
      mainRoom: props.mainRoom,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.state.closeFunc();
  }

  getRooms() {
    let roomsList = [this.state.mainRoom, ...this.state.rooms];
    let roomsData = [];
    for (let i = 0; i < roomsList.length; i++) {
      roomsData.push(
        <ListItem key={roomsList[i]}>
          <Accordion style={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <StyledEmoji>{"🟢"}</StyledEmoji>
              <ListItemText
                primary={
                  <RoomName>
                    <b>{roomsList[i]}</b>
                  </RoomName>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography>Vacant</Typography>
            </AccordionDetails>
          </Accordion>
        </ListItem>
      );
    }
    return roomsData;
  }

  render() {
    return (
      <div style={{ alignItems: "center", justifyContent: "center" }}>
        <Toolbar>
          <div
            style={{
              width: "90%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Typography
              style={{
                position: "absolute",
                fontFamily: "Comic Neue",
                marginTop: "-0.9rem",
              }}
              variant="h5"
            >
              🚪 <b>Rooms</b>
            </Typography>
          </div>
        </Toolbar>
        <Divider />
        <br />
        <List>{this.getRooms()}</List>
      </div>
    );
  }
}

export default SettingsPageRooms;
