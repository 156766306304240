import React from "react";
import { useHistory } from "react-router-dom";

const GamesNotFound = () => {
  const history = useHistory();

  const returnHome = () => {
    history.push("/");
  };

  return (
    <>
      <br />
      <br />
      <br />
      <div>
        <b>404</b> Games Not Found
      </div>
      <br />
      <button onClick={returnHome}>Home</button>
    </>
  );
};

export default GamesNotFound;
