import React from "react";
import styled from "styled-components";

import { Button, Dialog } from "@material-ui/core";

import RPSUtility from "../Utilities/RPSUtility";

import dimensions from "../../constants/dimensions";
import { ButtonColor } from "../../constants/colors";

const Container = styled.div`
  width: 100%;
  //   background-color: blue;
  //   position: fixed;
`;

const Emoji = styled.span`
  font-size: 8rem;
`;

const Content = styled.div`
  // margin: 2rem;
  margin-top: 4rem;
  // background-color: red;
  line-height: 1.3;
  text-align: center;
`;

class RPSGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      isGM: props.isGM,
      emoji: props.emoji,
      message: props.message,
      utilityOpen: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleUtilityOpen = this.handleUtilityOpen.bind(this);
    this.handleUtilityClose = this.handleUtilityClose.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleUtilityOpen = () => {
    this.setState({ utilityOpen: true });
  };

  handleUtilityClose = () => {
    this.setState({ utilityOpen: false });
  };

  render() {
    const Game = styled.div`
      width: ${this.state.width > 650 ? `75%` : `90%`};
      height: calc(6.5 * ${dimensions.lineHeight});
      overflow: scroll;
      margin-left: ${this.state.width > 650 ? `8rem` : `5%`};
      margin-top: 1rem;
      ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      cursor: default;
    `;

    return (
      <>
        <Dialog onClose={this.handleUtilityClose} open={this.state.utilityOpen}>
          <RPSUtility />
        </Dialog>
        <Container>
          <Game>
            <Content>
              <Emoji>{this.state.emoji}</Emoji>
            </Content>
            <Button
              style={{ backgroundColor: ButtonColor }}
              variant="outlined"
              onClick={this.handleUtilityOpen}
            >
              Shoot
            </Button>
          </Game>
        </Container>
      </>
    );
  }
}

export default RPSGame;
