import React from "react";
import styled from "styled-components";
import firebase from "../firebase";
import { Redirect, withRouter } from "react-router-dom";

import Cookies from "universal-cookie";

import { sleep, shuffleString } from "../utilities/utils.js";

import {
  defaultRounds,
  gameCodeLength,
  gameCodeChars,
  playerEmojis,
} from "../constants/parameters";
import {
  redLineMargin,
  lineHeight,
  redLineWidth,
} from "../constants/dimensions";

const Container = styled.div`
  position: absolute;
`;

const HeaderContainer = styled.div`
  position: relative;
  width: 100vw;
  height: calc(2 * ${lineHeight});
  //   background-color: yellow;
  display: flex;
`;

const HeaderLeft = styled.div`
  width: ${redLineMargin};
  height: calc(2 * ${lineHeight});
  // background-color: blue;
`;

const HeaderRight = styled.div`
  height: calc(2 * ${lineHeight});
  width: calc(100vw - ${redLineMargin});
  margin-left: ${redLineWidth};
  // background-color: yellow;
`;

const PageContent = styled.div`
  width: 100%;
  height: calc(10.5 * ${lineHeight});
  display: flex;
  justify-content: center;
  // background-color: pink;
`;

const HostAndJoin = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: calc(2.2 * ${lineHeight}); //was 1.3 * dim
  margin-bottom: 20vh;
  // background-color: green;
`;

const HostOrJoin = styled.div`
  width: 80%;
  flex: auto;
  margin-left: 0vh;
  font-size: 3vh;
  font-weight: bolder;
  cursor: pointer;
  // background-color: red;
`;

const HostEmoji = styled.div`
  margin-top: 3vh;
  font-size: 15vh;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
`;

const JoinEmoji = styled.div`
  font-size: 18vh;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: scale(1.2);
    transform-origin: bottom;
  }
`;

const JoinCodeField = styled.input`
  background-color: transparent;
  outline: 0;
  border: hidden;
  border-left: solid green 0px;
  border-right: solid green 0px;
  border-bottom: solid #aaaaaa 1.5px;
  border-top: solid green 0px;
  font-size: 3vh;
  font-family: Comic Neue;
  text-transform: uppercase;
  color: black;
  ::placeholder {
    color: grey;
    font-size: 0.75em;
    font-family: Comic Neue;
    text-transform: capitalize;
  }
`;

class PageHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      gamecode: "",
      joincode: "",
      width: 0,
      height: 0,
      hostName: "",
    };
    this.handleHost = this.handleHost.bind(this);
    this.handleJoin = this.handleJoin.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.joinGame = this.joinGame.bind(this);
    this.createGame = this.createGame.bind(this);
  }

  createGame() {
    const gameCode = this.genGameCode();
    const gameRef = firebase.database().ref("games/" + gameCode);
    this.handleGamecodeChange(gameCode);
    const firstLog = "Game " + gameCode + " created.";
    const game = {
      gamecode: gameCode,
      inLobby: false,
      hasStarted: false,
      isFinished: false,
      rounds: defaultRounds,
      round: 0,
      game: { game: "", gameEmoji: "", gameData: {}, gameDescription: "" },
      teleprompter: "Waiting for Games to Start...",
      players: [],
      bannedIPs: ["255.255.255.255"],
      log: [firstLog],
      owner: null,
      rule: "",
      suspendGMTimout: false,
      playerEmojis: shuffleString(playerEmojis),
      playerOrder: [],
    };
    gameRef.set(game);

    sleep(500).then((r) => {
      this.setState({ redirect: "/" + this.state.gamecode });
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleHost() {
    const cookies = new Cookies();
    cookies.set("didHost", true, { path: "/" });
    this.createGame();
  }

  joinGame() {
    if (this.state.joincode) {
      this.setState({ redirect: "/" + this.state.joincode });
    }
  }

  handleJoin() {
    this.joinGame();
  }

  handleChangeJoincode(e) {
    this.setState({ joincode: e.target.value });
  }

  handleChangeHostName(e) {
    this.setState({ hostName: e.target.value });
  }

  handleGamecodeChange(newCode) {
    this.setState({ gamecode: newCode });
  }

  genGameCode() {
    var code = "";
    var characters = gameCodeChars;
    for (var i = 0; i < gameCodeLength; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  }

  render() {
    const Title = styled.div`
      font-size: 5vh;
      margin-left: ${this.state.width > 650 ? `6rem` : `0rem`};
      position: absolute;
      bottom: 0;
      white-space: nowrap;
      text-decoration: underline;
      text-underline-offset: 0.4vh;
      padding: 2vh;
    `;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Container>
        <HeaderContainer>
          <HeaderLeft />
          <HeaderRight />
          <Title>The Drinking Games</Title>
        </HeaderContainer>
        <PageContent>
          <HostAndJoin>
            <HostOrJoin onClick={this.handleJoin}>
              <JoinEmoji>🍻</JoinEmoji>
            </HostOrJoin>
            <HostOrJoin onClick={this.handleHost}>
              <HostEmoji>🍺</HostEmoji>
            </HostOrJoin>
          </HostAndJoin>
          <form
            onSubmit={this.handleJoin}
            style={{
              position: "absolute",
              marginTop: "11vh",
              marginLeft: "-1.5rem",
            }}
          >
            <JoinCodeField
              // autoFocus
              onChange={(e) => this.handleChangeJoincode(e)}
              id="joincode"
              autoComplete="off"
              spellCheck="false"
              placeholder="Enter Game Code"
              onFocus={(e) => (e.target.placeholder = "")}
              onBlur={(e) => (e.target.placeholder = "Enter Game Code")}
              value={this.state.joinCode}
              maxLength={5}
              style={{
                border: "none",
                textAlign: "center",
              }}
            />
          </form>
          <HostAndJoin style={{ marginTop: "40vh" }}>
            <HostOrJoin onClick={this.handleJoin}>
              <br />
              Join
            </HostOrJoin>
            <HostOrJoin onClick={this.handleHost}>
              <br />
              Host
            </HostOrJoin>
          </HostAndJoin>
        </PageContent>
      </Container>
    );
  }
}

export default withRouter(PageHome);
