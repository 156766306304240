import React from "react";

import Loader from "react-loader-spinner";

const Loading = (props) => {
  const [loading] = React.useState(props.loading);
  return (
    loading && (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <Loader type="Bars" color="#000000" height="100" width="100" />
      </div>
    )
  );
};

export default Loading;
