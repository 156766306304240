import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Result = styled.span`
  font-size: 8rem;
`;

class RPSUtility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: Math.floor(Math.random() * 3) + 1,
    };
  }

  render() {
    return (
      <Container>
        <Title>
          <b>You picked</b>
        </Title>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Result>
            {this.state.result === 1
              ? "📄"
              : this.state.result === 2
              ? "✂️"
              : "🤜"}
          </Result>
        </div>
      </Container>
    );
  }
}

export default RPSUtility;
