module.exports = {
  gameCodeLength: 5,
  defaultRounds: 25,
  maxRounds: 100,
  gameCodeChars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  playerEmojis:
    "🐶🐹🐻🐯🐷🐵🐧🐣🐺🦄🐜🐢🦖🦑🦀🐟🐋🐓🦜🦨🦥🐿🐁🦔🦢🦃🦧🐬🦈🐡🦐🦕🐍🦟🐌🐝🐗🦉🐥🐦🦁🐼🐰🐱🐭🦊🐨🐮🐸🐔🐤🦆🦇🐴🐛🐙🦞🐠🐳🐈🦚",
  maxPenalties: 10,
  defaultStartingPenalties: 3,
  minPenalties: 0,
  maxNameLength: 20,
  defaultHeatLevel: 1,
  randomDareSecondsMin: 5,
  randomDareSecondsMax: 40,
  randomDareRoundsMin: 2,
  randomDareRoundsMax: 4,
  highestDareLevelWithoutCompatibility: 4,
  maxBroadcastLength: 200,
  maxTeleprompterLength: 200,
  defaultStartingBroadcastPercentage: 2,
  maxTordSubmissionLength: 150,
  maxFeedbackSubmissionLength: 400,
  lengthOfTriviaDataset: 191266,
  lengthOfQuotesDataset: 5213,
  censorTextWord: "_____",
  wheelOfDrunkSize: 3,
  wheelOfDrunkOptions: {
    "👶": "Youngest Player Drinks",
    "👴": "Oldest Player Drinks",
    "🚹": "Guys Drink",
    "🚺": "Girls Drink",
    "📅": "Whoever's Birthday is Closest to July 17th, Drink",
    "🔑": "Gamemaster Drinks",
    "⌛️": "Last Player That Joined Drinks",
    "🗓": "Drink if Your Birthday is This Month",
    "🗳": "Decide Together Who Must Drink",
    "👢": "Tallest Player Drinks",
    "🥿": "Shortest Player Drinks",
    "🩲": "Anyone Wearing Black Underwear, Drink",
    "🦷": "If You've Had Braces, Drink",
    "🔋": "Whoever Has the Most Battery Percentage, Drink",
    "💈": "Whoever's Been the Furthest North, Drink",
    "🧭": "Whoever's Been the Furthest South, Drink",
    "🥧": "Whoever Knows the Most Digits of Pi, Drink",
    "🥛": "Whoever's Drink is the Fullest, Drink",
    "💲": "If You Pay Rent Here, Drink",
  },
};
