import React from "react";
import styled from "styled-components";

import SaidQuoteGame from "./Games/SaidQuoteGame";
import TriviaGame from "./Games/TriviaGame";
import SimpleGame from "./Games/SimpleGame";
import PhotoRouletteGame from "./Games/oldPhotoRouletteGame";
import RPSGame from "./Games/RPSGame";
import WheelOfDrunkGame from "./Games/WheelOfDrunkGame";
import DrinkRouletteGame from "./Games/DrinkRouletteGame";
import Lobby from "./Lobby";

import { lineHeight, redLineMargin } from "../constants/dimensions";

const Container = styled.div`
  height: calc(7 * ${lineHeight});
  font-size: 2vh;
  // background-color: pink;
  // &:hover {
  //   background-color: grey;
  // }
  line-height: ${lineHeight};
`;

const GameContainer = styled.div`
  margin-top: calc(${lineHeight} * -1);
  z-index: 100;
  position: absolute;
  width: 100%;
  height: calc(7 * ${lineHeight});
  // background-color: red;
  // margin-left: ${redLineMargin};
`;

class GameSpace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      hasStarted: props.hasStarted,
      quote: "",
      author: "",
      work: "",

      myEmoji: props.myEmoji,
      myName: props.myName,
      closeFunc: props.closeFunc,
      im18: props.is18,
      isGM: props.isGM,
      status: props.status,
      selectRecipient: props.selectRecipient,
      handleSendDmOpen: props.handleSendDmOpen,
      advanced: props.advanced,

      game: props.game,
      gameEmoji: props.gameEmoji,
      gameDescription: props.gameDescription,
      gameData: props.gameData,

      currPlayerEmoji: props.currPlayerEmoji,
      currPlayerName: props.currPlayerName,
    };
  }

  render() {
    return (
      <Container>
        <br />
        <GameContainer>
          {this.state.game &&
            this.state.game.toLowerCase() === "who said the quote?" && (
              <SaidQuoteGame
                emoji={this.state.gameEmoji}
                data={this.state.gameData}
                gamecode={this.state.gamecode}
                isGM={this.state.isGM}
              />
            )}
          {this.state.game && this.state.game.toLowerCase() === "trivia" && (
            <TriviaGame
              emoji={this.state.gameEmoji}
              data={this.state.gameData}
              gamecode={this.state.gamecode}
              isGM={this.state.isGM}
            />
          )}
          {this.state.game &&
            this.state.game.toLowerCase() === "wheel of drunk" && (
              <WheelOfDrunkGame
                gamecode={this.state.gamecode}
                wheel={this.state.gameData}
                data={this.state.gameData ? this.state.gameData.data : null}
                done={
                  this.state.gameData
                    ? this.state.gameData.finishedSpinning
                    : true
                }
                isGM={this.state.isGM}
              />
            )}
          {/* {console.log("here1", this.state.gameData)} */}
          {this.state.game &&
            this.state.game.toLowerCase() === "drink roulette" && (
              <DrinkRouletteGame
                gamecode={this.state.gamecode}
                wheel={this.state.gameData}
                data={this.state.gameData ? this.state.gameData.data : null}
                done={
                  this.state.gameData
                    ? this.state.gameData.finishedSpinning
                    : true
                }
                isGM={this.state.isGM}
              />
            )}
          {this.state.game &&
            (this.state.game.toLowerCase() === "drive" ||
              this.state.game.toLowerCase() === "rhyme" ||
              this.state.game.toLowerCase() === "categories" ||
              this.state.game.toLowerCase() === "never have i ever") && (
              <SimpleGame
                emoji={this.state.gameEmoji}
                message={
                  this.state.currPlayerEmoji +
                  " " +
                  this.state.currPlayerName +
                  " Starts"
                }
                description={this.state.gameDescription}
                currPlayerName={this.state.currPlayerName}
              />
            )}
          {this.state.game &&
            this.state.game.toLowerCase() === "two truths and a lie" && (
              <SimpleGame
                emoji={this.state.gameEmoji}
                message={
                  this.state.currPlayerEmoji +
                  " " +
                  this.state.currPlayerName +
                  " is up!"
                }
                description={this.state.gameDescription}
                currPlayerName={this.state.currPlayerName}
              />
            )}

          {this.state.game &&
            (this.state.game.toLowerCase() === "beer pong" ||
              this.state.game.toLowerCase() === "flip cup" ||
              this.state.game.toLowerCase() === "stack cup" ||
              this.state.game.toLowerCase() === "beer die" ||
              this.state.game.toLowerCase() === "ride the bus" ||
              this.state.game.toLowerCase() === "blackjack" ||
              this.state.game.toLowerCase() === "spin the bottle") && (
              <SimpleGame
                emoji={this.state.gameEmoji}
                description={this.state.gameDescription}
                currPlayerName={this.state.currPlayerName}
              />
            )}
          {this.state.game.toLowerCase() === "hot seat" && (
            <SimpleGame
              emoji={this.state.gameEmoji}
              message={
                this.state.currPlayerEmoji +
                " " +
                this.state.currPlayerName +
                " is in the HOT SEAT"
              }
              description={this.state.gameDescription}
              currPlayerName={this.state.currPlayerName}
            />
          )}
          {this.state.game.toLowerCase() === "rock paper scissors war" && (
            <RPSGame emoji={this.state.gameEmoji} />
          )}
          {this.state.game.toLowerCase() === "photo roulette" && (
            <PhotoRouletteGame
              gamecode={this.state.gamecode}
              isGM={this.state.isGM}
              emoji={this.state.gameEmoji}
              myUid={this.state.myUid}
              data={this.state.gameData ? this.state.gameData : null}
            />
          )}
        </GameContainer>
        {this.state.hasStarted === false && (
          <Lobby
            gamecode={this.state.gamecode}
            myUid={this.state.myUid}
            myEmoji={this.state.myEmoji}
            closeFunc={this.state.closeFunc}
            im18={this.state.im18}
            isGM={this.state.isGM}
            imTaken={this.state.status}
            selectRecipient={this.state.selectRecipient}
            handleSendDmOpen={this.state.handleSendDmOpen}
            advanced={this.state.advanced}
          />
        )}
      </Container>
    );
  }
}

export default GameSpace;
