import React from "react";
import styled from "styled-components";

import Button from "@material-ui/core/Button";

import { endGame } from "../utilities/gameUtilities";

import { endGamesButtonColor } from "../constants/colors";

const Container = styled.div`
  margin: 2rem;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

class EndGamesPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      closeFunc: props.closeFunc,
    };
    this.handleEndGames = this.handleEndGames.bind(this);
  }

  handleEndGames = () => {
    endGame(this.state.gamecode);
  };

  render() {
    return (
      <Container>
        <Title>
          <b>Are You Sure?</b> <Emoji> 🛑</Emoji>
        </Title>
        <br />
        <Button
          style={{ backgroundColor: endGamesButtonColor, width: "100%" }}
          size="large"
          onClick={this.handleEndGames}
        >
          END GAMES
        </Button>
      </Container>
    );
  }
}

export default EndGamesPopup;
