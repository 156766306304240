import React from "react";
import styled from "styled-components";
import firebase from "../firebase";

import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Button, TextField, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import { setTeleprompter, setRule } from "../utilities/utils";

import { maxTeleprompterLength } from "../constants/parameters";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const StyledEmoji = styled.span`
  font-size: 6rem;
  text-align: center;
`;

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const Container = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

class DialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      emoji: props.emoji,
      description: props.description,
      typecode: props.typecode,
      gamecode: props.gamecode,
      myUid: props.myUid,
      ratingVal: 2.5,
      textfieldContent: "",
      closeFunc: props.closeCallback,
      ratingFunc: props.ratingCallback,
      time: 0,
      timerSecs: 0,
      timerDescription: "",
    };

    this.handleSubmitRating = this.handleSubmitRating.bind(this);
    this.handleSubmitTextfield = this.handleSubmitTextfield.bind(this);
    this.handleClickedRejoin = this.handleClickedRejoin.bind(this);
    this.timer = 0;
    this.fillInTimer = this.fillInTimer.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount() {
    if (this.state.typecode === 6) this.fillInTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleClose() {}

  handleClickedRejoin() {
    firebase
      .database()
      .ref(
        "/games/" +
          this.state.gamecode +
          "/players/" +
          this.state.myUid +
          "/isActive"
      )
      .set(true);
    firebase
      .database()
      .ref(
        "/games/" +
          this.state.gamecode +
          "/players/" +
          this.state.myUid +
          "/isPaused"
      )
      .set(false);
    this.state.closeFunc();
  }

  handleTextFieldChange(e) {
    if (e.target.value.length <= maxTeleprompterLength)
      this.setState({ textfieldContent: e.target.value });
  }

  handleSubmitTextfield() {
    setTeleprompter(this.state.gamecode, this.state.textfieldContent);
    setRule(this.state.gamecode, this.state.textfieldContent);
    this.state.closeFunc();
  }

  handleRatingChange(e) {
    this.setState({ ratingVal: e.target.value });
  }

  handleSubmitRating() {
    this.state.ratingFunc(this.state.ratingVal);
    this.state.closeFunc();
  }

  fillInTimer() {
    let timerTag = "{:timer:}";
    let description = this.state.description;
    let words = description.split(" ");
    let genericDescription = "";
    let secs = 0;
    for (let i = 0; i < words.length; i++) {
      if (words[i].includes(timerTag)) {
        secs = parseInt(words[i].substring(timerTag.length));
        genericDescription += timerTag;
      } else {
        genericDescription += words[i];
      }
      if (i !== words.length - 1) genericDescription += " ";
    }

    let newDescription = genericDescription.replaceAll(timerTag, secs);

    this.setState({
      timerDescription: genericDescription,
      timerSecs: secs,
      time: secs,
      description: newDescription,
    });

    this.startTimer();
  }

  startTimer() {
    this.timer = setInterval(this.countDown, 1000);
  }

  countDown() {
    console.log("here");
    let seconds = this.state.time - 1;
    let newDescription = this.state.timerDescription.replaceAll(
      "{:timer:}",
      seconds
    );
    this.setState({
      time: seconds,
      description: newDescription,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <Container style={{ textAlign: "center" }}>
        {this.state.typecode === 1 && (
          <>
            <DialogTitle
              style={{ textAlign: "center" }}
              onClose={this.handleClose}
            >
              {this.state.title}
            </DialogTitle>
            <StyledEmoji>{this.state.emoji}</StyledEmoji>
            <Typography
              variant="h6"
              style={{ fontFamily: "Comic Neue" }}
              gutterBottom
            >
              {this.state.description}
            </Typography>
          </>
        )}
        {this.state.typecode === 2 && (
          <>
            <DialogTitle
              style={{ textAlign: "center" }}
              onClose={this.handleClose}
            >
              {this.state.title}
            </DialogTitle>
            <StyledEmoji>{this.state.emoji}</StyledEmoji>
            <Typography
              variant="h6"
              style={{ fontFamily: "Comic Neue" }}
              gutterBottom
            >
              {this.state.description}
            </Typography>

            <TextField
              onChange={(e) => this.handleTextFieldChange(e)}
              label="New Rule"
              value={this.state.textfieldContent}
            />
            <br />
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleSubmitTextfield}
            >
              SUBMIT
            </Button>
          </>
        )}
        {this.state.typecode === 3 && (
          <>
            <StyledEmoji>{this.state.emoji}</StyledEmoji>
            <Typography
              variant="h6"
              style={{ fontFamily: "Comic Neue" }}
              gutterBottom
            >
              {this.state.description}
            </Typography>
            <br />
            <Rating
              onChange={(e) => this.handleRatingChange(e)}
              name="half-rating"
              defaultValue={2.5}
              precision={0.5}
            />
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleSubmitRating}
            >
              SUBMIT
            </Button>
          </>
        )}
        {this.state.typecode === 4 && (
          <>
            <StyledEmoji>{this.state.emoji}</StyledEmoji>
            <Typography
              variant="h6"
              style={{ fontFamily: "Comic Neue" }}
              gutterBottom
            >
              {this.state.description}
            </Typography>
            <br />
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClickedRejoin}
            >
              REJOIN
            </Button>
          </>
        )}
        {this.state.typecode === 5 && <></>}
        {this.state.typecode === 6 && (
          <>
            <DialogTitle
              style={{ textAlign: "center" }}
              onClose={this.handleClose}
            >
              {this.state.title}
            </DialogTitle>
            <StyledEmoji>{this.state.emoji}</StyledEmoji>
            <Typography
              variant="h6"
              style={{ fontFamily: "Comic Neue" }}
              gutterBottom
            >
              {this.state.description}
            </Typography>
            {console.log(this.state)}
          </>
        )}
      </Container>
    );
  }
}

// typecodes
// 1: title? > emoji? > description?
// 2: title? > emoji? > description? > Textfield > SUBMIT > button
// 3: title? > emoji? > description? > Rating > SUBMIT button
// 4: title? > emoji? > description > REJOIN button
// 5: title? > emoji? > description > Player Selector (Active not me) > SUBMIT button
// 6: 1 but description includes timer

export default DialogContent;
