import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCV8hpFBaOmiO-tQW51BWo4LBgoynNmYxY",
  authDomain: "the-drinking-games-bf4e5.firebaseapp.com",
  databaseURL: "https://the-drinking-games-bf4e5-default-rtdb.firebaseio.com",
  projectId: "the-drinking-games-bf4e5",
  storageBucket: "the-drinking-games-bf4e5.appspot.com",
  messagingSenderId: "14928730107",
  appId: "1:14928730107:web:21203ca7e0a507a1eb5d5e",
  measurementId: "G-JKX57QP4NS",
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
