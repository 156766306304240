import React from "react";
import styled from "styled-components";
import firebase from "../firebase";

import { Menu, MenuItem } from "@material-ui/core";

import {
  kick,
  ban,
  changeGM,
  getEmojiList,
  setPartner,
  removePartner,
} from "../utilities/utils.js";

import dimensions from "../constants/dimensions.js";

const Container = styled.div`
  margin-top: calc(
    calc(${dimensions.lineHeight} * -1.5) +
      calc(2.5 * ${dimensions.blueLineHeight})
  );
  margin-left: calc(${dimensions.redLineMargin} + 0.5rem);
  text-align: left;
  overflow: scroll;
  //   background-color: purple;
  height: calc(${dimensions.lineHeight} * 7.5);
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`;

const FaderBottom = styled.div`
  position: absolute;
  margin-top: calc(
    calc(-0.5 * ${dimensions.lineHeight}) + ${dimensions.blueLineHeight}
  );
  margin-left: calc(${dimensions.redLineMargin} + 0.5rem);
  height: calc(${dimensions.lineHeight} / 2);
  background: linear-gradient(
    to top,
    rgba(251, 251, 251, 1) 30%,
    rgba(251, 251, 251, 0) 100%
  );
  //   background-color: red;
  width: calc(100vw - calc(${dimensions.redLineMargin} + 0.5rem));
`;
const FaderTop = styled.div`
  position: absolute;
  margin-left: calc(${dimensions.redLineMargin} + 0.5rem);
  margin-top: calc(
    calc(${dimensions.lineHeight} * -7.5) - ${dimensions.blueLineHeight}
  );
  height: calc(${dimensions.lineHeight} / 1.8);
  background: linear-gradient(
    to bottom,
    rgba(251, 251, 251, 1) 0%,
    rgba(251, 251, 251, 0) 100%
  );
  //   background-color: red;
  width: calc(100vw - calc(${dimensions.redLineMargin} + 0.5rem));
`;

const Players = styled.div`
  text-align: left;
  padding-left: 0.5rem;
  //   background-color: orange;
`;

const PlayerTextWaiting = styled.span`
  margin-right: 1rem;
  font-size: 2vh;
  color: #cccccc;
  display: inline-block;
  white-space: nowrap;
`;

const PlayerTextReady = styled.span`
  margin-right: 1rem;
  font-size: 2vh;
  display: inline-block;
  cursor: pointer;
  //   background-color: orange;
  z-index: 100;
  position: relative;
`;

class Lobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      myUid: props.myUid,
      activePlayers: {},

      playerOptions: false,
      myEmoji: props.myEmoji,
      closeFunc: props.closeFunc,
      im18: props.im18,
      isGM: props.isGM,
      anchorEl: null,
      selectedPlayerUid: "",
      selectedPlayer: {},
      selectedPlayerPartner: {},
      imTaken: props.status === 2,
      selectRecipient: props.selectRecipient,
      handleSendDmOpen: props.handleSendDmOpen,
      advanced: props.advanced,
    };
    this.handleClose = this.handleClose.bind(this);
    this.getPlayers = this.getPlayers.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handlePlayerOptionsOpen = this.handlePlayerOptionsOpen.bind(this);
    this.handlePlayerOptionsClose = this.handlePlayerOptionsClose.bind(this);
    this.handleSetPartner = this.handleSetPartner.bind(this);
    this.handleRemovePartner = this.handleRemovePartner.bind(this);

    this.playersRef = firebase
      .database()
      .ref("games/" + this.state.gamecode + "/players");
  }

  componentDidMount() {
    this.playersRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        let activePlayers = snapshot.val();
        this.setState({ activePlayers: activePlayers });
      }
    });
  }

  componentWillUnmount() {
    this.playersRef.off("value");
  }

  handleClose() {
    this.state.closeFunc();
  }

  handleClick(e, playerUid, player) {
    this.setState({
      anchorEl: e.currentTarget,
      selectedPlayerUid: playerUid,
      selectedPlayer: player,
      selectedPlayerPartner: player.partner,
    });

    this.handlePlayerOptionsOpen();
  }

  getPlayers() {
    const playerComponents = [];
    this.playersRef.once("value", (snapshot) => {
      if (snapshot.exists()) {
        let activePlayers = snapshot.val();
        // this.setState({ activePlayers: activePlayers });
        Object.entries(activePlayers).forEach(([key, value]) => {
          if (value.isActive && !value.inTimeout) {
            if (value.isActive) {
              if (key === this.state.myUid) {
                playerComponents.push(
                  <PlayerTextReady style={{ cursor: "default" }} key={key}>
                    <span style={{ whiteSpace: "nowrap" }}>
                      {value.emoji}
                      {value.name}
                    </span>
                  </PlayerTextReady>
                );
              } else {
                if (value.isReady) {
                  playerComponents.push(
                    <PlayerTextReady
                      onClick={(e) => this.handleClick(e, key, value)}
                      key={key}
                    >
                      <span style={{ whiteSpace: "nowrap" }}>
                        {value.emoji}
                        {value.name}
                      </span>
                    </PlayerTextReady>
                  );
                } else {
                  playerComponents.push(
                    <PlayerTextWaiting key={key}>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {value.emoji}
                        {value.name}
                      </span>
                    </PlayerTextWaiting>
                  );
                }
              }
            }
          }
        });
      }
    });
    return playerComponents;
  }

  handlePlayerOptionsOpen = () => {
    this.setState({ playerOptions: true });
  };

  handlePlayerOptionsClose = () => {
    this.setState({ playerOptions: false, anchorEl: null });
  };

  handleSendMessage = () => {
    this.state.selectRecipient(
      this.state.selectedPlayerUid,
      this.state.selectedPlayer.name,
      this.state.selectedPlayer.emoji
    );
    this.handlePlayerOptionsClose();
    this.handleClose();
    this.state.handleSendDmOpen();
  };

  handleChangeGM = () => {
    changeGM(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid
    );
    this.handlePlayerOptionsClose();
    this.handleClose();
  };

  handleKick = () => {
    kick(this.state.gamecode, this.state.selectedPlayerUid);
    this.handlePlayerOptionsClose();
    // this.handleClose();
  };

  handleBan = () => {
    ban(this.state.gamecode, this.state.selectedPlayerUid);
    this.handlePlayerOptionsClose();
    // this.handleClose();
  };

  handleSetPartner = () => {
    setPartner(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid,
      this.state.myEmoji,
      this.state.selectedPlayer.emoji
    );
    this.handlePlayerOptionsClose();
  };

  handleRemovePartner = () => {
    removePartner(
      this.state.gamecode,
      this.state.myUid,
      this.state.selectedPlayerUid
    );
    this.handlePlayerOptionsClose();
  };

  render() {
    return (
      <>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.playerOptions}
          onClose={this.handlePlayerOptionsClose}
          PaperProps={{
            style: {
              maxHeight: "50rem",
              width: "13rem",
            },
          }}
        >
          <div>
            {" "}
            <div style={{ textAlign: "center" }}>
              <span style={{ fontSize: "2.5rem" }}>
                {this.state.selectedPlayer.emoji}
              </span>{" "}
              <br />
              {this.state.selectedPlayer.name}
              <br />
              {Object.keys(this.state.selectedPlayer).length !== 0 &&
                getEmojiList(this.state.selectedPlayer, this.state.im18)}
              <br />
            </div>
            <br />
            {this.state.isGM && (
              <div>
                <MenuItem onClick={this.handleChangeGM}>
                  Make Gamemaster
                </MenuItem>
                <MenuItem onClick={this.handleKick}>Kick</MenuItem>
                <MenuItem onClick={this.handleBan}>Ban</MenuItem>
                {this.state.advanced[7] && (
                  <MenuItem onClick={this.handleSendMessage}>Message</MenuItem>
                )}
                {this.state.selectedPlayerPartner &&
                  this.state.selectedPlayer.is18 &&
                  this.state.im18 &&
                  this.state.imTaken &&
                  this.state.selectedPlayer.showStatus &&
                  this.state.selectedPlayer.status === 2 &&
                  !(
                    this.state.selectedPlayerPartner.partnerUid ===
                      this.state.myUid &&
                    this.state.selectedPlayerPartner.mutual
                  ) && (
                    <MenuItem onClick={this.handleSetPartner}>
                      Set as Partner ♥︎
                    </MenuItem>
                  )}
                {this.state.selectedPlayerPartner &&
                  this.state.selectedPlayerPartner.partnerUid ===
                    this.state.myUid &&
                  this.state.selectedPlayerPartner.mutual && (
                    <MenuItem onClick={this.handleRemovePartner}>
                      Remove as Partner
                    </MenuItem>
                  )}
              </div>
            )}
            {/* TODO */}
            {!this.state.isGM && (
              <div>
                {this.state.advanced[7] && (
                  <MenuItem onClick={this.handleSendMessage}>Message</MenuItem>
                )}
                {this.state.selectedPlayerPartner &&
                  this.state.selectedPlayer.is18 &&
                  this.state.im18 &&
                  this.state.imTaken &&
                  this.state.selectedPlayer.showStatus &&
                  this.state.selectedPlayer.status === 2 &&
                  !(
                    this.state.selectedPlayerPartner.partnerUid ===
                      this.state.myUid &&
                    this.state.selectedPlayerPartner.mutual
                  ) && (
                    <MenuItem onClick={this.handleSetPartner}>
                      Set as Partner ♥︎
                    </MenuItem>
                  )}
                {this.state.selectedPlayerPartner &&
                  this.state.selectedPlayerPartner.partnerUid ===
                    this.state.myUid &&
                  this.state.selectedPlayerPartner.mutual && (
                    <MenuItem onClick={this.handleRemovePartner}>
                      Remove as Partner
                    </MenuItem>
                  )}
              </div>
            )}
          </div>
        </Menu>
        <Container>
          <div style={{ marginTop: "1rem" }}>
            <Players>{this.getPlayers()}</Players>
          </div>
        </Container>
        <FaderTop> </FaderTop>
        <FaderBottom> </FaderBottom>
      </>
    );
  }
}

export default Lobby;
