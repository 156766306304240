import React from "react";
import styled from "styled-components";

import { getActivePlayers } from "../../utilities/utils";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

const Result = styled.span`
  font-size: 1.5rem;
`;

class PlayerUtility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      result: {},
      render: false,
    };
  }

  componentDidMount() {
    let activePlayersPromise = getActivePlayers(this.state.gamecode);
    activePlayersPromise.then((activePlayers) => {
      let randIdx = Math.floor(
        Math.random() * Object.keys(activePlayers).length
      );
      this.setState({
        result: activePlayers[Object.keys(activePlayers)[randIdx]],
        render: true,
      });
    });
  }

  render() {
    return (
      <Container>
        <Title>
          <b>You Picked</b> <Emoji> 🖐</Emoji>
        </Title>
        {this.state.render && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Emoji>{this.state.result.emoji}</Emoji>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Result>{this.state.result.name}</Result>
            </div>
          </>
        )}
      </Container>
    );
  }
}

export default PlayerUtility;
