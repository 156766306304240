import React from "react";
import styled from "styled-components";

import { Typography, Switch, Grid } from "@material-ui/core";

const StyledCrementButton = styled.button`
  margin-top: -0.3rem;
  border: none;
  width: 1.5rem;
  background-color: white;
  color: black;
  border: 1px solid #eeeeee;
  border-radius: 2rem;
  &:hover {
    cursor: pointer;
    background-color: #eeeeee;
  }
  font-family: Comic Neue;
  // font-weight: bold;
  font-size: 0.9rem;
`;

const RoundNumber = styled.span`
  font-family: Comic Neue;
  font-size: 1.2rem;
`;

const Container = styled.div`
  cursor: default;
  width: 100%;
  display: flex;
  margin-left: -1rem;
`;

export default class ActivitySettingItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: props.idx,
      name: props.name,
      emoji: props.emoji,
      numRounds: props.numRounds,
      switch: props.switch,
      updateFunc: props.updateFunc,
    };
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleDecrement = this.handleDecrement.bind(this);
    this.handleIncrement = this.handleIncrement.bind(this);
  }

  handleSwitch() {
    this.state.updateFunc(this.state.idx, "switch", !this.state.switch);
    this.setState({ switch: !this.state.switch });
  }

  handleDecrement() {
    if (this.state.numRounds > 1) {
      this.state.updateFunc(this.state.idx, "rounds", this.state.numRounds - 1);
      this.setState({ numRounds: this.state.numRounds - 1 });
    }
  }

  handleIncrement() {
    if (this.state.numRounds < 5) {
      this.state.updateFunc(this.state.idx, "rounds", this.state.numRounds + 1);
      this.setState({ numRounds: this.state.numRounds + 1 });
    }
  }

  render() {
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Typography>{this.state.emoji}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography>{this.state.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                whiteSpace: "nowrap",
                textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              <StyledCrementButton onClick={this.handleDecrement}>
                {"-"}
              </StyledCrementButton>{" "}
              <RoundNumber>{this.state.numRounds}</RoundNumber>{" "}
              <StyledCrementButton onClick={this.handleIncrement}>
                {"+"}
              </StyledCrementButton>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div
              style={{
                textAlign: "right",
                marginTop: "-0.5rem",
                marginLeft: "1rem",
              }}
            >
              <Switch
                checked={this.state.switch}
                onChange={this.handleSwitch}
                color="primary"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
