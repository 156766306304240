import React from "react";
import styled from "styled-components";
import firebase from "../firebase";
import { Redirect } from "react-router-dom";

import HeaderBar from "./HeaderBar";
import RoundInfo from "./RoundInfo";
import GameSpace from "./GameSpace";
import Teleprompter from "./Teleprompter";
import FooterBar from "./FooterBar";
import GamesNotFound from "./GamesNotFound";
import Loading from "./Loading";
import DialogPopup from "./DialogPopup";
import Message from "./Message";
import GMControls from "./GMControls";
import Inflow from "./Inflow";
import Consent from "./Consent";
import Setup from "./Setup/Setup.js";
import SettingsList from "./Settings/SettingsList.js";
import SettingsPagePlayers from "./Settings/SettingsPagePlayers.js";
import SettingsPageRooms from "./Settings/SettingsPageRooms.js";

import { Snackbar, Dialog } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { SwipeableDrawer } from "@mui/material";

import { addToPlayerOrder } from "../utilities/gameUtilities";
import {
  sleep,
  IPInGame,
  setCookie,
  cookieExists,
  getCookie,
  removeCookie,
  getNewPlayerData,
  isBannedFromGame,
  getPlayerEmoji,
  initializePenalties,
  // assignGM,
} from "../utilities/utils.js";

import {
  defaultStartingPenalties,
  // lengthOfQuotesDataset,
} from "../constants/parameters";

const publicIp = require("public-ip");

const Container = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

class PageActiveGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageOpen: false,
      messageSender: "",
      messageMessage: "",
      dialogOpen: false,
      dialogTypecode: 0,
      dialogTitle: "",
      dialogEmoji: "",
      dialogDescription: "",
      popupsLeft: [],
      pauseDialogOpen: false,
      isPaused: false,
      inflowOpen: false,
      setupOpen: false,
      didSetup: false,
      consentOpen: false,
      dmOpen: false,
      settingsDrawerOpen: false,
      gmControlsOpen: false,
      settingsPageOpen: false,
      settingsPagetype: "",

      setupItems: {},
      rooms: [],
      mainRoom: "",
      games: [],
      activities: [],
      async: [],
      advanced: [],

      IP: "",
      emoji: "",
      redirect: null,
      myUid: "",
      database: null,
      gamecode: this.props.location.pathname.substring(1).toUpperCase(),
      activeGame: false,
      gamesOver: false,
      loading: true,
      isHost: false,
      isGM: false,
      is18: false,
      isReady: false,
      hasStarted: false,
      inTimeout: false,
      name: "",
      penalties: 0,
      userNeedsInit: true,

      status: 0,
      showStatus: false,

      sendMsgTo: "",
      sendMsgToEmoji: "",
      sendMsgToName: "",

      rerender: false,

      playerOrder: [],
      round: 0,
      game: "",
      gameEmoji: "",
      gameData: {},
      gameDescription: "",

      currPlayerEmoji: "",
      currPlayerName: "",
    };

    this.audio = new Audio();
    this.audio.autoplay = true;
    this.audio.src =
      "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

    this.handleMessageOpen = this.handleMessageOpen.bind(this);
    this.handleMessageClose = this.handleMessageClose.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handlePauseDialogOpen = this.handlePauseDialogOpen.bind(this);
    this.handlePauseDialogClose = this.handlePauseDialogClose.bind(this);
    this.handleClickedRejoin = this.handleClickedRejoin.bind(this);
    this.handleInflowClose = this.handleInflowClose.bind(this);
    this.handleInflowOpen = this.handleInflowOpen.bind(this);
    this.handleSetupOpen = this.handleSetupOpen.bind(this);
    this.handleSetupClose = this.handleSetupClose.bind(this);
    this.handleConsentOpen = this.handleConsentOpen.bind(this);
    this.handleConsentClose = this.handleConsentClose.bind(this);
    this.handleSettingsPageSelect = this.handleSettingsPageSelect.bind(this);
    this.handleSettingsPageClose = this.handleSettingsPageClose.bind(this);
    this.handleSettingsPageOpen = this.handleSettingsPageOpen.bind(this);
    this.handleSendDmClose = this.handleSendDmClose.bind(this);
    this.handleSendDmOpen = this.handleSendDmOpen.bind(this);
    this.handleSettingsDrawerOpen = this.handleSettingsDrawerOpen.bind(this);
    this.handleSettingsDrawerClose = this.handleSettingsDrawerClose.bind(this);
    this.handleGMControlsOpen = this.handleGMControlsOpen.bind(this);
    this.handleGMControlsClose = this.handleGMControlsClose.bind(this);
    this.getSetupItems = this.getSetupItems.bind(this);
    this.handleSetGameItems = this.handleSetGameItems.bind(this);
    this.getPlayerEmojis = this.getPlayerEmojis.bind(this);
    this.setSelectedRecipient = this.setSelectedRecipient.bind(this);
    this.setStateCallback = this.setStateCallback.bind(this);
  }

  componentDidMount = async () => {
    this.setState({
      database: firebase.database(),
    });
    // if (this.state.database && this.state.gamecode && this.state.myUid) {
    //   let ref = this.state.database.ref(
    //     "/games/" + this.state.gamecode + "/players/" + this.state.myUid
    //   );
    //   ref.child("/isGM").on("value", (snapshot) => {
    //     if (snapshot.exists()) {
    //       let imGM = snapshot.val();
    //       this.setState({ isGM: imGM, rerender: !this.state.rerender });
    //     }
    //   });
    // }
    document.addEventListener("visibilitychange", () => {
      if (this.state.database && this.state.gamecode && this.state.myUid) {
        let ref = this.state.database.ref(
          "/games/" + this.state.gamecode + "/players/" + this.state.myUid
        );

        ref.child("/isPresent").set(!document.hidden);
        if (document.hidden) {
          let datetime = new Date();
          ref.child("/lastSeen").set(datetime.getTime() / 1000);
        }
      }
    });
    this.getIP();
    this.getSetupItems();
    this.getPlayerEmojis();
    sleep(300).then((r) => {
      if (this.state.database) {
        let ref = this.state.database.ref("/games/" + this.state.gamecode);
        ref.once("value", (snapshot) => {
          if (snapshot.exists()) {
            const game = snapshot.val();
            if (game.isFinished) {
              this.setState({
                loading: false,
                activeGame: false,
              });
              return;
            }
            this.setState({
              loading: false,
              activeGame: true,
              advanced:
                game.advanced && game.advanced.length !== 0
                  ? game.advanced
                  : [],
              hasStarted: game.hasStarted,
              playerOrder: game.playerOrder,
              round: game.round,
              game: game.game.game,
              gameEmoji: game.game.gameEmoji,
              gameData: game.game.gameData,
              gameDescription: game.game.gameDescription,
            });
            console.log("initing user");
            this.initUser();
            sleep(400).then((r) => {
              this.initListeners();
            });
          }
        });
      }
    });
    sleep(4000).then((r) => {
      if (!this.state.activeGame) this.setState({ loading: false });
    });
  };

  getIP = async () => {
    try {
      let ip = await publicIp.v4();
      this.setState({ IP: ip });
    } catch (e) {}
  };

  // WARNING DON'T DOWNLOAD ALL OF GAMEASSETS
  getSetupItems() {
    let gameAssets = {};
    let assetsRef = firebase.database().ref("/gameassets/inGame");
    assetsRef.once("value", (snapshot) => {
      if (snapshot.exists()) {
        let assets = snapshot.val();
        gameAssets["activities"] = assets.activities;
        gameAssets["asynchronous"] = assets.asynchronous;
        let gamesFromDB = [];
        for (let i = 0; i < assets.games.length; i++) {
          if (assets.games[i].active) {
            let game = {
              duration: assets.games[i].duration,
              description: assets.games[i].description
                ? assets.games[i].description
                : "",
              emoji: assets.games[i].emoji,
              game: assets.games[i].game,
              inGame: assets.games[i].inGame,
              blockPZ: assets.games[i].blockPZ,
              blockAsync: assets.games[i].blockAsync,
              picked: false,
            };
            gamesFromDB.push(game);
          }
        }
        gameAssets["games"] = gamesFromDB;
        this.setState({ setupItems: gameAssets });
      }
    });
  }

  getPlayerEmojis() {
    let gameRef = firebase.database().ref("/games/" + this.state.gamecode);
    gameRef.child("/playerEmojis").once("value", (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ playerEmojis: snapshot.val() });
      }
    });
  }

  handleSetGameItems(rooms, mainRoom, games, activities, async, advanced) {
    this.setState({
      rooms: rooms,
      mainRoom: mainRoom,
      games: games,
      activities: activities,
      async: async,
      advanced: advanced,
      rerender: !this.state.rerender,
    });

    let gameitems = {
      rooms: rooms,
      mainRoom: mainRoom,
      games: games,
      activities: activities,
      async: async,
      advanced: advanced,
      rounds: advanced[0],
    };
    this.state.database.ref("games/" + this.state.gamecode).update(gameitems);
    initializePenalties(this.state.gamecode, advanced[6]);
  }

  componentDidUpdate() {
    const playersRef = this.state.database.ref(
      "/games/" + this.state.gamecode + "/players"
    );
    //check if need to kick myself
    let playerRef = playersRef.child("/" + this.state.myUid);
    // just changed from .on() to .once()
    playerRef.child("/kickself").once("value", (snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.val() === true) {
          let datetime = new Date();
          playerRef.child("/isPresent").set(false);
          playerRef.child("/lastSeen").set(datetime.getTime() / 1000);
          playerRef.child("/isGM").set(false);
          playerRef.child("/kickself").set(false);
          playerRef
            .child("/isActive")
            .set(false)
            .then(this.setState({ redirect: "/kicked" }));
        }
      }
    });

    // handle setting isPresent true when back from page refresh
    if (this.state.myUid && !document.hidden)
      playersRef.child("/" + this.state.myUid + "/isPresent").set(true);

    // go through and check if there is an active GM, if not reassign
    // playersRef.once("value", (snapshot) => {
    //   if (snapshot.exists()) {
    //     let players = snapshot.val();
    //     var hasGM = false;
    //     Object.entries(players).map(([key, value]) => {
    //       if (value.isGM) {
    //         hasGM = true;
    //       }
    //     });
    //     if (!hasGM) {
    //       assignGM(this.state.gamecode);
    //     }
    //   }
    // });
    // // check every other players lastSeen time IF they are GM AND not present to see if need to reassign GM (past 3 mins)
    // playersRef.once("value", (snapshot) => {
    //   if (snapshot.exists()) {
    //     let players = snapshot.val();
    //     let datetime = new Date();
    //     for (var i = 0; i < Object.keys(players).length; i++) {
    //       var value = players[Object.keys(players)[i]];
    //       if (value.isActive && value.isGM && !value.isPresent) {
    //         if (
    //           // if 2 mins has past since GM going un-present
    //           value.lastSeen &&
    //           value.lastSeen + 10 < datetime.getTime() / 1000
    //         ) {
    //           playersRef.child("/" + this.state.myUid + "/isGM").set(false);
    //           this.setState({ isGM: false });
    //         }
    //       }
    //     }
    //   }
    // });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.database !== nextState.database) return false;
    return true;
  }

  // find or create player data and uid, and set state accordingly, ensure not banned
  initUser = () => {
    if (this.state.userNeedsInit) {
      const { database } = this.state;
      const userRef = database.ref("users/");
      const gameRef = database.ref("games/" + this.state.gamecode);
      var [IPExists, uidOfIP] = IPInGame(this.state.gamecode, this.state.IP);
      var didHost = cookieExists("didHost") ? getCookie("didHost") : false;
      removeCookie("didHost");

      //if my IP already in game
      if (this.state.IP !== "" && uidOfIP !== null && IPExists === true) {
        // get uid of old session and retrieve current player data to update state
        console.log("1 IP in game");

        //changed from on to once
        gameRef.child("/players/" + uidOfIP).once("value", (snapshot) => {
          if (snapshot.exists()) {
            let player = snapshot.val();
            if (
              player.isBanned ||
              isBannedFromGame(this.state.gamecode, this.state.IP)
            ) {
              this.setState({ redirect: "/banned" });
            } else {
              this.setState({
                myUid: uidOfIP,
                isHost: player.isHost,
                isGM: player.isGM,
                isActive: player.isActive,
                isReady: player.isReady,
                is18: player.is18,
                inTimeout: player.inTimeout,
                name: player.name,
                emoji: player.emoji,
                status: player.status,
                showStatus: player.showStatus,
                penalties: player.penalties,
                rerender: !this.state.rerender,
                userNeedsInit: false,
              });
              if (!this.state.isReady) {
                this.handleConsentOpen();
              }
              console.log("4.1 " + uidOfIP);
              gameRef.child("/players/" + uidOfIP + "/isActive").set(true);
              setCookie("myUid", uidOfIP);
              this.initializeDisconnectListener(uidOfIP);
            }
          }
        });
      } else if (cookieExists("myUid")) {
        console.log("1.2 IP (" + this.state.IP + ") not in game, myUid exists");
        //my IP not in game but myUid cookie exists
        if (didHost) {
          console.log("2 hosted");
          removeCookie("myUid");
          // need new player data for HOST
          if (isBannedFromGame(this.state.gamecode, this.state.IP)) {
            this.setState({ redirect: "/banned" });
          } else {
            let playerEmoji = getPlayerEmoji(
              this.state.gamecode,
              this.state.playerEmojis
            );
            let pens =
              this.state.advanced.length === 0
                ? defaultStartingPenalties
                : this.state.advanced[6];
            let playerData = getNewPlayerData(
              this.state.IP,
              true,
              true,
              playerEmoji,
              pens
            );
            let newUid = userRef.push({ name: "" }).key;
            this.setState({
              myUid: newUid,
              isHost: true,
              isGM: true,
              inTimeout: playerData.inTimeout,
              isActive: playerData.isActive,
              isReady: playerData.isReady,
              name: playerData.name,
              emoji: playerEmoji,
              penalties: playerData.penalties,
              status: playerData.status,
              showStatus: playerData.showStatus,
              rerender: !this.state.rerender,
              userNeedsInit: false,
            });
            this.handleConsentOpen();
            if (this.state.hasStarted)
              addToPlayerOrder(this.state.gamecode, newUid);
            gameRef.child("/players/" + newUid).set(playerData);
            console.log("4.2");
            gameRef.child("/players/" + newUid + "/isActive").set(true);
            setCookie("myUid", newUid);
            this.initializeDisconnectListener(newUid);
          }
        } else {
          console.log("2 not hosted");
          // find current uid and retrieve player data
          let currentUidCookie = getCookie("myUid");
          gameRef
            .child("/players/" + currentUidCookie)
            .once("value", (snapshot) => {
              //changed from .on to .once
              if (snapshot.exists()) {
                let player = snapshot.val();
                if (
                  player.isBanned ||
                  isBannedFromGame(this.state.gamecode, this.state.IP)
                ) {
                  this.setState({ redirect: "/banned" });
                } else {
                  this.setState({
                    myUid: currentUidCookie,
                    isHost: player.isHost,
                    isGM: player.isGM,
                    isActive: player.isActive,
                    isReady: player.isReady,
                    is18: player.is18,
                    inTimeout: player.inTimeout,
                    name: player.name,
                    emoji: player.emoji,
                    status: player.status,
                    showStatus: player.showStatus,
                    penalties: player.penalties,
                    rerender: !this.state.rerender,
                    userNeedsInit: false,
                  });
                  if (!this.state.isReady) {
                    this.handleConsentOpen();
                  }
                  console.log("4.3");
                  gameRef
                    .child("/players/" + currentUidCookie + "/isActive")
                    .set(true);
                  this.initializeDisconnectListener(currentUidCookie);
                }
              } else {
                // has myUid cookie but is not part of THIS game yet
                console.log(
                  "4.4 has myUid cookie but is not part of THIS game yet"
                );
                if (isBannedFromGame(this.state.gamecode, this.state.IP)) {
                  this.setState({ redirect: "/banned" });
                } else {
                  let playerEmoji = getPlayerEmoji(
                    this.state.gamecode,
                    this.state.playerEmojis
                  );
                  let pens =
                    this.state.advanced.length === 0
                      ? defaultStartingPenalties
                      : this.state.advanced[6];
                  var playerData = getNewPlayerData(
                    this.state.IP,
                    false,
                    false,
                    playerEmoji,
                    pens
                  );
                  let currUid = getCookie("myUid");
                  this.setState({
                    myUid: currUid,
                    isHost: false,
                    isGM: false,
                    inTimeout: playerData.inTimeout,
                    isActive: playerData.isActive,
                    isReady: playerData.isReady,
                    name: playerData.name,
                    emoji: playerEmoji,
                    status: playerData.status,
                    showStatus: playerData.showStatus,
                    penalties: playerData.penalties,
                    rerender: !this.state.rerender,
                    userNeedsInit: false,
                  });
                  this.handleConsentOpen();
                  if (this.state.hasStarted)
                    addToPlayerOrder(this.state.gamecode, currUid);
                  gameRef.child("/players/" + currUid).set(playerData);
                  gameRef.child("/players/" + currUid + "/isActive").set(true);
                  this.initializeDisconnectListener(currUid);
                }
              }
            });
        }
      } else {
        console.log("1.3 IP not in game and myUid not exists");
        // neither IP in game nor myUid cookie exists
        if (didHost) {
          console.log("3 hosted");
          // need new player data for HOST
          if (isBannedFromGame(this.state.gamecode, this.state.IP)) {
            this.setState({ redirect: "/banned" });
          } else {
            let playerEmoji = getPlayerEmoji(
              this.state.gamecode,
              this.state.playerEmojis
            );
            let pens =
              this.state.advanced.length === 0
                ? defaultStartingPenalties
                : this.state.advanced[6];
            let playerData = getNewPlayerData(
              this.state.IP,
              true,
              true,
              playerEmoji,
              pens
            );
            let newUid = userRef.push({ name: "" }).key;
            this.setState({
              myUid: newUid,
              isHost: true,
              isGM: true,
              inTimeout: playerData.inTimeout,
              isActive: playerData.isActive,
              isReady: playerData.isReady,
              name: playerData.name,
              emoji: playerEmoji,
              status: playerData.status,
              showStatus: playerData.showStatus,
              penalties: playerData.penalties,
              rerender: !this.state.rerender,
              userNeedsInit: false,
            });
            this.handleConsentOpen();
            if (this.state.hasStarted)
              addToPlayerOrder(this.state.gamecode, newUid);
            gameRef.child("/players/" + newUid).set(playerData);
            console.log("4.4");
            gameRef.child("/players/" + newUid + "/isActive").set(true);
            setCookie("myUid", newUid);
            this.initializeDisconnectListener(newUid);
          }
        } else {
          console.log("3 not hosted");
          // need new player data for JOINING PLAYER
          if (isBannedFromGame(this.state.gamecode, this.state.IP)) {
            this.setState({ redirect: "/banned" });
          } else {
            let playerEmoji = getPlayerEmoji(
              this.state.gamecode,
              this.state.playerEmojis
            );
            let pens =
              this.state.advanced.length === 0
                ? defaultStartingPenalties
                : this.state.advanced[6];
            var playerData = getNewPlayerData(
              this.state.IP,
              false,
              false,
              playerEmoji,
              pens
            );
            let newUid = userRef.push({ name: "" }).key;
            this.setState({
              myUid: newUid,
              isHost: false,
              isGM: false,
              inTimeout: playerData.inTimeout,
              isActive: playerData.isActive,
              isReady: playerData.isReady,
              name: playerData.name,
              emoji: playerEmoji,
              status: playerData.status,
              showStatus: playerData.showStatus,
              penalties: playerData.penalties,
              rerender: !this.state.rerender,
              userNeedsInit: false,
            });
            this.handleConsentOpen();
            if (this.state.hasStarted)
              addToPlayerOrder(this.state.gamecode, newUid);
            gameRef.child("/players/" + newUid).set(playerData);
            console.log("4.5");
            gameRef.child("/players/" + newUid + "/isActive").set(true);
            setCookie("myUid", newUid);
            this.initializeDisconnectListener(newUid);
          }
        }
      }
    }
  };

  initializeDisconnectListener(uid) {
    let playerRef = firebase
      .database()
      .ref("games/" + this.state.gamecode + "/players/" + uid);
    let datetime = new Date();
    playerRef.child("/isPresent").onDisconnect().set(false);
    playerRef
      .child("/lastSeen")
      .onDisconnect()
      .set(datetime.getTime() / 1000);
  }

  initListeners() {
    let playerRef = this.state.database.ref(
      "/games/" + this.state.gamecode + "/players/" + this.state.myUid
    );
    let gameRef = this.state.database.ref("/games/" + this.state.gamecode);
    gameRef.child("/isFinished").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        if (val) {
          this.setState({ redirect: "/gamesover" });
        }
      }
    });
    gameRef.child("/hasStarted").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        this.setState({ hasStarted: val, rerender: !this.state.rerender });
      }
    });
    gameRef.child("/game").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        this.setState({
          game: val.game,
          gameEmoji: val.gameEmoji,
          gameData: val.gameData,
          gameDescription: val.gameDescription,
          rerender: !this.state.rerender,
        });
      }
    });
    gameRef.child("/round").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        this.setState({ round: val, rerender: !this.state.rerender });
      }
    });

    gameRef.child("/playerOrder").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        let currPlayerUid = val[0];
        gameRef.child("/players/" + currPlayerUid).once("value", (snapshot) => {
          if (snapshot.exists()) {
            let player = snapshot.val();
            this.setState({
              playerOrder: val,
              currPlayerEmoji: player.emoji,
              currPlayerName: player.name,
              rerender: !this.state.rerender,
            });
          } else {
            this.setState({ playerOrder: val, rerender: !this.state.rerender });
          }
        });
      }
    });
    playerRef.child("/isGM").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let imGM = snapshot.val();
        this.setState({
          isGM: imGM,
          rerender: !this.state.rerender,
        });
      }
    });
    playerRef.child("/message").on("value", (snapshot) => {
      if (snapshot.exists() && this.state.myUid !== "") {
        let message = snapshot.val();
        if (message.sender !== "" && message.msg !== "")
          this.handleMessageOpen(message.sender, message.msg);
      }
    });
    // playerRef.child("/partner").on("value", (snapshot) => {
    //   if (snapshot.exists() && this.state.myUid !== "") {
    //     let partner = snapshot.val();
    //     this.setState({
    //       partner: partner.partnerUid,
    //       partnerMutual: partner.mutual,
    //     });
    //   }
    // });
    playerRef.child("/isActive").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        this.setState({ isActive: val });
        if (val) {
          this.handleClickedRejoin();
        }
      }
    });
    playerRef.child("/penalties").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let val = snapshot.val();
        this.setState({
          penalties: val,
          rerender: !this.state.rerenderFooter,
        });
      }
    });
    // TODO
    gameRef.child("/advanced").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let advanced = snapshot.val();
        this.setState({ advanced: advanced });
        gameRef.once("value", (snapshot) => {
          if (snapshot.exists()) {
            let game = snapshot.val();
            // is this even needed?
            this.setState({
              rooms: game.rooms,
              mainRoom: game.mainRoom,
              games: game.games,
              activities: game.activities,
              async: game.async,
              rerender: !this.state.rerender,
            });
          }
        });
      }
    });
    playerRef.child("/popups").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let popups = snapshot.val().splice(1);
        if (popups.length > 0) {
          this.processPopup(popups);
          playerRef.child("/popups").set([{ typecode: 0 }]);
        }
      }
    });
    // playerRef.child("/inTimeout").on("value", (snapshot) => {
    //   if (snapshot.exists()) {
    //     let timeout = snapshot.val();
    //     this.setState({ inTimeout: timeout });
    //   }
    // });
    playerRef.child("/isPaused").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let paused = snapshot.val();
        if (paused) {
          this.handlePause();
        }
      }
    });
    // playerRef.child("/isReady").on("value", (snapshot) => {
    //   if (snapshot.exists()) {
    //     let ready = snapshot.val();
    //     this.setState({ isReady: ready });
    //   }
    // });
    playerRef.child("/reroute").on("value", (snapshot) => {
      if (snapshot.exists()) {
        playerRef.child("/reroute").remove();
        let url = snapshot.val();
        if (url) window.location.assign(url);
      }
    });
    playerRef.child("/sound").on("value", (snapshot) => {
      if (snapshot.exists()) {
        let url = snapshot.val();
        playerRef.child("/sound").set("");
        if (url && url !== "") {
          try {
            this.audio.src = url;
          } catch (e) {}
        }
      }
    });
  }

  handleMessageOpen(sender, message) {
    this.audio.src =
      "https://assets.mixkit.co/sfx/preview/mixkit-correct-answer-notification-947.mp3";
    this.setState({
      messageSender: sender,
      messageMessage: message,
      messageOpen: true,
    });
    this.state.database
      .ref("games/" + this.state.gamecode + "/players/" + this.state.myUid)
      .child("/message")
      .set({ msg: "", sender: "" });
  }

  handleMessageClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ messageOpen: false });
  }

  processPopup(popups) {
    var newPopupQueue = [...this.state.popupsLeft];
    for (let i in popups) {
      newPopupQueue.unshift(popups[i]);
    }
    this.setState({
      popupsLeft: newPopupQueue,
    });
    if (!this.state.dialogOpen) this.handleDialogOpen(newPopupQueue);
  }

  handleDialogOpen(newPopupQueue) {
    let queue = newPopupQueue ? newPopupQueue : this.state.popupsLeft;
    if (queue.length > 0) {
      let popupsRemaining = [...queue];
      let popup = popupsRemaining.pop();

      this.audio.src =
        "https://notificationsounds.com/storage/sounds/file-sounds-1154-done-for-you.mp3";
      // this.audio.src =
      //   "https://notificationsounds.com/storage/sounds/file-sounds-1125-insight.mp3";
      this.setState({
        dialogOpen: true,
        dialogTypecode: popup.typecode,
        dialogTitle: popup.title,
        dialogEmoji: popup.emoji,
        dialogDescription: popup.description,
        popupsLeft: popupsRemaining,
      });
    }
  }

  handleDialogClose() {
    this.audio.src = "";
    this.setState({
      dialogOpen: false,
      dialogTypecode: 0,
      dialogTitle: "",
      dialogEmoji: "",
      dialogDescription: "",
    });
    if (this.state.popupsLeft.length > 0) {
      sleep(250).then(() => {
        this.handleDialogOpen();
      });
    }
  }

  handlePauseDialogOpen() {
    this.setState({
      pauseDialogOpen: true,
    });
  }

  handlePauseDialogClose() {
    this.setState({ pauseDialogOpen: false });
  }

  handleCallbackRating() {}

  handleInflowOpen() {
    //on mount when isReady false
    this.setState({ inflowOpen: true });
  }

  handleInflowClose() {
    this.setState({ inflowOpen: false });
    if (this.state.isHost) {
      this.handleSetupOpen();
    }
  }

  handleSetupOpen = () => {
    this.setState({ setupOpen: true });
  };

  handleSetupClose() {
    this.setState({ setupOpen: false, didSetup: true });
  }

  handleConsentOpen() {
    this.setState({ consentOpen: true });
  }
  handleConsentClose() {
    this.setState({ consentOpen: false });
    this.handleInflowOpen();
  }

  handlePause = () => {
    this.handlePauseDialogOpen();
    this.setState({ isPaused: true });
    this.state.database
      .ref("/games/" + this.state.gamecode + "/players/" + this.state.myUid)
      .child("/isActive")
      .set(false);
    this.state.database
      .ref("/games/" + this.state.gamecode + "/players/" + this.state.myUid)
      .child("/isPaused")
      .set(true);
  };

  handleClickedRejoin = () => {
    this.handleDialogClose();
    this.setState({
      isPaused: false,
    });
  };

  handleSendDmOpen = () => {
    this.setState({ dmOpen: true });
  };

  handleSendDmClose = () => {
    this.setState({ dmOpen: false });
  };

  handleSettingsDrawerOpen = () => {
    this.setState({ settingsDrawerOpen: true });
  };

  handleSettingsDrawerClose = () => {
    this.setState({ settingsDrawerOpen: false });
  };

  handleGMControlsOpen = () => {
    this.setState({ gmControlsOpen: true });
  };

  handleGMControlsClose = () => {
    this.setState({ gmControlsOpen: false });
  };

  handleSettingsPageSelect = (type) => {
    this.setState({ settingsPagetype: type });
    this.handleSettingsPageOpen();
  };

  handleSettingsPageClose = () => {
    this.setState({ settingsPageOpen: false });
  };

  handleSettingsPageOpen = () => {
    this.setState({ settingsPageOpen: true });
  };

  setSelectedRecipient = (playerUid, name, emoji) => {
    this.setState({
      sendMsgTo: playerUid,
      sendMsgToName: name,
      sendMsgToEmoji: emoji,
    });
  };

  setStateCallback = (name, is18, status, showStatus) => {
    this.setState({
      name: name,
      is18: is18,
      status: status,
      showStatus: showStatus,
      rerender: !this.state.rerender,
    });
  };

  render() {
    if (this.state.redirect) return <Redirect to={this.state.redirect} />;
    if (this.state.loading) return <Loading loading={this.state.loading} />;
    if (!this.state.activeGame) return <GamesNotFound />;
    return (
      <Container>
        <>
          <SwipeableDrawer
            // anchor={anchor}
            open={this.state.settingsDrawerOpen}
            onClose={this.handleSettingsDrawerClose}
            onOpen={this.handleSettingsDrawerOpen}
          >
            <SettingsList
              myUid={this.state.myUid}
              gamecode={this.state.gamecode}
              closeDrawer={this.handleSettingsDrawerClose}
              pauseFunc={this.handlePause}
              settingsPageFunc={this.handleSettingsPageSelect}
            />
          </SwipeableDrawer>
          <Snackbar
            open={
              this.state.messageOpen &&
              this.state.messageSender !== "" &&
              this.state.messageMessage !== ""
            }
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={this.handleMessageClose}
          >
            <MuiAlert
              icon={false}
              elevation={6}
              variant="filled"
              severity={"info"}
              onClose={this.handleMessageClose}
            >
              <b>{this.state.messageSender}</b>: {this.state.messageMessage}
            </MuiAlert>
          </Snackbar>
          <Dialog
            // paused
            disableEscapeKeyDown
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                this.handlePauseDialogClose();
              }
            }}
            open={this.state.pauseDialogOpen}
          >
            <DialogPopup
              emoji={"⏸"}
              message={"You stepped out"}
              typecode={4}
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              closeCallback={this.handlePauseDialogClose}
            />
          </Dialog>
          <Dialog
            //other popups
            onClose={this.handleDialogClose}
            open={this.state.dialogOpen}
          >
            <DialogPopup
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              ratingCallback={this.handleCallbackRating}
              closeCallback={this.handleDialogClose}
              typecode={this.state.dialogTypecode}
              title={this.state.dialogTitle}
              emoji={this.state.dialogEmoji}
              description={this.state.dialogDescription}
            />
          </Dialog>

          <Dialog
            onClose={this.handleGMControlsClose}
            open={this.state.gmControlsOpen}
          >
            <GMControls
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              handleGMControlsClose={this.handleGMControlsClose}
              advanced={this.state.advanced}
              round={this.state.round}
            />
          </Dialog>
          <Dialog
            onClose={this.handleSendDmClose}
            open={
              this.state.dmOpen &&
              this.state.sendMsgTo &&
              this.state.sendMsgTo !== "" &&
              this.state.sendMsgToName !== "" &&
              this.state.sendMsgToEmoji
            }
          >
            <Message
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              myName={this.state.name}
              myEmoji={this.state.emoji}
              closeFunc={this.handleSendDmClose}
              recipientName={this.state.sendMsgToName}
              recipientEmoji={this.state.sendMsgToEmoji}
              recipientUid={this.state.sendMsgTo}
              broadcast={this.state.advanced[8]}
            />
          </Dialog>
          <Dialog
            fullWidth
            disableEscapeKeyDown
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                this.handleInflowClose();
              }
            }}
            open={this.state.inflowOpen}
          >
            <Inflow
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              closeFunc={this.handleInflowClose}
              setStateCallback={this.setStateCallback}
            />
          </Dialog>
          <Dialog
            fullWidth
            disableEscapeKeyDown
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                this.handleConsentClose();
              }
            }}
            open={this.state.consentOpen}
          >
            <Consent closeFunc={this.handleConsentClose} />
          </Dialog>
          <Dialog
            // fullScreen
            fullWidth
            disableEscapeKeyDown
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                this.handleSetupClose();
              }
            }}
            open={this.state.setupOpen}
          >
            <Setup
              closeFunc={this.handleSetupClose}
              gameItemsFunc={this.handleSetGameItems}
              items={this.state.setupItems}
            />
          </Dialog>
          <Dialog
            fullWidth
            onClose={this.handleSettingsPageClose}
            open={this.state.settingsPageOpen}
          >
            {this.state.settingsPagetype === "players" && (
              <SettingsPagePlayers
                gamecode={this.state.gamecode}
                myUid={this.state.myUid}
                myEmoji={this.state.emoji}
                closeFunc={this.handleSettingsPageClose}
                im18={this.state.is18}
                isGM={this.state.isGM}
                status={this.state.status}
                selectRecipient={this.setSelectedRecipient}
                handleSendDmOpen={this.handleSendDmOpen}
                advanced={this.state.advanced}
              />
            )}
            {this.state.settingsPagetype === "rooms" && (
              <SettingsPageRooms
                gamecode={this.state.gamecode}
                myUid={this.state.myUid}
                closeFunc={this.handleSettingsPageClose}
                rooms={this.state.rooms}
                mainRoom={this.state.mainRoom}
              />
            )}
            {/* {this.state.settingsPagetype === "profile" && (
                <SettingsPageProfile
                  gamecode={this.state.gamecode}
                  myUid={this.state.myUid}
                  closeFunc={this.handleSettingsPageClose}
                />
              )} */}
          </Dialog>

          {this.state.rerender && (
            <HeaderBar
              gamecode={this.state.gamecode}
              emoji={this.state.emoji}
              hasStarted={this.state.hasStarted}
              isGM={this.state.isGM}
              openSettings={this.handleSettingsDrawerOpen}
              openGMControls={this.handleGMControlsOpen}
              didSetup={this.state.didSetup}
              setupFunc={this.handleSetupOpen}
            />
          )}
          {!this.state.rerender && (
            <HeaderBar
              gamecode={this.state.gamecode}
              emoji={this.state.emoji}
              hasStarted={this.state.hasStarted}
              isGM={this.state.isGM}
              openSettings={this.handleSettingsDrawerOpen}
              openGMControls={this.handleGMControlsOpen}
              didSetup={this.state.didSetup}
              setupFunc={this.handleSetupOpen}
            />
          )}
          {this.state.rerender && (
            <RoundInfo
              im18={this.state.is18}
              status={this.state.status}
              isGM={this.state.isGM}
              hasStarted={this.state.hasStarted}
              myUid={this.state.myUid}
              myEmoji={this.state.emoji}
              gamecode={this.state.gamecode}
              selectRecipient={this.setSelectedRecipient}
              handleSendDmOpen={this.handleSendDmOpen}
              advanced={this.state.advanced}
              round={this.state.round}
              game={this.state.game}
              gameEmoji={this.state.gameEmoji}
              playerOrder={this.state.playerOrder}
            />
          )}
          {!this.state.rerender && (
            <RoundInfo
              im18={this.state.is18}
              status={this.state.status}
              isGM={this.state.isGM}
              hasStarted={this.state.hasStarted}
              myUid={this.state.myUid}
              myEmoji={this.state.emoji}
              gamecode={this.state.gamecode}
              selectRecipient={this.setSelectedRecipient}
              handleSendDmOpen={this.handleSendDmOpen}
              advanced={this.state.advanced}
              round={this.state.round}
              game={this.state.game}
              gameEmoji={this.state.gameEmoji}
              playerOrder={this.state.playerOrder}
            />
          )}
          {this.state.rerender && (
            <GameSpace
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              hasStarted={this.state.hasStarted}
              myEmoji={this.state.emoji}
              myName={this.state.name}
              closeFunc={this.handleSettingsPageClose}
              im18={this.state.is18}
              isGM={this.state.isGM}
              status={this.state.status}
              selectRecipient={this.setSelectedRecipient}
              handleSendDmOpen={this.handleSendDmOpen}
              advanced={this.state.advanced}
              game={this.state.game}
              gameEmoji={this.state.gameEmoji}
              gameData={this.state.gameData}
              gameDescription={this.state.gameDescription}
              currPlayerName={this.state.currPlayerName}
              currPlayerEmoji={this.state.currPlayerEmoji}
            />
          )}
          {!this.state.rerender && (
            <GameSpace
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              hasStarted={this.state.hasStarted}
              myEmoji={this.state.emoji}
              myName={this.state.name}
              closeFunc={this.handleSettingsPageClose}
              im18={this.state.is18}
              isGM={this.state.isGM}
              status={this.state.status}
              selectRecipient={this.setSelectedRecipient}
              handleSendDmOpen={this.handleSendDmOpen}
              advanced={this.state.advanced}
              game={this.state.game}
              gameEmoji={this.state.gameEmoji}
              gameData={this.state.gameData}
              gameDescription={this.state.gameDescription}
              currPlayerName={this.state.currPlayerName}
              currPlayerEmoji={this.state.currPlayerEmoji}
            />
          )}

          <Teleprompter gamecode={this.state.gamecode} />

          {!this.state.rerender && (
            <FooterBar
              penalties={this.state.penalties}
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              hasStarted={this.state.hasStarted}
              selectRecipient={this.setSelectedRecipient}
              handleSendDmOpen={this.handleSendDmOpen}
              advanced={this.state.advanced}
            />
          )}
          {this.state.rerender && (
            <FooterBar
              penalties={this.state.penalties}
              gamecode={this.state.gamecode}
              myUid={this.state.myUid}
              hasStarted={this.state.hasStarted}
              selectRecipient={this.setSelectedRecipient}
              handleSendDmOpen={this.handleSendDmOpen}
              advanced={this.state.advanced}
            />
          )}
        </>
      </Container>
    );
  }
}

export default PageActiveGame;
