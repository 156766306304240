import React from "react";
import styled from "styled-components";

import { TextField, Button } from "@material-ui/core";

import { broadcast } from "../utilities/utils.js";

import parameters from "../constants/parameters.js";

const Container = styled.div`
  margin: 2rem;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

class Broadcast extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      msg: "",
      closeFunc: props.closeFunc,
    };
    this.handleChangeMsg = this.handleChangeMsg.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleChangeMsg = (e) => {
    if (e.target.value.length <= parameters.maxBroadcastLength)
      this.setState({ msg: e.target.value });
  };

  handleSend = () => {
    broadcast(this.state.gamecode, this.state.msg);
    this.state.closeFunc();
  };

  render() {
    return (
      <Container>
        <Title>
          <b>Broadcast</b> <Emoji> 📣</Emoji>
        </Title>
        <TextField
          onChange={(e) => this.handleChangeMsg(e)}
          label="Message"
          value={this.state.msg}
        />
        <br />
        <br />
        <br />

        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Button
            disabled={this.state.msg === ""}
            size="small"
            onClick={this.handleSend}
            style={{ paddingTop: "0.5rem" }}
          >
            Send
          </Button>
        </div>
      </Container>
    );
  }
}

export default Broadcast;
