import { useState } from "react";
import styled from "styled-components";

import { Snackbar, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import QRCode from "react-qr-code";

import { startGames } from "../utilities/gameUtilities";
import useWindowDimensions from "../utilities/resizer";

import { GMColor } from "../constants/colors";
import { url } from "../constants/references";
import { lineHeight, redLineMargin } from "../constants/dimensions";

const Container = styled.div`
  height: calc(2 * ${lineHeight});
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const ButtonGroup = styled.div`
  text-align: center;
  //   background-color: red;
`;

const DrawerButton = styled.div`
  width: ${redLineMargin};
  //   background-color: green;
  padding-top: 0.5vh;
  font-size: 5vh;
  cursor: pointer;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: scale(1.04);
    transform-origin: bottom;
    // background-color: grey;
  }
`;

const GamemakerButton = styled.div`
  width: ${redLineMargin};
  //   background-color: green;
  font-size: 5vh;
  cursor: pointer;
  &:hover {
    transform: scale(1.04);
    transform-origin: bottom;
    // background-color: grey;
  }
`;

const GameCode = styled.div`
  position: absolute;
  right: 0rem;
  padding-right: 1rem;
  //   background-color: yellow;
  cursor: pointer;
`;

const Code = styled.div`
  font-size: 2vh;
  font-weight: bolder;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  cursor: pointer;
`;

const StartButtonContainer = styled.div`
  position: absolute;
  width: 60%;
  margin-left: 20%;
  // background-color: yellow;
  margin-top: calc(${lineHeight} * 1);
`;

const StartButton = styled(Button)`
  // background-color: red;
  // position: absolute;
`;

// https://gist.github.com/rproenca/64781c6a1329b48a455b645d361a9aa3
window.Clipboard = (function (window, document, navigator) {
  var textArea, copy;

  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text) {
    textArea = document.createElement("textArea");
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var range, selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  copy = function (text) {
    createTextArea(text);
    selectText();
    copyToClipboard();
  };

  return {
    copy: copy,
  };
})(window, document, navigator);

const HeaderBar = (props) => {
  const { height } = useWindowDimensions();
  const [gamecode] = useState(props.gamecode);
  const [emoji] = useState(props.emoji);
  const [clipboardMessage, setClipboardMessage] = useState(false);
  const [hasStarted] = useState(props.hasStarted);
  const [isGM] = useState(props.isGM);
  const [didSetup] = useState(props.didSetup);

  const handleCopyGameLink = () => {
    try {
      navigator.clipboard.writeText(url + gamecode);
      if (!clipboardMessage) setClipboardMessage(true);
    } catch (e) {
      // mobile (ios)
      window.Clipboard.copy(url + gamecode);
      if (!clipboardMessage) setClipboardMessage(true);
    }
    //TODO mobile crashes for now, shouldn't when on https
  };

  const handleMessageClose = () => {
    setClipboardMessage(false);
  };

  const handleOpenSettings = () => {
    props.openSettings();
  };

  const handleGMControls = () => {
    props.openGMControls();
  };

  const handleStartGames = () => {
    startGames(gamecode);
  };

  return (
    <Container>
      <Snackbar
        open={clipboardMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={1000}
        onClose={handleMessageClose}
        style={{
          pointerEvents: "none",
        }}
      >
        <Alert
          icon={false}
          elevation={6}
          variant="filled"
          style={{ backgroundColor: "white", color: "black" }}
        >
          <b>Copied Link</b>
        </Alert>
      </Snackbar>
      {hasStarted && (
        <ButtonGroup>
          <DrawerButton onClick={handleOpenSettings}>{emoji}</DrawerButton>
          {isGM && (
            <GamemakerButton onClick={handleGMControls}>🔑</GamemakerButton>
          )}
        </ButtonGroup>
      )}
      {isGM && !hasStarted && (
        <StartButtonContainer>
          <StartButton
            style={{ backgroundColor: GMColor }}
            variant="outlined"
            onClick={!didSetup ? props.setupFunc : handleStartGames}
          >
            Start Games 🔑
          </StartButton>
        </StartButtonContainer>
      )}
      <GameCode onClick={handleCopyGameLink}>
        <Code>{gamecode.toUpperCase()} 📋</Code>
        <QRCode value={url + gamecode} size={height * 0.09} />
      </GameCode>
    </Container>
  );
};

export default HeaderBar;
