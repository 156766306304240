import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import { Button, MenuItem, Dialog } from "@material-ui/core";

import dimensions from "../../constants/dimensions";
import { ButtonColor, GMColor } from "../../constants/colors";

import { photoRouletteUpload } from "../../utilities/gameUtilities";
import { getActivePlayers } from "../../utilities/utils";

const Container = styled.div`
  width: 100%;
  //   background-color: blue;
  //   position: fixed;
`;

const Emoji = styled.span`
  font-size: 8rem;
`;

const Content = styled.div`
  // margin: 2rem;
  margin-top: 4rem;
  // background-color: red;
  line-height: 1.3;
  text-align: center;
`;

const TopButtonContainer = styled.div`
  position: absolute;
  width: 70%;
  margin-left: 15%;
  //   background-color: yellow;
  margin-top: calc(${dimensions.lineHeight} * -2.5);
`;

const GMButton = styled(Button)`
  //   background-color: yellow;
  // position: absolute;
`;

const PlayersListContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 10%;
  margin-top: -10%;
  height: 70%;
  z-index: 100;
  pointer-events: none;
`;

const PlayersList = styled(Dialog)`
  overflow: scroll;
`;

const MenuItemPlayer = styled(MenuItem)`
  padding: 1rem;
`;

class PhotoRouletteGame extends React.Component {
  constructor(props) {
    super(props);

    this.inputOpenFileRef = React.createRef();

    this.state = {
      width: 0,
      height: 0,

      isGM: props.isGM,
      emoji: props.emoji,
      myUid: props.myUid,
      gamecode: props.gamecode,
      selectedFile: null,
      isFilePicked: false,

      data: props.data,
      submitted: false,
      started: false,
      answerUid: "",
      guessed: false,
      showPlayerList: false,
      submissions: {},
      showAnswer: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleFilePickerOpen = this.handleFilePickerOpen.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleMakeGuess = this.handleMakeGuess.bind(this);
    this.handlePlayerListOpen = this.handlePlayerListOpen.bind(this);
    this.handlePlayerListClose = this.handlePlayerListClose.bind(this);
    this.handleRevealAnswer = this.handleRevealAnswer.bind(this);
    this.getPlayers = this.getPlayers.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    let gameData = this.state.data;
    if (gameData) {
      this.setState({
        submitted: gameData.submitted[this.state.myUid],
      });
      if (gameData.doneUploading) {
        this.setState({
          started: true,
          answerUid: gameData.answer,
          photoUrl: gameData.photos[gameData.answer],
          guessed: gameData.guesses[this.state.myUid] ? true : false,
          submissions: gameData.submitted,
          showAnswer: gameData.showAnswer,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleUploadPhoto = () => {
    if (this.state.selectedFile) {
      var metadata = {
        contentType: "image/jpeg",
        owner: this.state.myUid,
      };
      photoRouletteUpload(
        this.state.gamecode,
        this.state.myUid,
        this.state.selectedFile.name,
        this.state.selectedFile,
        metadata
      );
    }
  };

  handleFilePickerOpen = (e) => {
    this.inputOpenFileRef.current.click();
  };

  handleChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    this.setState({ selectedFile: file, isFilePicked: true });
  };

  handleStart = () => {
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/game/gameData/doneUploading")
      .set(true);
  };

  handleMakeGuess = () => {
    this.handlePlayerListOpen();
  };

  handlePlayerListOpen = () => {
    this.getPlayers().then((res) => {
      this.setState({ showPlayerList: true, playersList: res });
    });
  };

  handlePlayerListClose = () => {
    this.setState({ showPlayerList: false });
  };

  handleSelectGuess = (e, uid) => {
    firebase
      .database()
      .ref(
        "games/" +
          this.state.gamecode +
          "/game/gameData/guesses/" +
          this.state.myUid
      )
      .set(uid);
    this.handlePlayerListClose();
  };

  handleRevealAnswer = () => {
    firebase
      .database()
      .ref("games/" + this.state.gamecode + "/game/gameData/showAnswer/")
      .set(true);
  };

  getPlayers = () => {
    return new Promise((resolve, reject) => {
      getActivePlayers(this.state.gamecode).then((activePlayers) => {
        if (activePlayers && this.state.submissions) {
          var playersList = [];
          Object.entries(this.state.submissions).forEach(([key, _]) => {
            playersList.push(
              <MenuItemPlayer
                key={key}
                onClick={(e) => this.handleSelectGuess(e, key)}
              >
                {activePlayers[key].emoij} {activePlayers[key].name}
              </MenuItemPlayer>
            );
          });
          resolve(playersList);
        }
      });
    });
  };

  render() {
    const PhotoContainer = styled.div`
      position: absolute;
      margin-left: ${this.state.width > 650 ? `8rem` : `5%`};
      margin-top: 1rem;
      width: ${this.state.width > 650 ? `75%` : `90%`};
      height: calc(6.5 * ${dimensions.lineHeight});
      overflow: hidden;
      cursor: pointer;
      border-radius: 5%;
    `;

    const Image = styled.img`
      width: 100%;
      // margin-top: ${this.state.width > 650 ? `-4rem` : `0`};
      border-radius: 5%;
    `;

    return (
      <>
        <PlayersListContainer>
          <PlayersList
            fullWidth
            open={this.state.showPlayerList}
            onClose={this.handlePlayerListClose}
            PaperProps={{
              style: {
                maxHeight: "90%",
                width: "30ch",
              },
            }}
          >
            {this.state.playersList}
          </PlayersList>
        </PlayersListContainer>
        {this.state.isGM && this.state.submitted && !this.state.started && (
          <TopButtonContainer>
            <GMButton
              style={{ backgroundColor: GMColor }}
              variant="outlined"
              onClick={this.handleStart}
            >
              Start 🔑
            </GMButton>
          </TopButtonContainer>
        )}
        <Container>
          {!this.state.submitted && (
            <>
              <Content>
                <Emoji>{this.state.emoji}</Emoji>
              </Content>
              {!this.state.isFilePicked && (
                <Button
                  style={{ backgroundColor: ButtonColor }}
                  variant="outlined"
                  onClick={this.handleFilePickerOpen}
                >
                  Upload Photo
                </Button>
              )}
              {this.state.isFilePicked && (
                <>
                  <Button
                    style={{ backgroundColor: ButtonColor }}
                    variant="outlined"
                    onClick={this.handleFilePickerOpen}
                  >
                    Re-Upload
                  </Button>{" "}
                  <Button
                    style={{ backgroundColor: ButtonColor }}
                    variant="outlined"
                    onClick={this.handleUploadPhoto}
                  >
                    Submit
                  </Button>
                </>
              )}
              <div>
                <input
                  type="file"
                  name="file"
                  ref={this.inputOpenFileRef}
                  accept={"image/png, image/jpeg, image/gif, image/jpg"}
                  onChange={this.handleChangeFile}
                  style={{ display: "none" }}
                />
              </div>
            </>
          )}
          {this.state.started && this.state.photoUrl && (
            <>
              <PhotoContainer>
                {!this.state.guessed && (
                  <Image
                    src={this.state.photoUrl}
                    onClick={this.handleMakeGuess}
                  />
                )}
                {this.state.guessed && <Image src={this.state.photoUrl} />}
              </PhotoContainer>
              {!this.state.guessed && (
                <TopButtonContainer>
                  <Button
                    style={{ backgroundColor: ButtonColor }}
                    variant="outlined"
                    onClick={this.handleMakeGuess}
                  >
                    Make Guess
                  </Button>
                </TopButtonContainer>
              )}
              {this.state.guessed && this.state.isGM && !this.state.showAnswer && (
                <TopButtonContainer>
                  <GMButton
                    style={{ backgroundColor: ButtonColor }}
                    variant="outlined"
                    onClick={this.handleRevealAnswer}
                  >
                    Reveal Answer 🔑
                  </GMButton>
                </TopButtonContainer>
              )}
            </>
          )}
          {this.state.showAnswer && <span>ANSWER SHOWN</span>}
        </Container>
      </>
    );
  }
}

export default PhotoRouletteGame;
