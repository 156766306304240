import React from "react";
import styled from "styled-components";
import firebase from "../../firebase";

import { TextField, Button } from "@material-ui/core";

import parameters from "../../constants/parameters.js";

const Container = styled.div`
  margin: 2rem;
  cursor: default;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

const Emoji = styled.span`
  font-size: 1.6rem;
`;

class SubmitTord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gamecode: props.gamecode,
      tord: "",
      closeFunc: props.closeFunc,
    };
    this.handleChangeTord = this.handleChangeTord.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  handleChangeTord = (e) => {
    if (e.target.value.length <= parameters.maxTordSubmissionLength)
      this.setState({ tord: e.target.value });
  };

  handleSend = () => {
    firebase.database().ref("gameassets/submissions").push(this.state.tord);
    this.state.closeFunc();
  };

  render() {
    return (
      <Container>
        <Title>
          <b>Submit a Truth or Dare</b>
        </Title>
        <div style={{ textAlign: "center" }}>
          <Emoji>👄 </Emoji>
          <Emoji> ⚠️</Emoji>
        </div>
        <SubTitle>All submissions will be reviewed </SubTitle>

        <TextField
          style={{ width: "100%" }}
          onChange={(e) => this.handleChangeTord(e)}
          label="Truth or Dare"
          value={this.state.tord}
        />
        <br />
        <br />
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <br />
          <br />
          <Button
            disabled={this.state.tord === ""}
            size="small"
            onClick={this.handleSend}
          >
            Submit
          </Button>
        </div>
      </Container>
    );
  }
}

export default SubmitTord;
