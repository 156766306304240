module.exports = {
  GMColor: "rgb(255, 152, 65)",
  PMHoverColor: "#C7C7D1",
  // PMColor: "#00BBF9",
  PMColor: "#E6E6EA",
  HorLinesColor: "blue",
  VerLineColor: "red",
  GameBGColor: "rgba(255, 255, 100, 0.95)",
  rouletteWheelDividerColor: "#F8EED7",
  rouletteWheelEveryColor: "red",
  rouletteWheelOtherColor: "black",
  rouletteWheelInnerBorderColor: "#222222",
  rouletteWheelNobodyColor: "#4D8723",
  endGamesButtonColor: "rgba(255, 80,80,0.8)",
  ButtonColor: "white",
};
