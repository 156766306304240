import React from "react";
import styled from "styled-components";

import {
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Input,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import HomeIcon from "@material-ui/icons/Home";

const Container = styled.div`
  cursor: default;
  width: 100%;
  display: flex;
`;

export default class RoomSettingItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: props.idx,
      deleteFunc: props.deleteFunc,
      changeNameFunc: props.changeNameFunc,
      setMainFunc: props.setMainFunc,
      name: props.name,
      isMainroom: props.isMainroom,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleMain = this.handleMain.bind(this);
  }

  handleNameChange(e) {
    this.state.changeNameFunc(e.target.value, this.state.idx);
    this.setState({ name: e.target.value });
  }

  handleDelete() {
    this.state.deleteFunc(this.state.idx);
  }

  handleMain() {
    this.state.setMainFunc(this.state.idx);
  }

  render() {
    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography>
              <Input
                style={{ width: "100%" }}
                onChange={(e) => this.handleNameChange(e)}
                value={this.state.name}
                placeholder="Room Name"
              />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div style={{ textAlign: "right" }}>
              {!this.state.isMainroom && (
                <IconButton
                  style={{ marginTop: "-0.3rem" }}
                  onClick={this.handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {!this.state.isMainroom && (
                <Tooltip title="Set as Main Room" placement="top">
                  <IconButton
                    style={{ marginTop: "-0.3rem" }}
                    onClick={this.handleMain}
                  >
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
