import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PageHome from "./components/PageHome.js";
import PageActiveGame from "./components/PageActiveGame";
import Banned from "./components/Pages/Banned";
import Kicked from "./components/Pages/Kicked";
import Terms from "./components/Pages/Terms";
import GamesOver from "./components/Pages/GamesOver";
import BackgroundLines from "./components/BackgroundLines";
import GamesNotFound from "./components/GamesNotFound";

function App() {
  return (
    <Router>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            maxWidth: "100%",
            overflowX: "hidden",
            minHeight: "30rem",
          }}
        >
          <BackgroundLines />
          <Switch>
            <Route exact path="/">
              <PageHome />
            </Route>
            <Route path="/banned" component={Banned} />
            <Route path="/kicked" component={Kicked} />
            <Route path="/gamesover" component={GamesOver} />
            <Route path="/terms" component={Terms} />
            <Route path="/:game" component={PageActiveGame} />
            <Route>
              <GamesNotFound />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
